<template>
    <div class="width100 height100">
        <router-link class="course-card box box--hover width100 height100 block relative"
                     :to="{ name: 'course', params: { stub: course.stub, lang: $i18n.locale } }" v-if="grid">
            <div
                    class="course-card__image bg--image quadric relative"
                    :style="{ 'background-image': 'url(' + (course.image && course.image.uri) + ')' }">
            </div>
            <div class="course-card__content">
                <div class="flex flex--wrap flex--middle mb20" v-if="course.categories && course.categories.length > 0 || course.certification">
                    <p class="mb5 mr10" v-if="course.categories && course.categories.length > 0">
                        {{ course.categories && course.categories.map((category) => category.name).join(", ")}}
                    </p>

                    <div v-if="course.certification"
                         class="label label--secondary label--small mb5">
                        {{$t('components.Courses.CourseCard.certificate')}}
                    </div>
                </div>

                <h6>{{ course.name }}</h6>

                <p class="mt20" v-if="course.authors && course.authors.length">
                    {{ course.authors && course.authors.map((author)=> (`${author.name} ${author.surname}`)).join(", ")}}
                </p>
                <!--
                    <div class="flex mb10 flex--middle mt10">
                        <v-icon-quality height="18px" width="18px" class="mr5"></v-icon-quality>
                        <span>{{ course.courseLevel }}</span>
                    </div>
                -->
                <p class="mt20">
                    {{ course.abstract && course.abstract.substring(0,100) + "..." }}
                </p>
                <!-- <div class="flex">
                    <div style="width: 50px;">
                        100%
                    </div>
                    <div style="width: calc(100% - 50px);">

                    </div>
                </div>
                <div class="pb10"></div> -->
            </div>
            <div class="course-card__footer absolute absolute--bottom absolute--left absolute--right">
                <div class="flex flex--between flex--middle">
                    <div class="flex flex--middle" v-if="course.duration">
                        <v-icon-clock height="18px" width="18px" class="text--secondary mr5"></v-icon-clock>
                        {{ course.duration | duration }}
                    </div>
                    <div style="height: 38px" v-else></div>
                    <div>
                        <template v-if="enrolled">
                            {{$t('components.Courses.CourseCard.enrolled')}}
                            {{ enrolledCourse && enrolledCourse.created | date }}
                        </template>
                        <template v-else>
                            <span v-if="course.paidCourse" class="label label--green">{{ course.priceCourse + "€"}}</span>
                            <span v-else class="label">{{ $t('components.Courses.CourseCard.free') }}</span>
                        </template>
                    </div>
                </div>
            </div>
        </router-link>

        <router-link class="box box--hover course-card width100 height100 block relative"
                     :to="{ name: 'course', params: { stub: course.stub, lang: $i18n.locale } }" v-else-if="featured"
                     :disabled="disabled" :event="!disabled ? 'click' : ''">
            <div
                    class="bg--image quadric relative"
                    :style="{ 'background-image': 'url(' + (course.image && course.image.uri) + ')' }"  @click="disabledPopup">
                <div v-if="course.certification"
                     class="absolute absolute--right absolute--bottom bg--gradient text--white pt5 pb5 pl30 pr30 text-uppercase">
                    {{$t('components.Courses.CourseCard.certificate')}}
                </div>
<!--
                <div class="course-card__waves">
                    <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5f47c0dd68989ed2f2a92d71_waves.png" alt="">
                </div> -->

                <div class="course-card__disabled-overlay" v-if="disabled">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
                    <div class="h4">{{ disabledText }}</div>
                </div>
            </div>
            <div class="pl30 pr30 pt30 pb30" @click="disabledPopup">
                <h4 class="mb20">{{ course.name }}</h4>
                <p class="mb20">
                    {{ course.authors && course.authors.map((author)=> (`${author.name} ${author.surname}`)).join(", ")}}
                </p>
                <p class="mb20">
                    {{ course.abstract && course.abstract.substring(0,100) + "..." }}
                </p>

                <div class="mt30">
                    <router-link :to="{ name: 'course', params: { stub: course.stub, lang: $i18n.locale } }" class="btn" :disabled="disabled" :class="{ 'is-disabled': disabled }">{{$t('components.Courses.CourseCard.button')}}
                    </router-link>
                </div>
            </div>
        </router-link>

        <router-link class="box box--hover course-card width100 height100 block relative" v-else
            :to="{ name: 'course', params: { stub: course.stub, lang: $i18n.locale } }">
            <div class="flex relative">
                <div class="col-md-4 flex--one pr0 pl0">
                    <div class="relative width100">
                        <div class="quadric bg--image height100 width100 relative"
                             :style="{'background-image': 'url(' + (course.image && course.image.uri) + ')'}">
                            <div v-if="course.certification"
                                 class="absolute absolute--right absolute--bottom bg--gradient text--white pt5 pb5 pl20 pr20 text-uppercase">
                                {{$t('components.Courses.CourseCard.certificate')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 flex--one">
                    <div class="pl15 pr15 pt10 pb60 relative" style="width: 100%">
                        <p class="h5 text-uppercase">{{ course.categories && course.categories.map((category) => category.name).join(", ")}}</p>
                        <h4 class="mb5 mt5">{{ course.name }}</h4>
                        <p>
                            {{ course.authors && course.authors.map((author)=> (`${author.name} ${author.surname}`)).join(", ")}}
                        </p>


                        <!-- <p class="small" style="font-weight: 700;"> -->
                            <!-- {{$t('components.Courses.CourseCard.level')}}: {{ course.courseLevel }} -->
                        <!-- </p> -->
                        <p style="font-weight: 700;">
                            {{$t('components.Courses.CourseCard.price')}}:
                            <span v-if="course.paidCourse || course.priceCourse" class="bg--gradient text--white pl5 pr5 pt5 pb5" style="border-radius: 5px">
                                {{ course.priceCourse + "€"}}
                            </span>
                            <span v-else style="background: #b3e6bb;border-radius: 5px" class=" pl5 pr5 pt5 pb5">
                                {{ $t('components.Courses.CourseCard.free') }}
                            </span>
                        </p>
                        <p class="pt5 small">
                            {{ course.abstract && course.abstract.substring(0,100) + "..." }}
                        </p>
                        <br>
                        <div class="flex flex--middle" v-if="!profile">
                            <div style="width: 50px;">
                                {{progress}}%
                            </div>
                            <div style="width: calc(100% - 50px);">
                                <div class="bg--blue" style="height: 5px;" :style="{'width':  progress+'%'}"></div>
                            </div>
                        </div>
                        <div class="absolute absolute--bottom absolute--left absolute--right pl20 pr20">
                            <hr style="height: 1.5px">
                            <div class="flex flex--between flex--middle">
                                <div class="flex mb10 flex--middle mt10" v-if="course.duration">
                                    <v-icon-clock height="18px" width="18px" class="mr5"></v-icon-clock>
                                    {{ course.duration | duration }}
                                </div>
                                <div class="text-bold">
                                    <template v-if="enrolled">
                                        {{$t('components.Courses.CourseCard.enrolled')}}
                                        {{ enrolledCourse.created | date }}
                                    </template>
                                    <template v-else>
                                        <span v-if="course.paidCourse" class="bg--gradient text--white pl5 pr5 pt5 pb5"
                                              style="border-radius: 5px">{{ course.priceCourse + "€"}}</span>
                                        <span v-else style="background: #b3e6bb;border-radius: 5px"
                                              class=" pl5 pr5 pt5 pb5">{{ $t('components.Courses.CourseCard.free') }}</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'course-card',
    props: {
        course: {
            type: Object,
            required: true,
        },

        grid: {
            type: Boolean,
            default: true,
            required: false,
        },

        featured: {
            type: Boolean,
            default: false,
            required: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        disabledText: {
            type: String,
            default: 'Prihaja kmalu',
            required: false,
        },

        profile: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    computed: {
        ...mapState({
            enrolledCourse(state) {
                return state.auth.enrolledCourses && state.auth.enrolledCourses.find(el => el.course.stub === this.course.stub);
            },
        }),

        enrolled() {
            return !!this.enrolledCourse;
        },
        progress() {
            if (this.course.sections && this.course.sections.length > 0) {
                return Math.round(this.course.progress.length / this.course.sections.map(section => section.lectures).flat().length * 100);
            }
            return Math.round(this.course.progress.length / this.course.lectures.length * 100);
        },
    },

    methods: {
        disabledPopup() {
            if(this.disabled) {
                this.$emit('disabledClick', true)
            }
        }
    }
};
</script>

<template>
    <div>
        <section class="bg--image ph"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover_small.jpg')})`}">
            <div class="container pt90 pt45:sm pb60 pb20:sm">
                <div class="row flex--middle">
                    <div class="col-md-2">
                        <div class="bg--image quadric--full"
                             :style="{'background-image': `url(${require('../../assets/images/avatar-man.png')})`}"></div>
                    </div>
                    <div class="col-md-6 pt10:sm">
                        <h3 class="text--white">{{ account ? account.name + " " + account.surname : ''}}</h3>
                        <p class="text--white">{{ account ? account.email : ''}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg--bg pt60 pb60 pt20:sm pb20:sm">
            <div class="container">
                <v-tabs-columns v-model="tabs">
                    <!-- <v-tab :name="$t('views.Auth.Profile.tabs.personal')" :selected="tabs">
                        <form-profile-personal></form-profile-personal>
                    </v-tab>
                    <v-tab :name="$t('views.Auth.Profile.tabs.password')" :selected="tabs">
                        <form-profile-password></form-profile-password>
                    </v-tab> -->
                    <v-tab :name="$t('views.Auth.Profile.tabs.courses')" :selected="tabs">
                        <template v-if="enrolledCourses && enrolledCourses.length > 0">
                            <div class="row">
                                <div class="col-md-12 width100 mb20" v-for="(course, ci) of enrolledCourses" :key="ci">
                                    <course-card :profile="true" :course="course.course" :grid="false"/>
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            <div class="info-box">
                                Trenutno niste naročeni na noben tečaj
                            </div>
                        </template>
                    </v-tab>
                    <!-- <v-tab :name="$t('views.Auth.Profile.tabs.events')" :selected="tabs">
                        <div class="row">
                            <div class="width100 mb20" v-for="(event, ei) of enrolledEvents" :key="ei">
                                <event-card :profile="true" :event="event.event"/>
                            </div>
                        </div>
                    </v-tab> -->
                    <v-tab :name="$t('views.Auth.Profile.tabs.order_history')" :selected="tabs">
                        <template v-if="paymentSucceeded.length > 0">
                            <div class="width100 mb20" v-for="(payment, pi) of paymentSucceeded">
                                <payment-card class="mb20" :payment="payment" :key="pi" />
                            </div>
                        </template>
                        <template v-else>
                            <div class="info-box">
                                Trenutno ni zgodovine naročil
                            </div>
                        </template>
                    </v-tab>
                </v-tabs-columns>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    import FormProfilePersonal from '../../components/Forms/profile/Personal.vue';
    import FormProfilePassword from '../../components/Forms/profile/Password.vue';
    import CourseCard from '../../components/Courses/CourseCard.vue';
    import EventCard from '../../components/Events/EventCard.vue';
    import PaymentCard from '../../components/Payments/PaymentCard';

    export default {
        components: {
            PaymentCard,
            EventCard,
            'course-card': CourseCard,
            FormProfilePassword,
            FormProfilePersonal,
        },

        data() {
            return {
                videos: [1, 2, 3],
                tabs: this.$t('views.Auth.Profile.tabs.courses'),
            };
        },

        watch: {
            portalName: {
                handler(portalName) {
                    document.title = `${portalName}`;
                },
            },
        },

        computed: {
            ...mapState({
                account: state => state.auth.account,
                enrolledCourses: state => state.auth.enrolledCourses,
                enrolledEvents: state => state.auth.enrolledEvents,
                payments: state => state.auth.payments,
                portalName: state => state.other.portalName,
            }),

            paymentSucceeded() {
                return this.payments.filter(payment => payment.status === 'succeeded')
            }
        },

        beforeRouteEnter: (to, from, next) => {
            if (localStorage.getItem('auth_token') !== null) {
                next();
            } else {
                next('/login');
            }
        },

        beforeMount() {
            document.title = `${this.portalName}`;
        },

        mounted() {
            this.$store.dispatch('auth/tokenUser');
        },
    };
</script>

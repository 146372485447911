<template>
    <section class="s-author s-author--header text--white ph">
        <div class="container">
            <div class="row">
                <div class="col-sm-4 col-md-6 relative">
                    <div class="s-author__image-wrapper">
                        <img class="s-author__image" :src="image" alt="">
                    </div>
                    <!-- <div class="s-author__name">
                        Jan Kovačič
                    </div> -->
                    <!-- <div class="s-author__name-bg">
                        <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5f48aa88951eed6da1cf6bf0_dots.png" alt="">
                    </div> -->
                </div>
                <div class="col-sm-8 col-md-6">
                    <div class="pl40 pl0:xs pr40 pr0:xs pt60 pt40:xs pb40">
                        <h5 class="text-thin text-uppercase mb20">
                            {{ $t('components._sections.Author_Header.subtitle') }}
                        </h5>
                        <h3 class="mb20">
                            {{ $t('components._sections.Author_Header.title') }}
                        </h3>
                        <p class="h4 s-author__text">
                            {{ $t('components._sections.Author_Header.description') }}
                        </p>

                        <div class="mb30">
                            <div class="flex flex--nowrap mb10">
                                <div class="mr10">
                                    <v-icon-check height="40px" width="40px" />
                                </div>
                                <p>
                                    {{ $t('components._sections.Author_Header.features.0.text') }}
                                </p>
                            </div>
                            <div class="flex flex--nowrap mb10">
                                <div class="mr10">
                                    <v-icon-check height="40px" width="40px"/>
                                </div>
                                <p>
                                    {{ $t('components._sections.Author_Header.features.1.text') }}
                                </p>
                            </div>
                            <div class="flex flex--nowrap mb10">
                                <div class="mr10">
                                    <v-icon-check height="40px" width="40px"/>
                                </div>
                                <p>
                                    {{ $t('components._sections.Author_Header.features.2.text') }}
                                </p>
                            </div>
                        </div>

                        <div class="bg--gray pt10 pr10 pl10 text--white">
                            <div class="flex flex--nowrap mb10">
                                <div class="mr10">
                                    <v-icon-award height="30px" width="30px"/>
                                </div>
                                <div>
                                    <h5 class="mb5">
                                        {{ $t('components._sections.Author_Header.guarantee.0.title') }}
                                    </h5>
                                    <p class="leading-normal">
                                        {{ $t('components._sections.Author_Header.guarantee.0.description') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'section-author-header',

        props: {
            image: {
                type: String,
                default: null
            }
        }
    };
</script>

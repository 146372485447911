<template>
    <section class="s-video-interview">
        <div class="container">
            <div class="row flex--center">
                <div class="col-md-10">
                    <h3 class="mb10 text-center text-section-title">
                        {{ $t('components._sections.VideoInterview.title') }}
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.VideoInterview.subtitle') }}
                    </h4>
                </div>
            </div>
            <div class="s-video-interview__content mt40">
                <div class="row">
                    <div class="col-md-5 mb20:sm">
                        <h5 class="text-thin mb20">
                           {{ $t('components._sections.VideoInterview.interview.caption') }}
                        </h5>
                        <h3 class="mb20">
                            {{ $t('components._sections.VideoInterview.interview.title') }}
                        </h3>
                        <p>
                            {{ $t('components._sections.VideoInterview.interview.description') }}
                        </p>
                    </div>

                    <div class="col-md-7">
                        <section class="s-video-interview__video">
                            <router-link :to="{ path: '/SI/lecture/30dni_intervju01_Anton-Majhen/courses/30-dnevni-izziv'}">
                                <div class="s-video-interview__video-image" :style="{'background-image': 'url('+ require('../../assets/images/intervju-01.jpg') +')'}">
                                    <div class="s-video-interview__video-name">
                                        <h4>
                                            {{ $t('components._sections.VideoInterview.interview.author') }}
                                        </h4>
                                        <h5 class="text-thin">
                                            {{ $t('components._sections.VideoInterview.interview.author-title') }}
                                        </h5>
                                    </div>

                                    <div class="s-video-interview__video-icon">
                                        <img :src="require('../../assets/images/play-button.png')">
                                    </div>
                                </div>
                            </router-link>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'section-video-interview',
};
</script>

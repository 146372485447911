import moment from 'moment';

import defaultState from './defaultState';

export default {
    saveCategories(state, categories) {
        const mainCategories = [{ id: null, name: 'Vse' }];
        const subCategories = [];

        for (let i = 0; i < categories.length; i += 1) {
            const category = categories[i];

            if (category.parent) {
                subCategories.push(category);
            } else {
                mainCategories.push(category);
            }
        }

        state.mainCategories = mainCategories.sort((a, b) => a.order - b.order);
        state.subCategories = subCategories;
    },

    saveList(state, { list, categoryId }) {
        const webinars = list;
        if (categoryId) {
            for (let i = 0; i < list.length; i += 1) {
                webinars[i].categoryId = categoryId;
            }
        }

        const newList = state.list.concat(webinars);

        state.list = newList;
    },

    saveArchivedList(state, { list, categoryId }) {
        const webinars = list;
        if (categoryId) {
            for (let i = 0; i < list.length; i += 1) {
                webinars[i].categoryId = categoryId;
            }
        }

        const newList = state.list.concat(webinars);

        state.archivedList = newList;
    },

    saveEnrolledList(state, list) {
        state.enrolledList = list.map((val) => val.webinar);
    },

    saveArchiveEnrolledList(state, list) {
        state.archivedEnrolledList = list.map((val) => val.webinar);
    },

    saveLiveEnrolledList(state, list) {
        state.liveEnrolledList = list.map((val) => val.webinar);
    },

    resetList(state) {
        state.list = [];
    },

    resetArchivedList(state) {
        state.archivedList = [];
    },

    saveOne(state, webinar) {
        state.one.id = webinar.id;
        state.one.name = webinar.name;
        state.one.price = webinar.price;
        state.one.free = webinar.free;
        state.one.startsAt = webinar.startsAt;
        state.one.endsAt = webinar.endsAt;
        state.one.authors = webinar.authors;
        state.one.image = webinar.image;
        state.one.description = webinar.description;
        state.one.category = webinar.categories;
        state.one.enrolled = webinar.enrolled ? webinar.enrolled.created : null;
        state.one.url = webinar.liveStream ? webinar.liveStream.url : null;
        state.one.additionalContent = webinar.additionalContent;
        state.one.enrolmentsCount = webinar.enrolmentsCount;
        state.one.video = webinar.video;
        state.one.processed = webinar.processed;
        state.one.lastPayment = webinar.lastPayment;
        state.one.recurring = webinar.recurring;
        state.one.liveStream = webinar.liveStream;
    },

    saveOneAuthor(state, webinar) {
        state.oneAuthor.id = webinar.id;
        state.oneAuthor.name = webinar.name;
        state.oneAuthor.startsAt = webinar.startsAt;
        state.oneAuthor.endsAt = webinar.endsAt;
        state.oneAuthor.image = webinar.image;
        state.oneAuthor.description = webinar.description;
        state.oneAuthor.enrolments = webinar.enrolments;
        state.oneAuthor.url = webinar.liveStream ? webinar.liveStream.url : null;
        state.oneAuthor.rtmpUrl = webinar.liveStream ? webinar.liveStream.rtmpUrl : null;
        state.oneAuthor.done = moment(webinar.endsAt).add(30, 'minutes') < moment();
        state.oneAuthor.video = webinar.video;
    },

    saveOneEdit(state, webinar) {
        state.edit.id = webinar.id;
        state.edit.name = webinar.name;
        state.edit.price = webinar.price;
        state.edit.free = webinar.free;
        state.edit.starts = moment(webinar.startsAt).toISOString();
        state.edit.ends = moment(webinar.endsAt).toISOString();
        state.edit.image = webinar.image;
        state.edit.description = webinar.description;
        state.edit.stub = webinar.stub;
        state.edit.category = webinar.categories.length > 0 ? webinar.categories[0].id : null;
        state.edit.additionalContent = webinar.additionalContent;
        state.edit.status = webinar.status;
        state.edit.organization = webinar.organizations.length > 0 ? webinar.organizations[0].id : null;
        state.edit.onEnd = webinar.onEnd;
    },

    saveLive(state, webinars) {
        state.lives = webinars;
    },

    resetLive(state) {
        state.lives = [];
    },

    toggleSuccessModal(state, bool) {
        state.successModal = bool;
    },

    addAdditionalContent(state, { uri, id }) {
        state.edit.additionalContent.push({
            id: null,
            image: {
                id,
                uri,
            },
        });
    },

    removeAdditionalContent(state, index) {
        state.edit.additionalContent.splice(index, 1);
    },

    edit(state, { field, value }) {
        state.edit[field] = value;
    },

    resetEdit(state) {
        state.edit = JSON.parse(JSON.stringify(defaultState.edit));
    },

    toggleUploadVideoModal(state, bool) {
        state.uploadVideoModal = bool;
    },

    resetOne(state) {
        state.one = JSON.parse(JSON.stringify(defaultState.one));
    },

    resetOneAuthor(state) {
        state.oneAuthor = JSON.parse(JSON.stringify(defaultState.oneAuthor));
    },

    toggleInstructionsModal(state, bool) {
        state.instructionsModal = bool;
    },

    togglePackagesModal(state, bool) {
        state.packageModal = bool;
    },
};

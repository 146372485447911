import moment from 'moment';
import Vue from 'vue';

import { portal } from '../../api/api';

import categories from '../../api/graphql/categories/categories.graphql';
// import webinars from '../../api/graphql/webinars/webinars.graphql';
import webinars from '../../api/graphql/webinars/list.graphql';
import webinarsArchived from '../../api/graphql/webinars/listArchived.graphql';
import webinarsOne from '../../api/graphql/webinars/one.graphql';
import webinarsEnroll from '../../api/graphql/webinars/enroll.graphql';

export default {
    fetchCategories: ({ commit }) => {
        portal(categories.Categories).then((success) => {
            commit('saveCategories', success.data.data.categories);
        }, (error) => {
            console.log(error);
        });
    },

    fetchWebinars: ({ commit }, { categoryId, authorId, organizationId }) => {
        const filter = {
            endsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'GT' },
        };
        if (categoryId) {
            filter.categoryId = { value: categoryId, comparison: 'EQ' };
        }

        if (authorId) {
            filter.authorId = { value: authorId, comparison: 'EQ' };
        }

        if (organizationId) {
            filter.organizationId = { value: organizationId, comparison: 'EQ' };
        }

        // commit('loading/loading', 'courseList', { root: true });

        portal(webinars, { filter }).then((success) => {
            console.log(success)
            commit('saveList', { list: success.data.data.webinars.data, categoryId });
            // commit('loading/loaded', 'courseList', { root: true });
        }, (error) => {
            console.log(error);
        });
    },

    fetchArchivedWebinars: ({ commit }, { categoryId, authorId, organizationId }) => {
        const filter = {
            endsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'LT' },
            onEnd: { value: 'public', comparison: 'EQ' },
        };

        if (categoryId) {
            filter.categoryId = { value: categoryId, comparison: 'EQ' };
        }

        if (authorId) {
            filter.authorId = { value: authorId, comparison: 'EQ' };
        }

        if (organizationId) {
            filter.organizationId = { value: organizationId, comparison: 'EQ' };
        }

        // commit('loading/loading', 'courseList', { root: true });

        portal(webinarsArchived, { filter }).then((success) => {
            commit('saveArchivedList', { list: success.data.data.webinars.data, categoryId });
            // commit('loading/loaded', 'courseList', { root: true });
        }, (error) => {
            console.log(error);
        });
    },

    fetchAuthorWebinars: ({ commit }, { categoryId, organizationId }) => {
        const filter = {
            startsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'GT' },
        };

        if (categoryId) {
            filter.categoryId = { value: categoryId, comparison: 'EQ' };
        }

        if (organizationId) {
            filter.organizationId = { value: organizationId, comparison: 'EQ' };
        }

        portal(webinars.AuthorWebinars, { filter }).then((success) => {
            commit('saveList', { list: success.data.data.myWebinars.data, categoryId });
        }, (error) => {
            console.log(error);
        });
    },

    fetchAuthorArchivedWebinars: ({ commit }, { categoryId, organizationId }) => {
        const filter = {
            endsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'LT' },
        };

        if (categoryId) {
            filter.categoryId = { value: categoryId, comparison: 'EQ' };
        }

        if (organizationId) {
            filter.organizationId = { value: organizationId, comparison: 'EQ' };
        }

        portal(webinars.AuthorWebinars, { filter }).then((success) => {
            commit('saveArchivedList', { list: success.data.data.myWebinars.data, categoryId });
        }, (error) => {
            console.log(error);
        });
    },

    fetchEnrolledWebinars: ({ commit }) => {
        const filter = {
            startsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'GT' },
        };

        commit('loading/loading', 'courseList', { root: true });

        portal(webinars.Enrolled, { filter }).then((success) => {
            commit('saveEnrolledList', success.data.data.enrolledWebinars);
            commit('loading/loaded', 'courseList', { root: true });
        }, (error) => {
            console.log(error);
        });
    },

    fetchLiveEnrolledWebinars: ({ commit }) => {
        const filter = {
            startsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'LT' },
            endsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'GT' },
        };

        commit('loading/loading', 'courseList', { root: true });

        portal(webinars.Enrolled, { filter }).then((success) => {
            commit('saveLiveEnrolledList', success.data.data.enrolledWebinars);
            commit('loading/loaded', 'courseList', { root: true });
        }, (error) => {
            console.log(error);
        });
    },

    fetchArhiveEnrolledWebinars: ({ commit }) => {
        const filter = {
            endsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'LT' },
        };

        commit('loading/loading', 'enrolledArchiveList', { root: true });

        portal(webinars.EnrolledArchived, { filter }).then((success) => {
            commit('saveArchiveEnrolledList', success.data.data.enrolledWebinars);
            commit('loading/loaded', 'enrolledArchiveList', { root: true });
        }, (error) => {
            console.log(error);
        });
    },

    fetchOne: ({ commit }, id) => {
        // commit('loading/loading', 'courseList', { root: true });

        portal(webinarsOne, { id }).then((success) => {
            commit('saveOne', success.data.data.webinars.data[0]);
            // commit('loading/loaded', 'courseList', { root: true });
        }, (error) => {
            console.log(error);
        });
    },

    fetchOneAuthor: ({ commit }, id) => {
        portal(webinars.OneAuthor, { id }).then((success) => {
            console.log(success.data.data);
            commit('saveOneAuthor', success.data.data.webinars.data[0]);
        }, (error) => {
            console.log(error);
        });
    },

    fetchOneEdit: ({ commit }, id) => {
        commit('loading/loading', 'courseEdit', { root: true });

        portal(webinars.OneEdit, { id }).then((success) => {
            if (success.data.data.myWebinars.data.length < 1) {
                console.log('Not allowed....');
                return;
            }

            commit('saveOneEdit', success.data.data.myWebinars.data[0]);
            commit('loading/loaded', 'courseEdit', { root: true });
        }, (error) => {
            console.log(error);
        });
    },

    fetchLive: ({ commit }, { authorId, categoryId, organizationId }) => {
        commit('resetLive');
        const now = moment();
        const filter = {
            startsAt: { value: now.format('YYYY-MM-DD HH:mm:ss'), comparison: 'LT' },
            endsAt: { value: now.format('YYYY-MM-DD HH:mm:ss'), comparison: 'GT' },
        };

        if (authorId) {
            filter.authorId = { value: authorId, comparison: 'EQ' };
        }

        if (categoryId) {
            filter.categoryId = { value: categoryId, comparison: 'EQ' };
        }

        if (organizationId) {
            filter.organizationId = { value: organizationId, comparison: 'EQ' };
        }

        commit('loading/loading', 'courseList', { root: true });

        portal(webinars, { filter }).then((success) => {
            commit('saveLive', success.data.data.webinars.data);
            commit('loading/loaded', 'courseList', { root: true });
        });
    },

    enroll: ({ dispatch, commit }, { id, packageId }) => {
        const params = {
            webinarId: parseInt(id, 10),
        };

        if (packageId) {
            params.accountPackageId = packageId;
        }

        portal(webinarsEnroll, params).then(() => {
            commit('resetOne');
            dispatch('fetchOne', id);
            commit('toggleSuccessModal', true);
        });
    },

    update: ({ getters, state }, { cb }) => {
        const data = getters.update;
        data.startsAt = moment(data.startsAt).format('YYYY-MM-DD HH:mm:ss');
        data.endsAt = moment(data.endsAt).format('YYYY-MM-DD HH:mm:ss');

        portal(webinars.Update, { id: state.edit.id, input: data }).then((success) => {
            console.log(success);

            if (cb) {
                cb(success);
            }
        });
    },

    create: ({ getters, rootState }, { cb, router }) => {
        const data = getters.update;
        data.startsAt = moment(data.startsAt).format('YYYY-MM-DD HH:mm:ss');
        data.endsAt = moment(data.endsAt).format('YYYY-MM-DD HH:mm:ss');

        portal(webinars.Create, { input: data }).then((success) => {
            if (cb) {
                cb(success);
            }

            if (!success.data.errors) {
                router.push({ name: 'Author', params: { id: rootState.app.user.authorId } });
            }
        });
    },

    showLiveEnrolledWebinars: ({ dispatch }, { router }) => {
        const filter = {
            startsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'LT' },
            endsAt: { value: moment().format('YYYY-MM-DD HH:mm:ss'), comparison: 'GT' },
        };

        portal(webinars.Enrolled, { filter }).then((success) => {
            for (let i = 0; i < success.data.data.enrolledWebinars.length; i += 1) {
                const { name, id, image } = success.data.data.enrolledWebinars[i].webinar;

                dispatch('liveToast', {
                    webinar: {
                        name,
                        id,
                        image: image ? image.uri : '',
                    },
                    router,
                });
            }
            // const webinarObject = {
            //   name: 'Test webinar',
            //   id: 11,
            //   image: 'https://assets.staging.trayn.zone/tbdxSyhKWMm6ov8XgmieDKsvLpvYWh5j0uGo6KzZ4tUvtC3NkhB8qOSjHGdCEQXb?size=500',
            // };

            // // Live webinar toast
            // this.$store.dispatch('webinars/liveToast', { webinar: webinarObject, router: this.$router });

            // // Coming webinar toast
            // this.$store.dispatch('webinars/comingWebinarToast', webinarObject);
        }, (error) => {
            console.log(error);
        });
    },

    liveToast: (_, { webinar, router }) => {
        const toast = Vue.toasted.show(`
        <div class="w-full">
        <a href="/courses/${webinar.id}" class="card__thumbnail block rounded-t-md overflow-hidden relative bg-cover bg-center"
        style="background-image: url(${webinar.image}); padding-top: 65%;">
        <!-- Play icon -->
        <div class="card__play-icon absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 text-white opacity-75 group-hover:opacity-100">
        <svg class="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        </div>
        </a>
        <div class="card__body flex-1 p-4">
        <div class="card__heading">
        <a href="/courses/${webinar.id}" class="">
        <h3 class="text-md leading-4 font-medium text-gray-900 hover:text-turquoise-500">
        ${webinar.name}
        </h3>
        </a>
        <p class="text-sm text-gray-400 mt-1">
        Trenutno v živo
        </p>
        </div>
        </div>
        </div>`,
        {
            type: 'notification',
            action: [
                {
                    text: 'poglej',
                    push: {
                        name: 'SingleCourse',
                        params: { id: webinar.id },
                        dontClose: false,
                    },
                },
                {
                    text: 'zapri',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            ],
        });

        toast.el.getElementsByTagName('a').forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                if (e.target.innerText !== 'ZAPRI') {
                    router.push({
                        name: 'SingleCourse',
                        params: { id: webinar.id },
                    });
                }
                toast.goAway(0);
            });
        });
    },

    comingWebinarToast: (_, webinar) => {
        Vue.toasted.global.toastInfo({
            message: `<strong>${webinar.name}</strong><br>
            Se prične čez 10min`,
        });
    },
};

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="22px" viewBox="0 0 20 11" fill="none"
         :stroke="active ? '#0067b6':'#000'" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
        <rect x="1" y="1" width="18" height="9" rx="2" ry="2"></rect>
        <g stroke-width="3"  style="transform: scale(0.33) translateX(100%) translateY(65%)">
            <circle cx="12" cy="7" r="4"></circle>
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

import { portal } from '../../api/api';

import Authors from '../../api/graphql/authors/authors.graphql';
import One from '../../api/graphql/authors/one.graphql';

export default {
    fetch: ({ commit, state }) => {
        const searchQuery = {
            pagination: {
                limit: state.list.limit,
                page: state.list.page,
            },

        };
        if (state.list.search) {
            searchQuery.filter = {
                name: {
                    value: state.list.search,
                    comparison: 'LIKE',
                },
            };
        }
        return portal(Authors, searchQuery)
            .then((success) => {
                commit('saveListAttr', { field: 'total', payload: success.data.data.authors.total });
                commit('saveListAttr', { field: 'data', payload: success.data.data.authors.data });
                return Promise.resolve(success.data.data.authors.data);
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    changePagination: ({ commit }, page) => {
        commit('saveListAttr', { field: 'page', payload: page });
    },
    changeSearch: ({ commit }, search) => {
        commit('saveListAttr', { field: 'search', payload: search });
    },

    fetchOne: ({ commit }, stub) => portal(One, { filter: { stub: { value: stub, comparison: 'EQ' } } })
        .then((success) => {
            commit('saveOne', success.data.data.authors.data[0]);
            return Promise.resolve(success.data.data.authors.data[0]);
        })
        .catch(error => Promise.reject(error)),
};

<template>
    <v-modal :open="$store.state.courses.waitlist.popup" @close="closePopup()">
        <div>
            <h4 class="text-center mb10">Prijavi se v čakalno vrsto za</h4>
            <h3 class="text-center pb10 mb20" v-if="this.$store.state.courses.waitlist.course">
                {{ this.$store.state.courses.waitlist.course.name }}
            </h3>

            <form action="">
                <div class="row">
                    <div class="col-md-12 mb30">
                        <input type="text" name="name" placeholder="Ime" @keyup="$v.nameWaitlist.$touch()" v-model="nameWaitlist">

                        <p class="errortext mb0" v-if="$v.nameWaitlist.$dirty && !$v.nameWaitlist.required && !$store.state.courses.waitlist.success">
                            Prosimo, vnesite ime
                        </p>
                    </div>
                    <div class="col-md-12 mb30">
                        <input type="email" name="email" placeholder="E-pošta" @keyup="$v.emailWaitlist.$touch()" v-model="emailWaitlist">

                        <p class="errortext mb0" v-if="$v.emailWaitlist.$dirty && !$v.emailWaitlist.required && !$store.state.courses.waitlist.success">
                            {{$t('components._sections.Newsletter.form.email.required')}}
                        </p>
                        <p class="errortext mb0" v-if="$v.emailWaitlist.$dirty && !$v.emailWaitlist.email">
                            {{$t('components._sections.Newsletter.form.email.email')}}
                        </p>
                    </div>
                    <div class="col-md-6">
                        <button type="button" class="btn" @click="submitWaitlist()" :disabled="$v.$invalid">Prijavi se</button>
                    </div>
                    <div class="col-md-6 text-right">
                        <button type="button" class="btn" @click="$store.commit('courses/waitlist', { field: 'popup', value: false })">Zapri</button>
                    </div>
                </div>

                <div class="success-box flex flex--middle mt20" v-if="$store.state.courses.waitlist.success">
                    <v-icon-checkCircle class="mr10" />
                    <div style="width: calc(100% - 50px)">
                        Uspešno ste se prijavili na čakalno listo. Ko bo vadba na voljo, vas obvestimo na e-naslov.
                    </div>
                </div>
            </form>
        </div>
    </v-modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            nameWaitlist: null,
            emailWaitlist: null,
            displayWaitlistSuccessMessage: false,
        };
    },

    validations: {
        nameWaitlist: {
            required,
        },
        emailWaitlist: {
            required,
            email,
        },
    },

    methods: {
        closePopup() {
            this.$store.commit('courses/waitlist', { field: 'popup', value: false });
        },

        submitWaitlist() {
            this.$store.dispatch('other/waitlistForm', { name: this.nameWaitlist, email: this.emailWaitlist });
        },
    },
}
</script>

<template>
    <div>

        <section class="bg--image bg--primary ph" :style="{
            'background-image': 'linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url('+ require('../../assets/images/bg_cover_small.jpg') +')',
            }">


            <div class="container pt90 pt30:sm pb60 pb30:sm">
                <div class="row flex--center flex--middle">
                    <div class="col-md-8 text-center text--white">
                        <template v-if="featuredWebinar">
                            <p class="mb30">
                                {{ featuredWebinar.abstract }}
                            </p> 

                            <h6 class="mb15">
                                {{ featuredWebinar.authors.map((author) => (`${author.name}
                                ${author.surname}`)).join(", ") }}
                            </h6>

                            <h2 class="mb20">
                                {{ featuredWebinar.name }}
                            </h2>

                            <template v-if="countdown_time > 0">
                                <div class="c-countdown">
                                    <h4 class="c-countdown__title mb20">
                                        P{{$t('views.Webinars.Webinars.countdown_title')}}
                                    </h4>

                                    <countdown :time="countdown_time < 0 ? 0 : countdown_time">
                                        <template slot-scope="props">
                                            <div class="c-countdown__grid">
                                                <div class="c-countdown__item" v-if="props.days > 0">
                                                    <div class="c-countdown__text">
                                                        dni
                                                    </div>
                                                    <div class="c-countdown__number">
                                                        {{ props.days }}
                                                    </div>
                                                </div>
                                                <div class="c-countdown__item" v-if="props.hours > 0">
                                                    <div class="c-countdown__text">
                                                        ur
                                                    </div>
                                                    <div class="c-countdown__number">
                                                        {{ props.hours }}
                                                    </div>
                                                </div>
                                                <div class="c-countdown__item">
                                                    <div class="c-countdown__text">
                                                        min
                                                    </div>
                                                    <div class="c-countdown__number">
                                                        {{ props.minutes }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </countdown>

                                    <div class="mt30">
                                        <button class="btn">
                                            {{$t('views.Webinars.Webinars.enroll')}}
                                        </button>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <!-- <p class="text-md leading-normal font-medium text-gray-900 mt-4 py-2">
                                    <strong>Se trenutno predvaja - kupi</strong>
                                </p> -->

                                <div class="mt30">
                                    <button class="btn">
                                         {{$t('views.Webinars.Webinars.playing')}}  
                                    </button>
                                </div>
                            </template>

                            <div class="mt20">
                                {{ featuredWebinar.duration }}
                            </div>


                        </template>

                        <template v-else>
                            <h2 class="text--white text-center mb20">{{$t('views.Webinars.Webinars.title')}}</h2>
                        </template>
                    </div>
                </div>

            </div>
        </section>

        <section class="pt120 pt60:sm pb120 pb60:sm bg--bg">
            <div class="container">
                <div class="row flex--center">
                    <div class="col-md-6 pb60">
                        <h3 class="text-center text-section-title wow">
                            {{$t('views.Webinars.Webinars.title')}}
                        </h3>
                    </div>
                </div>
                <div class="flex flex--center" v-if="loading">
                    <div class="loading-indicator"></div>
                </div>

                <div v-if="!loading">
                    <template class="mb60" v-if="webinarList">
                        <div class="mb40">
                            <h4>{{$t('views.Webinars.Webinars.announcement_title')}}</h4>
                        </div>

                        <div class="row">
                            <!-- Change webinarList to webinars after dev -->
                            <div class="col-sm-4 pb40 pb20:sm flex--one wow" v-for="webinar in webinarList" :key="webinar.name">
                                <webinar-card :webinar="webinar"></webinar-card>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="info-box info-box--medium text-center mb60">
                            <div class="info-box__icon">
                                <svg class="" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path></svg>
                            </div>
                            <h4>{{$t('views.Webinars.Webinars.announcement_title')}}</h4>
                        </div>
                    </template>

                    <div v-if="webinarListArchived">
                        <div class="mb40">
                            <h4>{{$t('views.Webinars.Webinars.archive_title')}}</h4>
                        </div>

                        <div class="row">
                            <!-- Change webinarList to webinars after dev -->
                            <div class="col-sm-4 pb40 pb20:sm flex--one wow" v-for="webinarArchived in webinarListArchived" :key="webinarArchived.name">
                                <webinar-card :webinar="webinarArchived"></webinar-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {{ webinarListArchived }}
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';

import VueContentLoading from 'vue-content-loading';
import WebinarCard from '../../components/Webinars/WebinarCard';

export default {
    name: 'Webinars',

    components: {
        'countdown': VueCountdown,
        VueContentLoading,
        WebinarCard,
    },

    data() {
        return {
            featuredLoaded: false,
            loading: true,
        };
    },

    computed: {
        ...mapState({
            // courses: state => state.courses.list.data,
            // coursesAll: state => state.courses.all,
            // categories: state => state.categories.categories,
            // featured: state => state.courses.featured,
            // limit: state => state.courses.list.limit,
            // total: state => state.courses.list.total,
            portalName: state => state.other.portalName,
            webinarList: state => state.webinars.list,
            webinarListArchived: state => state.webinars.archivedList,
            webinarList2: state => state.courses.list.data,
        }),

        webinars() {
            console.log(this.webinarList2)
            return this.webinarList.slice(1, this.webinarList.length)
        },

        featuredWebinar() {
            return this.webinarList[0]
        },

        countdown_time() {
            const now = new Date();
            const to = new Date(this.featuredWebinar.startsAt);
            const end = new Date(this.featuredWebinar.endsAt);

            if ((to - now) < 0) {
                if ((end - now) > 0) {
                    return 0;
                }

                return end - now;
            }

            return to - now;
        },
    },

    methods: {
    },

    beforeMount() {
        document.title = `${this.portalName} - ${this.$t('views.Courses.Courses.title')}`;
        this.$store.dispatch('courses/fetch').then(() => {
            this.loading = false;
        });
        this.$store.dispatch('courses/fetchFeatured');
        this.$store.dispatch('categories/fetch');

        this.$store.commit('webinars/resetList');
        this.$store.dispatch('webinars/fetchWebinars', {});
        this.$store.dispatch('webinars/fetchArchivedWebinars', {});
    },
}
</script>

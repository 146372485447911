import { portal } from '../../api/api';

import Latest from '../../api/graphql/events/latest.graphql';
import Events from '../../api/graphql/events/events.graphql';
import One from '../../api/graphql/events/one.graphql';
import EventEnroll from '../../api/graphql/events/enroll.graphql';

export default {
    fetchLatest: ({ commit }) => {
        portal(Latest, {
            order: { dateFrom: { position: 0, direction: 'ASC' } },
            pagination: { limit: 4, page: 1 },
        }).then((success) => {
            commit('saveLatest', success.data.data.events.data);
            return Promise.resolve(success.data.data.events.data);
        }, (error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },

    fetchAll: ({ commit }) => {
        return portal(Events, {})
            .then((success) => {
                commit('saveAll', success.data.data.events.data );
                return Promise.resolve(success.data.data.events.data);
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    fetch: ({ commit, state }) => {
        const searchQuery = {
            pagination: {
                limit: state.list.limit,
                page: state.list.page,
            },
            filter: {},
        };
        if (state.list.search) {
            searchQuery.filter.name = {
                value: state.list.search,
                comparison: 'LIKE',
            };
        }
        if (state.list.category) {
            searchQuery.filter.categoryId = {
                value: state.list.category,
                comparison: 'EQ',
            };
        }
        return portal(Events, { order: { dateFrom: { position: 0, direction: 'ASC' } }, ...searchQuery })
            .then((success) => {
                commit('saveListAttr', { field: 'total', payload: success.data.data.events.total });
                commit('saveListAttr', { field: 'data', payload: success.data.data.events.data });
                return Promise.resolve(success.data.data.events.data);
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    changePagination: ({ commit }, page) => {
        commit('saveListAttr', { field: 'page', payload: page });
    },
    changeCategory: ({ commit }, category) => {
        commit('saveListAttr', { field: 'category', payload: category });
    },
    changeSearch: ({ commit }, search) => {
        commit('saveListAttr', { field: 'search', payload: search });
    },

    fetchUpcoming: ({ commit }) => {
        portal(Events, {
            order: { dateFrom: { position: 0, direction: 'ASC' } },
            filter: { dateFrom: { value: new Date(), comparison: 'GT' } },
        }).then((success) => {
            commit('saveUpcoming', success.data.data.events.data);
        });
    },

    fetchOne: ({ commit }, stub) => portal(One, {
        filter: { stub: { value: stub, comparison: 'EQ' } },
    })
        .then((success) => {
            commit('one', success.data.data.events.data[0]);
            return Promise.resolve(success.data.data.events.data[0]);
        })
        .catch(error => Promise.reject(error)),

    eventEnroll: (context, eventId) => portal(EventEnroll, {
        eventId,
    })
        .then((success) => {
            if (success.data.data && success.data.data.eventEnrol) {
                return Promise.resolve();
            }
            return Promise.reject(success.data.errors[0].message);
        }),

    enrollPopup: ({ commit }, value) => {
        commit('setEnrollPopup', value);
    },
};

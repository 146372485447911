<template>
    <section class="s-author bg--cool-gray-light">
        <div class="container">
            <div class="row">
                <div class="col-sm-4 col-md-5 relative">
                    <div class="s-author__image-wrapper">
                        <img class="s-author__image" :src="require('../../assets/images/trener-02.jpg')">
                    </div>
                    <div class="s-author__name">
                        {{ $t('components._sections.Author.author_name') }}
                    </div>
                    <div class="s-author__name-bg">
                        <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5f48aa88951eed6da1cf6bf0_dots.png" alt="">
                    </div>
                </div>
                <div class="col-sm-8 col-md-7">
                    <div class="pl40 pr40 pt60 pb40">
                        <h4 class="text-thin mb20">
                            {{ $t('components._sections.Author.author_title') }}
                        </h4>
                        <h3 class="mb20">
                            {{ $t('components._sections.About2.title') }}
                        </h3>
                        <p class="h4 s-author__text mb40">
                            {{ $t('components._sections.Author.author_tekst') }}
                        </p>

                        <div class="features">
                            <div class="row">
                                <div class="col-sm-4 features__item">
                                    <div class="features__icon">
                                        <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5fa408bac7b5ba756a1da2eb_0223-medal-first.png" alt="">
                                    </div>
                                    <h5 class="features__title">{{ $t('components._sections.Author.features.0.title') }}</h5>
                                    <p>
                                        {{ $t('components._sections.Author.features.0.description') }}
                                    </p>
                                </div>
                                <div class="col-sm-4 features__item">
                                    <div class="features__icon">
                                        <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5fa408bab331b3cdbc92393f_0224-medal-second.png" alt="">
                                    </div>
                                    <h5 class="features__title">{{ $t('components._sections.Author.features.1.title') }}</h5>
                                    <p>
                                        {{ $t('components._sections.Author.features.1.description') }}
                                    </p>
                                </div>
                                <div class="col-sm-4 features__item">
                                    <div class="features__icon">
                                        <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5fa408baeb48dfc5006910a8_0225-medal-third.png" alt="">
                                    </div>
                                    <h5 class="features__title">{{ $t('components._sections.Author.features.2.title') }}</h5>
                                    <p>
                                        {{ $t('components._sections.Author.features.2.description') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'section-author',
    };
</script>

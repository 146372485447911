<template>
    <div>
        <section class="bg--image ph"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover_small.jpg')})`}">
            <div class="container pt90 pt30:sm pb150 pb120:sm">
                <div class="row flex--center flex--middle pb20">
                    <div class="col-md-8">
                        <h2 class="text--white text-center">{{page && page.title}}</h2>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container pb90 pb60:sm">
                <div class="flex flex--center">
                    <div class="col-md-9 bg--white pt30 pt15:sm pb30:sm box pb45" style="margin-top: -90px;">
                        <div class="row flex flex--center">
                            <div class="col-md-10 col-xs-11">
                                <div v-html="page && page.content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    watch: {
        page: (val) => {
            document.title = `${this.portalName} - ${val.title}`;
        },

        portalName: {
            handler(portalName) {
                document.title = `${portalName} - ${val.title}`;
            },
        },
    },
    computed: {
        ...mapState({
            pages: state => state.other.pages,
            portalName: state => state.other.portalName,
        }),

        page() {
            return this.pages.filter(p => p.stub === this.$route.params.stub)[0] || null;
        },
    },

    beforeMount() {
        this.$matomo && this.$matomo.trackPageView();
        document.title = `${this.portalName}`;
    },
};
</script>

<template>
    <div class="modal" :class="{'open': open}" @click="close()">
        <div class="modal__box" @click.stop="">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-modal',

    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

export default {
  one: {
    id: null,
    name: null,
    price: null,
    free: null,
    startsAt: null,
    endsAt: null,
    image: null,
    description: null,
    category: null,
    enrolled: null,
    authors: [],
    url: null,
    additionalContent: [],
    enrolmentsCount: null,
    processed: null,
    video: {
      sources: {
        q360p: null,
        q720p: null,
      },
    },
    lastPayment: {
      status: null,
    },
    recurring: {
      nextWebinars: [],
    },
  },

  oneAuthor: {
    id: null,
    name: null,
    startsAt: null,
    endsAt: null,
    image: null,
    description: null,
    category: null,
    rtmpUrl: null,
    url: null,
    enrolments: [],
    done: false,
    video: {
      sources: {
        q360p: null,
        q720p: null,
      },
    },
  },

  edit: {
    id: null,
    name: null,
    price: null,
    stub: null,
    free: true,
    starts: '',
    ends: '',
    image: { id: null, uri: null },
    description: '',
    category: null,
    additionalContent: [],
    status: null,
    organization: null,
    onEnd: null,
  },
};

export default {
    languages: [],
    fbPixel: null,
    gAnalytics: null,
    popup: false,
    popupView: 'login',
    pages: [],
    portalName: '',

    index6Form: {
        name: null,
        email: null,
    },

    booksForm: {
        name: null,
        email: null,
    },
};

<template>
    <div>
        <header class="header header__line scroll" :class="{'scroll': scroll || white,'open': open}" v-if="type === 'default'">
            <div class="container height100">
                <div class="flex flex--between flex--middle header__box">
                    <router-link :to="{name: 'index', params: {lang: $i18n.locale}}" class="header__logo">
                        <img src="../../assets/images/logos/logo4.png">
                        <img src="../../assets/images/logos/logo3.png">
                    </router-link>
                    <div class="header__links" :class="{'open': open}">
                        <nav>
                            <ul class="flex flex--middle">
                                <li>
                                    <router-link :to="{ name: 'courses', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.courses')}}
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'webinars', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.webinars')}}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'events', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.events')}}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'authors', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.authors')}}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'partners', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.partners')}}
                                    </router-link>
                                </li>

                                <li v-if="auth">
                                    <router-link :to="{ name: 'profile', params: {lang: $i18n.locale} }">
                                        {{$t('components.Base.Header.profile')}}
                                    </router-link>
                                </li>
                                <li v-if="auth">
                                    <a @click="logout()">{{$t('components.Base.Header.logout')}}</a>
                                </li>
                                <li v-if="!auth">
                                    <router-link :to="{ name: 'login', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.login')}}
                                    </router-link>
                                </li>
                                <li v-if="!auth">
                                    <button @click="$router.push({name:'register', params: {lang: $i18n.locale}})" class="btn" :to="{ name: 'register' }">
                                        {{$t('components.Base.Header.register')}}
                                    </button>
                                </li>
                                <li v-if="cart.length>0">
                                    <router-link :to="{name: 'cart', params: {lang: $i18n.locale}}" class="relative">
                                        <v-icon-cart :class="{'text--white': !(scroll || white)}"></v-icon-cart>
                                        <div class="absolute bg--gradient"
                                             style="border-radius: 50%;width: 14px;height: 14px;top: -4px; right: -8px">
                                            <div class="text--white text-center" style="font-size: 10px;line-height: 14px;">
                                                {{cart.length}}
                                            </div>
                                        </div>
                                    </router-link>
                                </li>

                                <li class="flex flex--middle cursor">
                                    <div class="hide:sm">
                                        <v-icon-slo v-if="lang === 'SI'"></v-icon-slo>
                                        <v-icon-eng v-if="lang === 'EN'"></v-icon-eng>
                                        <v-icon-cro v-if="lang === 'HR'"></v-icon-cro>
                                        <v-icon-de v-if="lang === 'DE'"></v-icon-de>
                                        <v-icon-srb v-if="lang === 'RS'"></v-icon-srb>
                                    </div>
                                    <ul v-if="languages.length > 0">
                                        <li v-for="(language, langi) of languages" class="cursor" :key="langi"
                                            :class="{'show-block:sm': lang===language.name}"
                                            @click="lang=language.name">
                                            <v-icon-slo v-if="language.name==='SI'"></v-icon-slo>
                                            <v-icon-eng v-else-if="language.name==='EN'"></v-icon-eng>
                                            <v-icon-cro v-else-if="language.name==='HR'"></v-icon-cro>
                                            <v-icon-de v-else-if="language.name==='DE'"></v-icon-de>
                                            <v-icon-srb v-else-if="language.name==='RS'"></v-icon-srb>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <button class="header__btn" @click="open=!open" :class="{'open': open}">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </header>

        <header class="header" :class="{'scroll': scroll || white,'open': open}">
            <div class="container height100">
                <div class="flex flex--between flex--middle header__box">
                    <router-link :to="{name: 'index', params: {lang: $i18n.locale}}" class="header__logo">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 270.6 84.1" enable-background="new 0 0 270.6 84.1" xml:space="preserve">
                            <g>
                                <g>
                                    <path fill="#00D5FF" d="M69.8,43.5c3.4-0.3,6-0.7,7.6,1c7.9,8.8,7.7,12.8,1.3,14l0,0c4.3,9.1,6,18.2-0.9,22.4l0,0
                                        C66,88.5,40.7,79.4,18.3,59.2l0,0c14.1,2,31.9,7.2,46.4,9.5c-3.4-6.5-11.5-15.4-16.7-22C55.6,45.2,63.1,44.3,69.8,43.5"/>
                                    <path fill="#00D5FF" d="M89.6,0.5c3.1,6.6,3.2,20.2,0.4,28.4c-5,14.4-43.4,0.3-70.5-4.1c3.4,6.5,11.5,15.4,16.7,22
                                        c-7.6,1.5-19.6,2.9-26.4,3.8C1,38-5.9,15.7,9.7,11.2c12.8-3.7,30.1,0.4,45.9,0.6c11.8-2.6,15.8-5.6,20.6-10
                                        c0.4,2.5-0.1,4.9-0.8,7.5C85.1,6.8,88.6,3.1,89.6,0.5"/>
                                </g>
                                <g>
                                    <g>
                                        <path fill="currentColor" d="M135.7,29.4c0.8,1.8,1.2,3.8,1.2,5.9v0.2c0,0.1,0,0.2,0,0.3l-0.1,0.5h-3.4v0.1h-18.7
                                            c0.2,1.5,0.9,2.8,2,3.9c1.4,1.4,3,2.1,4.9,2.1c1.9,0,3.5-0.7,4.9-2.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l4.7,6.7
                                            c-1.3,1.1-2.7,1.9-4.1,2.5c-1.9,0.7-3.8,1.1-5.8,1.1c-2.7,0-5.2-0.6-7.5-1.9c-2.4-1.3-4.3-3.1-5.6-5.4c-1.4-2.3-2.1-4.8-2.1-7.7
                                            c0-2.1,0.4-4.1,1.2-5.9c0.8-1.8,1.9-3.4,3.4-4.7c1.5-1.4,3.1-2.4,4.9-3.1c1.9-0.7,3.8-1.1,5.8-1.1c2,0,3.9,0.4,5.8,1.1
                                            c1.8,0.7,3.5,1.7,4.9,3.1C133.7,26,134.8,27.5,135.7,29.4z M121.6,28.3c-1.2,0-2.4,0.3-3.4,0.9c-0.7,0.4-1.3,1-1.9,1.7h10.7
                                            c-0.6-0.7-1.3-1.3-1.9-1.7C123.9,28.6,122.8,28.3,121.6,28.3z"/>
                                        <path fill="currentColor" d="M139.4,49.3V10.8h23.4V19h-13.9v6.7h7.7l2.2,8.1h-9.9v15.6L139.4,49.3z"/>
                                        <path fill="currentColor" d="M175.6,28.8c-0.5-0.3-1-0.5-1.8-0.5h-0.5c-2.2,0.1-3.3,1.6-3.3,4.6v16.5h-8.7V32.9c0-7.9,4-12.1,12-12.5
                                            h0.5c0.6,0,1.2,0.1,1.8,0.2V28.8z"/>
                                        <path fill="currentColor" d="M207.5,29.4c0.8,1.8,1.2,3.8,1.2,5.9v0.2c0,0.1,0,0.2,0,0.3l-0.1,0.5h-3.4v0.1h-18.7
                                            c0.2,1.5,0.9,2.8,2,3.9c1.4,1.4,3,2.1,4.9,2.1c1.9,0,3.5-0.7,4.9-2.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l4.7,6.7
                                            c-1.3,1.1-2.7,1.9-4.1,2.5c-1.9,0.7-3.8,1.1-5.8,1.1c-2.7,0-5.2-0.6-7.5-1.9c-2.4-1.3-4.3-3.1-5.6-5.4c-1.4-2.3-2.1-4.8-2.1-7.7
                                            c0-2.1,0.4-4.1,1.2-5.9c0.8-1.8,1.9-3.4,3.4-4.7c1.5-1.4,3.1-2.4,4.9-3.1c1.9-0.7,3.8-1.1,5.8-1.1c2,0,3.9,0.4,5.8,1.1
                                            c1.8,0.7,3.5,1.7,4.9,3.1C205.6,26,206.7,27.5,207.5,29.4z M193.4,28.3c-1.2,0-2.4,0.3-3.4,0.9c-0.7,0.4-1.3,1-1.9,1.7h10.7
                                            c-0.6-0.7-1.3-1.3-1.9-1.7C195.8,28.6,194.6,28.3,193.4,28.3z"/>
                                        <path fill="currentColor" d="M223.2,28.3c-2.2,0.1-3.3,1.6-3.3,4.6v16.5h-8.7V32.9c0-7.9,4-12.1,12-12.5h0.5c8,0.4,12,4.6,12,12.5
                                            v16.5h-8.7V32.9c0-3-1.1-4.5-3.3-4.6H223.2z"/>
                                        <path fill="currentColor" d="M270.2,35.5c0,2.8-0.8,5.3-2.2,7.5c-1.5,2.3-3.4,4-5.9,5.3c-2.5,1.3-5.1,2-7.9,2c-2.1,0-4.1-0.4-6-1.1
                                            c-1.9-0.7-3.6-1.7-5.1-3.1c-1.5-1.4-2.7-3-3.5-4.8c-0.8-1.8-1.3-3.8-1.3-5.9c0-2.1,0.4-4.1,1.3-5.9c0.8-1.8,2-3.4,3.5-4.8
                                            c1.5-1.4,3.2-2.4,5.1-3.1c2-0.7,4-1.1,6-1.1c2.1,0,4.1,0.4,6,1.1l0.6,0.3l0.6,0.3V10.6l8.7,0V35.5z M261.5,35.3
                                            c0-1.3-0.3-2.4-1-3.5c-0.7-1.1-1.6-1.9-2.7-2.6c-1.1-0.6-2.3-1-3.6-1s-2.5,0.3-3.6,1c-1.1,0.6-2,1.5-2.7,2.6
                                            c-0.7,1.1-1,2.2-1,3.5c0,1.9,0.7,3.6,2.2,5c1.4,1.4,3.1,2.1,5.1,2.1c2,0,3.7-0.7,5.1-2.1C260.8,38.9,261.5,37.3,261.5,35.3z"/>
                                    </g>
                                </g>
                                <g>
                                    <path fill="currentColor" d="M114,78c-1.4,0-2.5-0.3-3.5-0.9c-1-0.6-1.8-1.4-2.4-2.5s-0.8-2.3-0.8-3.8c0-1.5,0.3-2.8,0.8-3.9
                                        c0.6-1.1,1.3-2,2.3-2.5c1-0.6,2.2-0.9,3.5-0.9c1.4,0,2.6,0.3,3.5,1c1,0.6,1.7,1.5,2.2,2.7c0.5,1.2,0.7,2.6,0.6,4.2H118v-0.8
                                        c0-1.7-0.4-3-1-3.8s-1.6-1.3-3-1.3c-1.4,0-2.5,0.5-3.2,1.4c-0.7,0.9-1.1,2.2-1.1,3.9c0,1.6,0.4,2.9,1.1,3.8
                                        c0.7,0.9,1.8,1.3,3.1,1.3c0.9,0,1.7-0.2,2.4-0.6c0.7-0.4,1.2-1,1.6-1.8l2.2,0.8c-0.5,1.2-1.3,2.2-2.4,2.8
                                        C116.6,77.6,115.4,78,114,78z M108.9,71.4v-1.8h10.2v1.8H108.9z"/>
                                    <path fill="currentColor" d="M123.1,77.6l0-18.4h2.3v11.2l5.8-6.6h2.9l-6.1,6.9l6.8,6.9h-3.2l-6.3-6.6v6.6H123.1z"/>
                                    <path fill="currentColor" d="M136.4,61.4v-2.3h2.3v2.3H136.4z M136.4,77.6V63.8h2.3v13.8H136.4z"/>
                                    <path fill="currentColor" d="M142.5,83.7V63.8h2V74h0.3v9.8H142.5z M148.8,78c-1.3,0-2.4-0.3-3.3-1s-1.6-1.5-2-2.6
                                        c-0.5-1.1-0.7-2.3-0.7-3.7c0-1.4,0.2-2.6,0.7-3.7c0.5-1.1,1.1-2,2-2.6c0.9-0.6,2-0.9,3.3-0.9c1.3,0,2.4,0.3,3.3,0.9
                                        c0.9,0.6,1.6,1.5,2.1,2.6c0.5,1.1,0.7,2.3,0.7,3.7c0,1.4-0.2,2.6-0.7,3.7c-0.5,1.1-1.2,2-2.1,2.6C151.2,77.7,150.1,78,148.8,78z
                                        M148.5,75.9c0.9,0,1.7-0.2,2.3-0.7c0.6-0.5,1-1.1,1.3-1.9s0.4-1.7,0.4-2.6c0-1-0.1-1.8-0.4-2.6c-0.3-0.8-0.7-1.4-1.4-1.9
                                        s-1.4-0.7-2.3-0.7c-0.9,0-1.6,0.2-2.2,0.7s-1,1-1.3,1.8c-0.3,0.8-0.4,1.7-0.4,2.7c0,1,0.1,1.9,0.4,2.7s0.7,1.4,1.3,1.8
                                        S147.6,75.9,148.5,75.9z"/>
                                    <path fill="currentColor" d="M163.7,78c-1.4,0-2.5-0.3-3.5-0.9c-1-0.6-1.8-1.4-2.4-2.5c-0.6-1.1-0.8-2.3-0.8-3.8c0-1.5,0.3-2.8,0.8-3.9
                                        c0.6-1.1,1.3-2,2.3-2.5c1-0.6,2.2-0.9,3.5-0.9c1.4,0,2.6,0.3,3.5,1c1,0.6,1.7,1.5,2.2,2.7c0.5,1.2,0.7,2.6,0.6,4.2h-2.3v-0.8
                                        c0-1.7-0.4-3-1-3.8c-0.7-0.8-1.6-1.3-3-1.3c-1.4,0-2.5,0.5-3.2,1.4c-0.7,0.9-1.1,2.2-1.1,3.9c0,1.6,0.4,2.9,1.1,3.8
                                        c0.7,0.9,1.8,1.3,3.1,1.3c0.9,0,1.7-0.2,2.4-0.6c0.7-0.4,1.2-1,1.6-1.8l2.2,0.8c-0.5,1.2-1.3,2.2-2.4,2.8S165.1,78,163.7,78z
                                        M158.6,71.4v-1.8h10.2v1.8H158.6z"/>
                                    <path fill="currentColor" d="M178.2,78c-1.3,0-2.4-0.3-3.3-1c-0.9-0.6-1.6-1.5-2.1-2.6c-0.5-1.1-0.7-2.3-0.7-3.7c0-1.4,0.2-2.6,0.7-3.7
                                        c0.5-1.1,1.2-2,2.1-2.6c0.9-0.6,2-0.9,3.3-0.9c1.3,0,2.4,0.3,3.3,0.9s1.6,1.5,2,2.6c0.5,1.1,0.7,2.3,0.7,3.7
                                        c0,1.4-0.2,2.6-0.7,3.7c-0.5,1.1-1.1,2-2,2.6S179.5,78,178.2,78z M178.5,75.9c0.9,0,1.7-0.2,2.3-0.7s1-1.1,1.3-1.8
                                        c0.3-0.8,0.4-1.7,0.4-2.7c0-1-0.1-1.9-0.4-2.7c-0.3-0.8-0.7-1.4-1.3-1.8c-0.6-0.4-1.3-0.7-2.2-0.7c-0.9,0-1.7,0.2-2.3,0.7
                                        c-0.6,0.5-1.1,1.1-1.4,1.9s-0.4,1.7-0.4,2.6c0,1,0.1,1.9,0.4,2.6c0.3,0.8,0.7,1.4,1.3,1.9C176.8,75.7,177.6,75.9,178.5,75.9z
                                        M182.5,77.6V67.5h-0.3v-8.2h2.3v18.4H182.5z"/>
                                    <path fill="currentColor" d="M188.4,61.4v-2.3h2.3v2.3H188.4z M188.4,77.6V63.8h2.3v13.8H188.4z"/>
                                    <path fill="currentColor" d="M192.4,83.7v-2.2h0.7c0.7,0,1.2-0.2,1.5-0.5c0.3-0.3,0.5-0.9,0.5-1.6V63.8h2.3v16.4c0,1.1-0.3,2-0.9,2.6
                                        s-1.4,0.9-2.6,0.9H192.4z M195.2,61.4v-2.3h2.3v2.3H195.2z"/>
                                    <path fill="currentColor" d="M205.1,78c-1,0-1.9-0.2-2.6-0.6c-0.7-0.4-1.2-0.9-1.6-1.5s-0.5-1.3-0.5-2c0-0.7,0.1-1.3,0.4-1.9
                                        c0.3-0.5,0.7-1,1.2-1.3c0.5-0.4,1.1-0.6,1.8-0.8c0.7-0.2,1.4-0.3,2.2-0.5s1.6-0.3,2.5-0.4c0.8-0.1,1.6-0.2,2.3-0.3l-0.8,0.5
                                        c0-1.2-0.2-2.1-0.7-2.7c-0.5-0.6-1.4-0.9-2.7-0.9c-0.8,0-1.6,0.2-2.2,0.6c-0.6,0.4-1.1,1-1.3,1.8l-2.2-0.6c0.3-1.2,1-2.2,1.9-2.8
                                        c1-0.7,2.2-1,3.8-1c1.2,0,2.3,0.2,3.2,0.7c0.9,0.4,1.5,1.1,1.9,2c0.2,0.4,0.3,0.9,0.4,1.4c0.1,0.5,0.1,1,0.1,1.6v8.5h-2v-3.3
                                        l0.5,0.3c-0.5,1.1-1.2,2-2.2,2.5S206.4,78,205.1,78z M205.4,76.1c0.8,0,1.5-0.1,2.1-0.4c0.6-0.3,1.1-0.7,1.4-1.2
                                        c0.4-0.5,0.6-1,0.7-1.6c0.1-0.4,0.2-0.9,0.2-1.4c0-0.5,0-0.9,0-1.2l0.8,0.3c-0.7,0.1-1.5,0.2-2.2,0.3c-0.7,0.1-1.4,0.2-2,0.3
                                        c-0.6,0.1-1.2,0.2-1.7,0.4c-0.4,0.1-0.7,0.3-1.1,0.5c-0.3,0.2-0.6,0.4-0.8,0.7c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.4,0.1,0.7,0.3,1.1
                                        c0.2,0.3,0.5,0.6,0.9,0.8C204.2,76,204.8,76.1,205.4,76.1z"/>
                                </g>
                            </g>
                            </svg>
                    </router-link>
                    <div class="header__links" :class="{'open': open}">
                        <nav>
                            <ul class="flex flex--middle">

                                <li>
                                    <router-link :to="{ name: 'courses', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.courses')}}
                                    </router-link>
                                </li>

                                <li>
                                    <a href="https://efrend.si">{{$t('components.Base.Header.portal')}}</a>
                                </li>
                            <!--
                                <li>
                                    <router-link :to="{ name: 'webinars', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.webinars')}}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'events', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.events')}}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'authors', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.authors')}}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'partners', params: {lang: $i18n.locale} }">{{$t('components.Base.Header.partners')}}
                                    </router-link>
                                </li>
                            -->
                                <li v-if="auth">
                                    <router-link :to="{ name: 'profile', params: {lang: $i18n.locale} }">
                                        {{$t('components.Base.Header.profile')}}
                                    </router-link>
                                </li>
                                <li v-if="auth">
                                    <a @click="logout()">{{$t('components.Base.Header.logout')}}</a>
                                </li>
                                <li v-if="!auth">
                                    <a :href="loginLinkUrl">{{$t('components.Base.Header.login')}}</a>
                                </li>

                                <li v-if="!auth">
                                    <a :href="registerLinkUrl">{{$t('components.Base.Header.register')}}</a>
                                </li>

                                <li v-if="cart.length>0">
                                    <router-link :to="{name: 'cart', params: {lang: $i18n.locale}}" class="relative">
                                        <!-- v-icon :class="{'text--white': !(scroll || white)}" -->
                                        <v-icon-cart></v-icon-cart>
                                        <div class="absolute bg--gradient"
                                             style="border-radius: 50%;width: 14px;height: 14px;top: -4px; right: -8px">
                                            <div class="text--white text-center" style="font-size: 10px;line-height: 14px;">
                                                {{cart.length}}
                                            </div>
                                        </div>
                                    </router-link>
                                </li>

                                <!-- <li class="flex flex--middle cursor">
                                    <div class="hide:sm">
                                        <v-icon-slo v-if="lang === 'SI'"></v-icon-slo>
                                        <v-icon-eng v-if="lang === 'EN'"></v-icon-eng>
                                        <v-icon-cro v-if="lang === 'HR'"></v-icon-cro>
                                        <v-icon-de v-if="lang === 'DE'"></v-icon-de>
                                        <v-icon-srb v-if="lang === 'RS'"></v-icon-srb>
                                    </div>
                                    <ul v-if="languages.length > 0">
                                        <li v-for="(language, langi) of languages" class="cursor" :key="langi"
                                            :class="{'show-block:sm': lang===language.name}"
                                            @click="lang=language.name">
                                            <v-icon-slo v-if="language.name==='SI'"></v-icon-slo>
                                            <v-icon-eng v-else-if="language.name==='EN'"></v-icon-eng>
                                            <v-icon-cro v-else-if="language.name==='HR'"></v-icon-cro>
                                            <v-icon-de v-else-if="language.name==='DE'"></v-icon-de>
                                            <v-icon-srb v-else-if="language.name==='RS'"></v-icon-srb>
                                        </li>
                                    </ul>
                                </li> -->
                            </ul>
                        </nav>
                    </div>

                    <button class="header__btn" @click="open=!open" :class="{'open': open}">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </header>

    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Header',

    props: {
        type: {
            type: String,
            default: 'default'
        }
    },

    data() {
        return {
            scroll: false,
            open: false,
            white: false,
            loginLinkUrl: '',
            registerLinkUrl: '',
        };
    },

    watch: {
        open(val) {
            if (val) {
                document.body.classList.add('overflow');
            } else {
                document.body.classList.remove('overflow');
            }
        },

        $route(to) {
            this.white = to === 'lecture';
            this.open = false;

            this.registerLink();
            this.loginLink();
        },
    },

    computed: {
        ...mapState({
            auth: state => state.auth.token !== null,
            cart: state => state.shop.cart,
            languages: state => state.other.languages,
        }),

        lang: {
            get() {
                return this.$i18n.locale;
            },
            set(val) {
                this.$router.push(this.$route.fullPath.replace(this.$i18n.locale, val));
                this.$i18n.locale = val;
                localStorage.setItem('lang', val);
                this.open = false;
            },
        },
    },
    methods: {
        logout() {
            localStorage.removeItem('auth_token');

            // this.$store.commit('auth/saveLogin', {
            //     token: null,
            //     account: null,
            //     enrolledCourses: [],
            //     enrolledEvents: [],
            // });

            // this.$store.commit('auth/saveEnrolledCourses', []);

            window.location = process.env.VUE_APP_LOGOUT_REDIRECT;
        },

        registerLink() {
            this.registerLinkUrl = `${process.env.VUE_APP_REGISTER_REDIRECT}?origin=academy`;
        },

        loginLink() {
            this.loginLinkUrl = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
        }
    },

    mounted() {
        const self = this;
        window.addEventListener('scroll', () => {
            self.scroll = window.window.scrollY > 50;
        });
        this.white = this.$route.name === 'lecture';

        this.registerLink();
        this.loginLink();
    },
};
</script>

<template>
    <section class="bg--gradient" style="transform: skew(-5deg) rotate(-5deg)">
        <div class="width100" style="transform: skew(5deg) rotate(5deg)">

            <div class="container pt60 pb60">
                <div class="row flex--center">
                    <div class="col-md-8 pb40">
                        <h3 class="text-center text-section-title text--white wow slideInUp">{{$t('components._sections.Customers2.title')}}</h3>
                        <h4 class="text-center text-section-subtitle text--white wow slideInUp">
                            {{$t('components._sections.Customers2.subtitle')}}
                        </h4>
                    </div>
                </div>
                <div class="row wow slideInUp flex--row-reverse">
                    <div class="col-md-6 pb20:sm">
                        <v-video ref="video" width="600" height="400"
                                 class="video-js"
                                 poster="https://lhp-avantacademy.s3.amazonaws.com/lectures/749/still.jpg"
                                 webkit-playsinline
                                 playsinline
                                 controls
                                 :sources="['https://lhp-avantacademy.s3.amazonaws.com/lectures/749/video.mp4']"></v-video>
                    </div>
                    <div class="col-md-6 flex--one">
                        <div class="width100 height100 flex flex--middle">
                            <div>
                                <p class="text--white text-right">
                                    “
                                    {{$t('components._sections.Customers2.testimonial')}}
                                    ”
                                </p>
                                <div class="flex pt30 flex--right">
                                    <div class="pr10">
                                        <h6 class="text-right text--white"> 
                                            {{$t('components._sections.Customers2.author')}}
                                        </h6>
                                        <p class="text-right text--white">
                                            {{$t('components._sections.Customers2.company')}}
                                        </p>
                                    </div>
                                    <div class="bg--image img--circle"
                                         style="width: 50px;height:50px;background-image: url(../../assets/images/learn.jpg)"></div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    name: 'section-customers-2',
};
</script>

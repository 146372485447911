import { portal, base } from '../../api/api';
import One from '../../api/graphql/lectures/one.graphql';
import VideoLog from '../../api/graphql/lectures/videoLog.graphql';

export default {
    fetchOne: ({ commit }, stub) => portal(One, { filter: { stub: { value: stub, comparison: 'EQ' } } }).then((success) => {
        commit('one', success.data.data.lectures.data[0]);
        if (success.data.data.lectures.data[0].video) {
            if (success.data.data.lectures.data[0].video.slides && success.data.data.lectures.data[0].video.slides.length === 0 && success.data.data.lectures.data[0].video.chapters && success.data.data.lectures.data[0].video.chapters.length === 0) {
                commit('player', { field: 'layout', value: 3 });
            } else {
                commit('player', { field: 'layout', value: 1 });
            }
        } else {
            commit('player', { field: 'layout', value: 1 });
        }
        return Promise.resolve(success.data.data.lectures.data[0]);
    }, error => Promise.reject(error)),
    videoLog: ({ state }, type) => base(VideoLog, {
        portalId: process.env.VUE_APP_PORTAL_ID,
        lectureId: parseInt(state.one.id, 10),
        type,
        time: Math.floor(state.player.time),
    })
        .then((success) => {
            if (success.data.data && success.data.data.videoLog) {
                return Promise.resolve(success.data.data.videoLog);
            }
            return Promise.reject(success);
        }, error => Promise.reject(error)),
};

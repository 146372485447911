<template>
    <div>
        <form @submit.prevent="submit()">
            <input type="text" :placeholder="'Name'"
                   v-model="name"
                   @blur="$v.name.$touch()"
                   :class="{'error': $v.name.$invalid && $v.name.$dirty}"
                   class="mb10"
                   autocomplete="name"
                   required>
            <p v-if="!$v.name.required && $v.name.$dirty" class="errortext">
                {{ "Name Required" }}
            </p>
            <input type="email" :placeholder="'Email'"
                   v-model="email"
                   @blur="$v.email.$touch()"
                   :class="{'error': $v.email.$invalid && $v.email.$dirty}"
                   class="mb10"
                   autocomplete="email"
                   required>
            <p v-if="!$v.email.required && $v.email.$dirty" class="errortext">
                {{ "Email required" }}
            </p>
            <p v-if="!$v.email.email && $v.email.$dirty" class="errortext">
                {{ "Email email" }}
            </p>
            <button class="btn btn--full" :disabled="$v.$invalid">
                Submit
            </button>
            <p class="errortext text-center">{{error}}</p>
            <p class="text-center" v-if="success">Success</p>
        </form>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: 'form-sections-beta',

    data() {
        return {
            name: '',
            email: '',
            error: '',
            success: false,
        };
    },

    validations: {
        name: {
            required,
        },

        email: {
            email,
            required,
        },
    },

    methods: {
        submit() {

        },
    },
};
</script>
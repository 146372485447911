<template>
    <section class="bg--bg pt120 pb120 pt60:sm pb60:sm">
        <div class="container">
            <div class="row flex--center pb60">
                <div class="col-md-6">
                    <h3 class="text-center text-section-title wow slideInUp" v-html="$t('components._sections.LatestEvents.title')"></h3>
                    <h4 class="text-center text-section-subtitle wow slideInUp">
                        {{$t('components._sections.LatestEvents.description')}}
                    </h4>
                </div>
            </div>
            <div class="flex flex--center pt20 pb20" v-if="loading">
                <div class="loading-indicator"></div>
            </div>
            <div class="row" v-else>
                <div class="col-md-3 pb40 pb20:sm flex--one wow slideInUp" v-for="event in events"
                     :key="event.id">
                    <event-card :event="event" :grid="true"></event-card>
                </div>
                <div class="col-md-12" v-if="$store.state.events.latest.length<1">
                    <h4 class="text-center">{{$t('components._sections.LatestEvents.list.none')}}</h4>
                </div>
            </div>
            <div class="flex flex--center pt20">
                <router-link :to="{ name: 'events', params: {lang: $i18n.locale} }" class="btn">{{$t('components._sections.LatestEvents.link')}}
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import EventCard from '../Events/EventCard.vue';

export default {
    name: 'section-latest-events',

    components: {
        EventCard,
    },

    data() {
        return {
            loading: true,
        };
    },

    watch: {
        '$i18n.locale': {
            handler() {
                this.loading = true;
                this.$store.dispatch('events/fetchLatest')
                    .then(() => {
                        this.loading = false;
                    });
            },
        },
    },

    computed: {
        ...mapState({
            events: state => state.events.latest,
        }),
    },

    beforeCreate() {
        this.$store.dispatch('events/fetchLatest')
            .then(() => {
                this.loading = false;
            });
    },
};
</script>

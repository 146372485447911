<template>
    <div class="width100 height100">
        <router-link class="event-card box box--hover width100 height100 block relative"
                     :to="{ name: 'event', params: { stub: event.stub, lang: $i18n.locale }}" v-if="grid">
            <div class="event-card__image bg--image quadric width100"
                    :style="{'background-image': `url(${ event.image ? event.image.uri : '' })`}"></div>
            <div class="event-card__content">
                <div class="flex flex--wrap flex--middle mb20" v-if="event.authors && event.authors.length > 0">
                    <p class="mb5 mr10">
                        {{ event.authors && event.authors.map((author)=> (`${author.name}
                        ${author.surname}`)).join(', ') }}
                    </p>
                </div>

                <h6>{{ event.name }}</h6>

                <p class="mt20">
                    {{ event.abstract && event.abstract.substring(0,100) + "..." }}
                </p>
            </div>
            <div class="event-card__footer absolute absolute--bottom absolute--left absolute--right">
                <div class="flex flex--between flex--middle">
                    <div class="flex flex--middle">
                        <v-icon-clock height="18px" width="18px" class="text--secondary mr5"></v-icon-clock>
                        {{ event.dateFrom | date }} -
                        {{ event.dateTo | date }}
                    </div>
                    <template v-if="enrolled">
                        {{$t('components.Courses.CourseCard.enrolled')}}
                        {{ enrolledEvent && enrolledEvent.created | date }}
                    </template>
                </div>
            </div>
        </router-link>
        <router-link :to="{ name: 'event', params: { stub: event.stub, lang: $i18n.locale }}"
                     class="box box--hover block width100" v-else>
            <div class="flex width100">
                <div class="col-md-4 pl0 pr0 flex--one">
                    <div class="quadric bg--image height100 width100"
                            :style="{'background-image': `url(${ event.image ? event.image.uri : '' })`}"
                    ></div>
                </div>
                <div class="col-md-8 flex--one">
                    <div class="width100 pt20 pb20 pl10 pr10">
                        <h6>{{ event.name }}</h6>
                        <p class="small pt10">
                            {{ event.authors && event.authors.map((author)=> (`${author.name}
                            ${author.surname}`)).join(', ') }}
                        </p>
                        <p class="pt5 small">
                            {{ event.abstract && event.abstract.substring(0,120) + "..." }}
                        </p>
                        <hr>
                        <div class="flex flex--middle flex--between mb0 pt10">
                            <div class="flex">
                                <v-icon-calendar height="20px" width="20px"></v-icon-calendar>
                                <p class="mb0 ml10 small">
                                    {{ event.dateFrom | date }} -
                                    {{ event.dateTo | date }}
                                </p>
                            </div>
                            <div class="text-bold">
                                <template v-if="enrolled">
                                    {{$t('components.Courses.CourseCard.enrolled')}}
                                    {{ enrolledEvent.created | date }}
                                </template>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'event-card',

    props: {
        event: {
            type: Object,
            required: true,
        },

        grid: {
            type: Boolean,
            default: false,
        },

        profile: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    computed: {
        ...mapState({
            enrolledEvent(state) {
                return state.auth.enrolledEvents && state.auth.enrolledEvents.find(el => el.event.stub === this.event.stub);
            },
        }),

        enrolled() {
            return !!this.enrolledEvent;
        },
    },
};
</script>

<style scoped>

</style>

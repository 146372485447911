<template>
    <div>
        <form @submit.prevent="submit()" novalidate>
            <div class="relative mb10">
                <input :type="password.visible?'text':'password'"
                       :placeholder="$t('components.Forms.profile.Password.form.oldpassword.placeholder')"
                       v-model="password.value" style="padding-right: 40px"
                       required
                       @blur="$v.password.value.$touch()"
                       autocomplete="current-password"
                       :class="{'error': $v.password.value.$dirty && $v.password.value.$invalid}">
                <div class="absolute absolute--right absolute--top absolute--bottom">
                    <div class="flex flex--middle height100 pr10">
                        <div @click="password.visible=(!password.visible)" class="cursor">
                            <div>
                                <v-icon-eye v-if="password.visible"></v-icon-eye>
                                <v-icon-eyeOff v-else></v-icon-eyeOff>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="errortext" v-if="$v.password.value.$dirty && !$v.password.value.required">
                {{$t('components.Forms.profile.Password.form.oldpassword.required')}}</p>

            <div class="relative mb10">
                <input :type="newpassword.visible?'text':'password'"
                       :placeholder="$t('components.Forms.profile.Password.form.password.placeholder')"
                       v-model="newpassword.value" style="padding-right: 40px"
                       required
                       @blur="$v.newpassword.value.$touch()"
                       autocomplete="new-password"
                       :class="{'error': $v.newpassword.value.$dirty && $v.newpassword.value.$invalid}">
                <div :style="{'width': Math.min(100, scorePassword(newpassword.value))+'%', 'background':scorePassword(newpassword.value)>=60? 'green':'red' }"
                     style="height: 3px;"></div>
                <div class="absolute absolute--right absolute--top absolute--bottom">
                    <div class="flex flex--middle height100 pr10">
                        <div @click="newpassword.visible=(!newpassword.visible)" class="cursor">
                            <div>
                                <v-icon-eye v-if="newpassword.visible"></v-icon-eye>
                                <v-icon-eyeOff v-else></v-icon-eyeOff>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="errortext" v-if="$v.newpassword.value.$dirty && !$v.newpassword.value.required">
                {{$t('components.Forms.profile.Password.form.password.required')}}</p>
            <p class="errortext"
               v-if="$v.newpassword.value.$dirty && !$v.newpassword.value.passwordStrength && $v.newpassword.value.required">
                {{$t('components.Forms.profile.Password.form.password.strong')}}</p>

            <div class="relative mb10">
                <input :type="newrepassword.visible?'text':'password'"
                       :placeholder="$t('components.Forms.profile.Password.form.repassword.placeholder')"
                       v-model="newrepassword.value" style="padding-right: 40px"
                       required
                       @blur="$v.newrepassword.value.$touch()"
                       autocomplete="new-password"
                       :class="{'error': $v.newrepassword.value.$dirty && $v.newrepassword.value.$invalid}">
                <div :style="{'width': Math.min(100, scorePassword(newrepassword.value))+'%', 'background':scorePassword(newrepassword.value)>=60? 'green':'red' }"
                     style="height: 3px;"></div>
                <div class="absolute absolute--right absolute--top absolute--bottom">
                    <div class="flex flex--middle height100 pr10">
                        <div @click="newrepassword.visible=(!newrepassword.visible)" class="cursor">
                            <div>
                                <v-icon-eye v-if="newrepassword.visible"></v-icon-eye>
                                <v-icon-eyeOff v-else></v-icon-eyeOff>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="errortext" v-if="$v.newrepassword.value.$dirty && !$v.newrepassword.value.required">
                {{$t('components.Forms.profile.Password.form.repassword.required')}}</p>
            <p class="errortext"
               v-if="$v.newrepassword.value.$dirty && !$v.newrepassword.value.same && $v.newrepassword.value.required">
                {{$t('components.Forms.profile.Password.form.repassword.match')}}
            </p>
            <div class="flex flex--center">
                <div class="loading-indicator" v-if="sending"></div>
                <button class="btn" :disabled="$v.$invalid" v-else>{{$t('components.Forms.profile.Password.form.submit')}}
                </button>
            </div>
            <div class="success-box flex flex--middle mt10"  v-if="success">
                <v-icon-checkCircle class="mr10" ></v-icon-checkCircle>
                <div style="width: calc(100% - 50px)">
                {{success}}
                </div>
            </div>
            <div class="error-box flex flex--middle mt10"  v-if="error">
                <v-icon-xCircle class="mr10" ></v-icon-xCircle>
                <div style="width: calc(100% - 50px)">
                {{error}}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import { passwordStrength } from '../../../validators';

export default {
    name: 'form-profile-password',

    data() {
        return {
            sending: false,
            password: {
                value: '',
                visible: false,
            },
            newpassword: {
                value: '',
                visible: false,
            },
            newrepassword: {
                value: '',
                visible: false,
            },
            success: '',
            error: '',
        };
    },

    validations: {
        password: {
            value: {
                required,
            },
        },

        newpassword: {
            value: {
                required,
                passwordStrength,
            },
        },

        newrepassword: {
            value: {
                required,
                same: sameAs(function () {
                    return this.newpassword.value;
                }),
            },
        },
    },
    methods: {
        submit() {
            this.sending = true;
            this.error = '';
            this.success = '';
            this.$store.dispatch('auth/userPassword', {
                oldPassword: this.password.value,
                newPassword: this.newpassword.value,
            })
                .then(() => {
                    this.sending = false;
                    this.$v.$reset();
                    this.password.value = '';
                    this.newpassword.value = '';
                    this.newrepassword.value = '';
                    this.success = this.$t('components.Forms.profile.Password.form.success');
                })
                .catch((err) => {
                    this.sending = false;
                    this.error = err;
                });
        },

        scorePassword(pass) {
            return passwordStrength(pass);
        },
    },
};
</script>

import { portal } from '../../api/api';

import Card from '../../api/graphql/courses/card.graphql';
import One from '../../api/graphql/courses/one.graphql';
import Enroll from '../../api/graphql/courses/enroll.graphql';
import CourseProgress from '../../api/graphql/courses/courseProgress.graphql';

export default {
    fetchFeatured: ({ commit }) => portal(Card, { filter: { featured: { value: true, comparison: 'EQ' } } }).then((success) => {
        commit('saveFeatured', success.data.data.courses.data);
        return Promise.resolve(success.data.data.courses.data);
    }, (error) => {
        console.log(error);
        return Promise.reject(error);
    }),

    fetchAll: ({ commit, state }) => {
        return portal(Card, {})
            .then((success) => {
                commit('saveAll', success.data.data.courses.data);
                return Promise.resolve(success.data.data.courses.data);
            }, (error) => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    fetch: ({ commit, state }) => {
        const searchQuery = {
            pagination: {
                limit: state.list.limit,
                page: state.list.page,
            },
            filter: {},
        };
        if (state.list.search) {
            searchQuery.filter.name = {
                value: state.list.search,
                comparison: 'LIKE',
            };
        }
        if (state.list.category) {
            searchQuery.filter.categoryId = {
                value: state.list.category,
                comparison: 'EQ',
            };
        }
        return portal(Card, searchQuery)
            .then((success) => {
                commit('saveListAttr', { field: 'data', payload: success.data.data.courses.data });
                commit('saveListAttr', { field: 'total', payload: success.data.data.courses.total });
                return Promise.resolve(success.data.data.courses.data);
            }, (error) => {
                console.log(error);
                return Promise.reject(error);
            });
    },
    changePagination: ({ commit }, page) => {
        commit('saveListAttr', { field: 'page', payload: page });
    },
    changeCategory: ({ commit }, category) => {
        commit('saveListAttr', { field: 'category', payload: category });
    },
    changeSearch: ({ commit }, search) => {
        commit('saveListAttr', { field: 'search', payload: search });
    },
    fetchOne: ({ commit }, stub) => portal(One, { filter: { stub: { value: stub, comparison: 'EQ' } } }).then((success) => {
        commit('one', success.data.data.courses.data[0]);
        return Promise.resolve(success.data.data.courses.data[0]);
    }, (error) => {
        console.log(error);
        return Promise.reject(error);
    }),
    courseEnroll: (context, courseId) => portal(Enroll, {
        courseId,
    })
        .then((success) => {
            if (success.data.data && success.data.data.courseEnrol) {
                return Promise.resolve();
            }
            return Promise.reject(success.data.errors[0].message);
        }),
    courseProgress: (context, data) => portal(CourseProgress, data)
        .then((success) => {
            if (success.data.data && success.data.data.courseProgress) {
                return Promise.resolve(success.data.data.courseProgress);
            }
            return Promise.reject(success.data.errors[0].message);
        }),
    enrollPopup: ({ commit }, value) => {
        commit('setEnrollPopup', value);
    },
};

export default {
    loadCart(state) {
        const localCart = localStorage.getItem('cart');
        if (localCart) {
            state.cart = JSON.parse(localCart);
        }
    },
    addToCart(state, course) {
        const inCart = state.cart.find(el => el.id === course.id);
        if (!inCart) {
            state.cart.push(course);
        }
        localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    removeFromCart(state, id) {
        state.cart.splice(id, 1);
        localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    emptyCart(state) {
        state.cart = [];
        localStorage.setItem('cart', JSON.stringify(state.cart));
    },
};

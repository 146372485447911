<template>
    <section class="s-pricing">
        <!-- TODO: change accordions -->
        <div class="container">
            <div class="row flex--center">
                <div class="col-md-8">
                    <h3 class="mb10 text-center text-section-title">
                        {{ $t('components._sections.Pricing.title') }} 
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.Pricing.subtitle') }} 
                    </h4>
                </div>
            </div>
            <div class="s-pricing__content mt40">
                <div class="row">
                    <div class="col-md-4 mb30:sm">
                        <div class="s-pricing__item">
                            <h5 class="mb5">
                                {{ $t('components._sections.Pricing.price01.title') }} 
                            </h5>
                            <div class="s-pricing__title">
                                <h3>
                                    {{ $t('components._sections.Pricing.price01.price') }} 
                                </h3>
                                <p class="h5">
                                    {{ $t('components._sections.Pricing.price01.period') }} 
                                </p>
                            </div>
                            <ul class="s-pricing__list">
                                <li>{{ $t('components._sections.Pricing.price01.list.0.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price01.list.1.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price01.list.2.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price01.list.3.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price01.list.4.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price01.list.5.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price01.list.6.text') }}</li>
                            </ul>
                            <p>
                                <strong>{{ $t('components._sections.Pricing.price01.payment') }}</strong>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 mb30:sm">
                        <div class="s-pricing__item s-pricing__item--exposed">
                            <h5 class="mb5">
                                {{ $t('components._sections.Pricing.price02.title') }} 
                            </h5>
                            <div class="s-pricing__title">
                                <h3>
                                    {{ $t('components._sections.Pricing.price02.price') }} 
                                </h3>
                                <p class="h5">
                                    {{ $t('components._sections.Pricing.price02.period') }} 
                                </p>
                            </div>
                            <ul class="s-pricing__list">
                                <li>{{ $t('components._sections.Pricing.price02.list.0.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price02.list.1.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price02.list.2.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price02.list.3.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price02.list.4.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price02.list.5.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price02.list.6.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price02.list.6.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price02.list.8.text') }}</li>
                            </ul>
                            <p>
                                <strong>{{ $t('components._sections.Pricing.price02.payment') }}</strong>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="s-pricing__item">
                            <h5 class="mb5">
                                {{ $t('components._sections.Pricing.price03.title') }} 
                            </h5>
                            <div class="s-pricing__title">
                                <h3>
                                    {{ $t('components._sections.Pricing.price03.price') }}
                                </h3>
                                <p class="h5">
                                    {{ $t('components._sections.Pricing.price03.period') }}
                                </p>
                            </div>
                            <ul class="s-pricing__list">
                                <li>{{ $t('components._sections.Pricing.price03.list.0.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price03.list.1.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price03.list.2.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price03.list.3.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price03.list.4.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price03.list.5.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price03.list.6.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price03.list.7.text') }}</li>
                                <li>{{ $t('components._sections.Pricing.price03.list.8.text') }}</li>
                            </ul>
                            <p>
                                <strong>{{ $t('components._sections.Pricing.price03.payment') }}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'section-pricing',
};
</script>

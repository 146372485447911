<template>
    <section>
        <div class="container pt120 pt60:sm pb90 pb60:sm">
           <div class="row flex--center">
               <div class="col-md-5 flex--one pb30">
                   <div class="height100 width100 flex flex--middle">
                       <div>
                           <h3 class="text-section-title pb30">
                               {{ $t('components._sections.Content2.title') }}
                           </h3>
                           <h4 class="text-section-subtitle">
                               {{ $t('components._sections.Content2.content') }}
                           </h4>
                           <div class="pt30">
                               <a href="#" class="btn">
                                   {{ $t('components._sections.Content2.button') }}
                               </a>
                           </div>
                       </div>
                   </div>
               </div>
               <div class="col-md-1"></div>
               <div class="col-md-6 flex--one pb30">
                   <div class="row height100 width100">
                       <div class="col-md-6">
                           <div class="box pt45 pb20 pl30 pr30 mb30">
                               <div class="pb15">
                                   <v-icon-elastic height="100px" width="100px" />
                               </div>
                               <h6 class="pb10">
                                   {{ $t('components._sections.Content2.boxes.0.title') }}
                               </h6>
                               <p>
                                   {{ $t('components._sections.Content2.boxes.0.description') }}
                               </p>
                           </div>
                           <div class="box pt45 pb20 pl30 pr30 mb30">
                               <div class="pb15">
                                   <v-icon-elastic height="100px" width="100px" />
                               </div>
                               <h6 class="pb10">
                                   {{ $t('components._sections.Content2.boxes.1.title') }}
                               </h6>
                               <p>
                                   {{ $t('components._sections.Content2.boxes.1.description') }}
                               </p>
                           </div>
                       </div>
                       <div class="col-md-6 pt60 pt0:sm">
                           <div class="box pt45 pb20 pl30 pr30 mb30">
                               <div class="pb15">
                                   <v-icon-elastic height="100px" width="100px" />
                               </div>
                               <h6 class="pb10">
                                   {{ $t('components._sections.Content2.boxes.2.title') }}
                               </h6>
                               <p>
                                   {{ $t('components._sections.Content2.boxes.2.description') }}
                               </p>
                           </div>
                           <div class="box pt45 pb20 pl30 pr30 mb30">
                               <div class="pb15">
                                   <v-icon-elastic height="100px" width="100px" />
                               </div>
                               <h6 class="pb10">
                                   {{ $t('components._sections.Content2.boxes.3.title') }}
                               </h6>
                               <p>
                                   {{ $t('components._sections.Content2.boxes.3.description') }}
                               </p>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-content-2',
};
</script>

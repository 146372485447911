<template>
    <div class="c-accordion" @click="open=!open">
        <div class="c-accordion__header flex flex--middle flex--nowrap flex--between">
            <h5 class="c-accordion__title text-normal">{{title}}</h5>
            <div style="height: 24px;" class="c-accordion__icon link ml10" :style="{'transform': open? 'rotate(180deg)':'rotate(0deg)'}">
                <v-icon-chevronDown></v-icon-chevronDown>
            </div>
        </div>
        <transition name="fade">
            <div class="c-accordion__content" v-if="open">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'c-accordion',

    props: {
        title: {
            type: String,
        },
        opened: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            open: false,
        };
    },
    mounted() {
        this.open = this.opened;
    },
};
</script>

<style scoped>

</style>

<template>
    <section class="s-newsletter bg--bg pt60 pb20 pt60:sm pb60:sm">
        <div class="container">
            <div class="row flex--center pb20">
                <div class="col-md-8">
                    <h3 class="text-center text-section-title wow slideInUp pb10">{{$t('components._sections.Newsletter.title')}}</h3>
                    <h4 class="text-center text-section-subtitle wow slideInUp">
                        {{$t('components._sections.Newsletter.description')}}
                    </h4>
                </div>
            </div>
            <div class="row flex--center">
                <div class="col-md-6 wow slideInUp">
                    <form class="s-newsletter__form flex flex--between" novalidate
                        @submit="subscribe()">
                        <input type="text" :placeholder="$t('components._sections.Newsletter.form.email.placeholder')"
                               v-model="email" @blur="$v.email.$touch()" class="s-newsletter__input"
                               autocomplete="email">
                        <button class="s-newsletter__button btn" :disabled="$v.$invalid && $v.$dirty">{{$t('components._sections.Newsletter.form.submit')}}</button>
                    </form>
                    <div class="pt10">
                        <p class="errortext text-center" v-if="$v.email.$dirty && !$v.email.required">
                            {{$t('components._sections.Newsletter.form.email.required')}}
                        </p>
                        <p class="errortext text-center" v-if="$v.email.$dirty && !$v.email.email">
                            {{$t('components._sections.Newsletter.form.email.email')}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: 'section-newsletter',

    data() {
        return {
            email: '',
        };
    },

    validations: {
        email: {
            required,
            email,
        },
    },

    methods: {
        subscribe() {
        },
    },
};
</script>

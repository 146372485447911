<template>
    <section>
        <div class="container pt60 pt60:sm pb90 pb60:sm">
            <div class="row flex--center pb60">
                <div class="col-md-8">
                    <h3 class="text-center text-section-title">
                        {{ $t('components._sections.Calendar.title') }}
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.Calendar.subtitle') }}
                    </h4>
                </div>
            </div>
            <div class="row flex--center">
                <div class="col-md-6 flex--one pb60">
                    <div class="height100 width100 flex box">
                        <div class="col-md-4 pt15 pb15 border--blue-right">
                            <div class="flex flex--middle">
                                <h2 class="pr15">24</h2>
                                <div class="small">
                                    Februar<br>
                                    2020
                                </div>
                            </div>
                            <p class="text-bold">Trend Hotel</p>
                            <p>New York</p>

                            <p class="small pt15">*Conference</p>
                        </div>
                        <div class="col-md-8 pt30 pb15">
                            <h6 class="pb10">
                                Ipsum dolor sit amet, consectetur adipisicing elit
                            </h6>
                            <p>
                                Cupiditate debitis dicta ducimus est ipsa iure nostrum quas rerum totam voluptatibus
                            </p>
                            <a href="#" class="text--blue">
                                > {{ $t('components._sections.Calendar.link') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 flex--one pb60">
                    <div class="height100 width100 flex box">
                        <div class="col-md-4 pt15 pb15 border--blue-right">
                            <div class="flex flex--middle">
                                <h2 class="pr15">15</h2>
                                <div class="small">
                                    April<br>
                                    2020
                                </div>
                            </div>
                            <p class="text-bold">Austria Trend Hotel</p>
                            <p>Viena</p>

                            <p class="small pt15">*workshop</p>
                        </div>
                        <div class="col-md-8 pt30 pb15">
                            <h6 class="pb10">
                                Ipsum dolor sit amet, consectetur adipisicing elit
                            </h6>
                            <p>
                                Cupiditate debitis dicta ducimus est ipsa iure nostrum quas rerum totam voluptatibus
                            </p>
                            <a href="#" class="text--blue">
                                > {{ $t('components._sections.Calendar.link') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 flex--one pb60">
                    <div class="height100 width100 flex box">
                        <div class="col-md-4 pt15 pb15 border--blue-right">
                            <div class="flex flex--middle">
                                <h2 class="pr15">1</h2>
                               <div class="small">
                                    May<br>
                                    2020
                                </div>
                            </div>
                            <p class="text-bold">Hermitage Trend Hotel</p>
                            <p>Sankt Petersburg</p>

                            <p class="small pt15">*tutorial</p>
                        </div>
                        <div class="col-md-8 pt30 pb15">
                            <h6 class="pb10">
                                Ipsum dolor sit amet, consectetur adipisicing elit
                            </h6>
                            <p>
                                Cupiditate debitis dicta ducimus est ipsa iure nostrum quas rerum totam voluptatibus
                            </p>
                            <a href="#" class="text--blue">
                                > {{ $t('components._sections.Calendar.link') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 flex--one pb60">
                    <div class="height100 width100 flex box">
                        <div class="col-md-4 pt15 pb15 border--blue-right">
                            <div class="flex flex--middle">
                                <h2 class="pr15">24</h2>
                                <div class="small">
                                    July<br>
                                    2020
                                </div>
                            </div>
                            <p class="text-bold">China Superb Hotel</p>
                            <p>Bejing</p>

                            <p class="small pt15">*celebration</p>
                        </div>
                        <div class="col-md-8 pt30 pb15">
                            <h6 class="pb10">
                                Ipsum dolor sit amet, consectetur adipisicing elit
                            </h6>
                            <p>
                                Cupiditate debitis dicta ducimus est ipsa iure nostrum quas rerum totam voluptatibus
                            </p>
                            <a href="#" class="text--blue">
                                > {{ $t('components._sections.Calendar.link') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-calendar',
};
</script>

<template>
    <router-link :to="{ name: 'author', params: { stub: author.stub, lang: $i18n.locale } }"
                 class="box box--hover block width100 height100 relative">
        <div
                class="quadric bg--image"
                :style="{'background-image': `url(${author.image ? author.image.uri : ''})`}"
        ></div>
        <div class="pl15 pr15 pt10 pb45 relative">
            <h6>{{ author.academictitle }} {{ author.name }} {{ author.surname }}</h6>
            <div class="small mb10" v-html="(author.abstract ? author.abstract.substring(0,150) : '')">
            </div>

        </div>
        <div class="absolute absolute--left absolute--right absolute--bottom">
            <div class="pl15 pr15 pb10">
                <hr style="height: 1.5px" class="mb5">
                <div class="flex flex--middle">
                    <v-icon-award height="18px" width="18px"></v-icon-award>
                    <p class="mb0 ml5">
                        <b>{{ $t('views.Authors.AuthorList.list.courses')}}: </b>{{ author.courses.length }}<br>
                    </p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'author-card',

    props: {
        author: {
            type: Object,
            required: true,
        },
    },
};
</script>

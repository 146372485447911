<template>
<section class="section section--courses pt60 pb60 pt30:sm pb30:sm">
    <div class="container">
        <div class="row flex--center">
            <div class="col-sm-8 col-md-6 col-lg-6">
                <div class="section__title text-center text-center">
                    <h3 class="text-section-title wow slideInUp">
                        {{ $t('components._sections.PopularCourses.title') }}
                    </h3>
                    <h4 class="text-section-subtitle wow slideInUp">
                        {{ $t('components._sections.PopularCourses.subtitle') }}
                    </h4>
                </div>
            </div>
        </div>

        <div class="section__courses">
            <div class="row flex--center">
                <div class="col-md-4" v-for="card in featured" :key="card.id">
                    <div class="course-card">
                        <div class="course-card__thumbnail">
                            <img :src="card.image.uri + '?size=400'" :alt="card.name + 'image'">
                            <!-- <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5f47c0dd68989ed2f2a92d71_waves.png" alt=""> -->
                        </div>
                        <div class="course-card__body">
                            <h3 class="course-card__title">
                                {{ card.name }}
                            </h3>
                            <div class="course-card__content">
                                <p>
                                    {{ card.abstract }}
                                </p>
                            </div>
                        </div>
                        <div class="course-card__footer">
                            {{ card }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container pt60 pb60 pt30:sm pb30:sm">
        <div class="row flex--center">
            <div class="col-md-8">
                <h3 class="text-center text-section-title wow slideInUp">
                    {{ $t('components._sections.PopularCourses.title') }}
                </h3>
                <h4 class="text-center text-section-subtitle wow slideInUp">
                    {{ $t('components._sections.PopularCourses.subtitle') }}
                </h4>
            </div>
        </div>
        <div class="row flex--center pt30">
            <div class="col-md-11">
                <carousel class="owl-dark" :loop="true" v-if="featuredLoaded"
                          :responsive="{0:{nav:false,dots:true,items:1},992:{nav:true,dots:false,items:3}}">
                    <div v-for="(feature, fid) of featured" :key="fid"
                         class="pl15 pr15 pb30">
                       <course-card :course="feature"></course-card>
                    </div>
                </carousel>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import carousel from 'vue-owl-carousel';
import { mapState } from 'vuex';
import CourseCard from '../Courses/CourseCard.vue';

export default {
    name: 'section-courses',

    components: {
        CourseCard,
        carousel,
    },

    data() {
        return {
            featuredLoaded: false,
        };
    },

    computed: {
        ...mapState({
            featured: state => state.courses.featured,
        }),
    },

    beforeCreate() {
        this.$store.dispatch('courses/fetchFeatured')
            .then(() => {
                this.featuredLoaded = true;
            });
    },
};
</script>

<template>
    <section class="s-content-3 pt120 pt60:sm pb90 pb60:sm">
        <div class="container">
            <div class="row flex--center pb60">
                <div class="col-md-6">
                    <h3 class="text-center text-section-title" v-html="$t('components._sections.Content3.title')">
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.Content3.subtitle') }}
                    </h4>
                </div>
            </div>

            <div class="row flex--center">
                <div class="col-md-6 flex--one pb90 pb30:sm">
                    <div class="box quadric bg--image width100" :style="{'background-image': `url(${require('../../assets/images/firstpage_01.jpg')})`}"></div>
                </div>
                <div class="col-md-6 flex--one pb90 pb30:sm">
                    <div class="flex flex--middle width100 height100 pl30 pr30 pl0:sm pr0:sm">
                        <div>
                            <h4 class="mb20">
                                <span class="text--secondary">01</span>
                                {{ $t('components._sections.Content3.content.0.title') }}
                            </h4>
                            <p>
                                {{ $t('components._sections.Content3.content.0.description') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row flex--center flex--row-reverse">
                <div class="col-md-6 flex--one pb90 pb30:sm">
                    <div class="box quadric bg--image width100" :style="{'background-image': `url(${require('../../assets/images/firstpage_02.jpg')})`}"></div>
                </div>
                <div class="col-md-6 flex--one pb90 pb30:sm">
                    <div class="flex flex--middle width100 height100 pl30 pr30 pl0:sm pr0:sm">
                        <div>
                            <h4 class="mb20">
                                <span class="text--secondary">02</span>
                                {{ $t('components._sections.Content3.content.1.title') }}
                            </h4>
                            <p>
                                {{ $t('components._sections.Content3.content.1.description') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row flex--center">
                <div class="col-md-6 flex--one pb90 pb30:sm">
                    <div class="box quadric bg--image width100" :style="{'background-image': `url(${require('../../assets/images/firstpage_03.jpg')})`}"></div>
                </div>
                <div class="col-md-6 flex--one pb90 pb30:sm">
                    <div class="flex flex--middle width100 height100 pl30 pr30 pl0:sm pr0:sm">
                        <div>
                            <h4 class="mb20">
                                <span class="text--secondary">03</span>
                                {{ $t('components._sections.Content3.content.2.title') }}
                            </h4>
                            <p>
                                {{ $t('components._sections.Content3.content.2.description') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex--center pt30">
                <a href="#" class="btn">
                    {{ $t('components._sections.Content3.button') }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'section-content-3',
    };
</script>

<template>
  <div>
    <!-- add class video-player--16-9 -->
    <video ref="videoPlayer" class="video-js video-player--16-9 video-player w-full"  v-if="url"></video>
    <div class="element element--16-9 bg-black" v-else></div>
  </div>
</template>

<script>
import videojs from 'video.js';
// import qualitySelector from 'videojs-hls-quality-selector';
// import contribQualityLevels from 'videojs-contrib-quality-levels';
import axios from 'axios';

export default {
  name: 'VideoPlayer',

  props: {
    options: {
      type: Object,
      default() {
        return {
          autoplay: true,
          controls: true,
          controlBar: {
            children: [
              'playToggle',
              'progressControl',
              'volumePanel',
            //   'qualitySelector',
              'fullscreenToggle',
            ],
          },
        };
      },
    },
    url: {
      type: String,
    },
    poster: {
      type: String,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      player: null,
      isLive: null,
      defaultSrc: {
        type: 'application/x-mpegURL',
        // src: `${process.env.VUE_APP_DEFAULT_STREAM_URL}?token=${auth.getTokenHash()}`,
        label: '360p',
      },
      interval: null,
      controlBar: [
        'playToggle',
        'progressControl',
        'volumePanel',
        // 'qualitySelector',
        'fullscreenToggle',
        'remainingTimeDisplay',
      ],
    };
  },

  mounted() {
    if (this.url == null) {
      return;
    }

    this.interval = setInterval(() => {
      this.checkIfLive();
    }, 2000);

    const options = JSON.parse(JSON.stringify(this.options));
    options.poster = this.poster;
    options.sources = [this.defaultSrc];

    // videojs.registerPlugin('qualityLevels', contribQualityLevels);
    // // videojs.registerPlugin('hlsQualitySelector', qualitySelector);
    this.player = videojs(this.$refs.videoPlayer, options, () => {});

    // this.player.hlsQualitySelector({});
    this.toggleControlBar(false);
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  watch: {
    isLive(isLive) {
      if (isLive !== false && isLive !== true) {
        return;
      }

      if (isLive) {
        this.player.src({
          type: 'application/x-mpegURL',
        //   src: `${this.url}?token=${auth.getTokenHash()}`,
        });
        this.toggleControlBar(true);
      } else {
        this.player.src(this.defaultSrc);
        this.toggleControlBar(false);
      }

      this.player.play();
    },
  },

  methods: {
    checkIfLive() {
    //   axios.get(`${this.url}?token=${auth.getTokenHash()}`).then(() => {
    //     this.isLive = true;
    //   }, () => {
    //     this.isLive = false;
    //   });

        this.isLive = false;
    },

    toggleControlBar(bool) {
      /* eslint no-continue: 0 */
      for (let i = 0; i < this.controlBar.length; i += 1) {
        if (typeof this.player.controlBar[this.controlBar[i]] === 'undefined') {
          continue;
        }

        if (bool) {
          this.player.controlBar[this.controlBar[i]].show();
        } else {
          this.player.controlBar[this.controlBar[i]].hide();
        }
      }
    },
  },
};
</script>

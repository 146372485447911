<template>
    <div>
        <div class="cookie" :class="{'open': open}">
            <img :src="require('../../assets/images/cookie.png')">
            <div style="width: calc(100% - 105px)" class="pl15">
                <p class="mb0">
                    By browsing this site, you agree to our use of Cookies
                </p>
            </div>
            <v-icon-check class="success cursor" height="32px" width="32px" @click="accept(true)"></v-icon-check>
            <v-icon-x class="error cursor" height="32px" width="32px" @click="accept(false)"></v-icon-x>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cookie-popup',
    data() {
        return {
            open: false,
        };
    },
    methods: {
        accept(accepted) {
            this.open = false;
            if (accepted) {
                localStorage.setItem('cookie', true);
                this.$matomo && this.$matomo.rememberConsentGiven();
            } else {
                localStorage.setItem('cookie', false);
            }
        },
    },
    mounted() {
        if (localStorage.getItem('cookie') == null) {
            this.open = true;
        }
    },
};
</script>

<template>
    <section>
        <div class="container pt120 pb120 pt60:sm pb60:sm">
            <div class="row flex--center">
                <div class="col-md-8">
                    <h3 class="text-center text-section-title">
                        {{ $t('components._sections.About1.title')}}
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.About1.subtitle')}}
                    </h4>
                </div>
            </div>

            <div class="row flex--center pt45">
                <div class="col-md-3 pb20">
                    <div class="flex flex--center pb15">
                        <div>
                            <v-icon-elastic height="64px" width="64px"/>
                        </div>
                    </div>
                    <h4 class="text-center pb15">
                        {{ $t('components._sections.About1.boxes.0.title')}}
                    </h4>
                    <p class="text-center">
                        {{ $t('components._sections.About1.boxes.0.description')}}
                    </p>
                </div>
                <div class="col-md-3 pb20">
                    <div class="flex flex--center pb15">
                        <div>
                            <v-icon-elastic height="64px" width="64px"/>
                        </div>
                    </div>
                    <h4 class="text-center pb15">
                        {{ $t('components._sections.About1.boxes.1.title')}}
                    </h4>
                    <p class="text-center">
                        {{ $t('components._sections.About1.boxes.1.description')}}
                    </p>
                </div>
                <div class="col-md-3 pb20">
                    <div class="flex flex--center pb15">
                        <div>
                            <v-icon-elastic height="64px" width="64px"/>
                        </div>
                    </div>
                    <h4 class="text-center pb15">
                        {{ $t('components._sections.About1.boxes.2.title')}}
                    </h4>
                    <p class="text-center">
                        {{ $t('components._sections.About1.boxes.2.description')}}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-about-1',
};
</script>

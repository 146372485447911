import Vue from 'vue';
import Router from 'vue-router';

import Index from './views/Index/Index.vue';
import Courses from './views/Courses/Courses.vue';
import Course from './views/Courses/Course.vue';
import CourseFinish from './views/Courses/Finish.vue';

import Lecture from './views/Lectures/Lecture.vue';
import LectureLive from './views/Lectures/LectureLive.vue';

import Events from './views/Events/Events.vue';
import Event from './views/Events/Event.vue';

import Webinar from './views/Webinars/Webinar.vue';
import Webinars from './views/Webinars/Webinars.vue';

import Authors from './views/Authors/Authors.vue';
import Author from './views/Authors/Author.vue';

import Partners from './views/Partners/Partners.vue';
import Partner from './views/Partners/Partner.vue';

// import Login from './views/Auth/Login.vue';
// import Register from './views/Auth/Register.vue';
import Profile from './views/Auth/Profile.vue';
// import ForgotPassword from './views/Auth/ForgotPassword.vue';
// import ConfirmRegistration from './views/Auth/ConfirmRegistration.vue';
// import PasswordRecovery from './views/Auth/PasswordRecovery.vue';
import AuthToken from './views/Auth/Token.vue';
import Lang from './components/Base/Lang.vue';

import Static from './views/Other/Static.vue';

// import Cart from './views/Shop/Cart.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {

            path: '/:lang',
            component: Lang,
            children: [
                { path: '', name: 'index', component: Index },

                { path: 'courses', name: 'courses', component: Courses },
                { path: 'courses/:stub', name: 'course', component: Course },
                { path: 'courses/:stub/finish', name: 'coursefinish', component: CourseFinish },

                { path: 'lecture/:stub/:type/:tstub', name: 'lecture', component: Lecture },
                { path: 'lecture-live/:stub/:type/:tstub', name: 'lecture-live', component: LectureLive },

                { path: 'events', name: 'events', component: Events },
                { path: 'event/:stub', name: 'event', component: Event },

                { path: 'webinars', name: 'webinars', component: Webinars },
                { path: 'webinars/:id', name: 'webinar', component: Webinar },

                { path: 'authors', name: 'authors', component: Authors },
                { path: 'author/:stub', name: 'author', component: Author },

                { path: 'partners', name: 'partners', component: Partners },
                { path: 'partner/:stub', name: 'partner', component: Partner },

                // { path: 'login', name: 'login', component: Login },
                // { path: 'register', name: 'register', component: Register },
                { path: 'profile', name: 'profile', component: Profile },
                // { path: 'forgot-password', name: 'forgot-password', component: ForgotPassword },
                // { path: 'confirm/:code', name: 'confirmregistration', component: ConfirmRegistration },
                // { path: 'passwordRecovery/:code', name: 'passwordrecovery', component: PasswordRecovery },
                { path: 'auth/token', component: AuthToken },

                // { path: 'cart', name: 'cart', component: Cart },

                { path: 'static/:stub', name: 'static', component: Static },

            ],
        },
        {
            path: '*', component: Lang,
        },
    ],
});

export default {
    one(state, lecture) {
        state.one = lecture;
        if (lecture.video && !lecture.video.slides) {
            state.one.video.slides = [];
        }
    },

    player(state, { field, value }) {
        state.player[field] = value;
    },

    changeQuality(state, q) {
        state.selectedQuality = q;
    },
};

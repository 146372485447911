<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'lang',
    beforeCreate() {
        if (this.$route.params.lang && this.$route.params.lang === this.$route.params.lang.toUpperCase() && this.$route.params.lang.length === 2) {
            if (this.$i18n.locale !== this.$route.params.lang) {
                this.$i18n.locale = this.$route.params.lang;
                localStorage.setItem('lang', this.$route.params.lang);
            }
        } else {
            localStorage.removeItem('lang');
            if (this.$i18n.locale) {
                this.$router.push(`/${this.$i18n.locale}${this.$route.fullPath}`);
                localStorage.setItem('lang', this.$i18n.locale);
            } else {
                this.$router.push(`/${process.env.VUE_APP_PORTAL_LANG}${this.$route.fullPath}`);
                localStorage.setItem('lang', process.env.VUE_APP_PORTAL_LANG);
            }
        }
    },
};
</script>

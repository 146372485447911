<template>
    <div>
        <form @submit.prevent="submit()" novalidate>
            <input type="text" :placeholder="$t('components.Forms.profile.Personal.form.name.placeholder')" class="mb10"
                   required
                   v-model="name"
                   @blur="$v.name.$touch()"
                   autocomplete="name given-name"
                   :class="{'error': $v.name.$dirty && $v.name.$invalid}">
            <p class="errortext" v-if="$v.name.$dirty && !$v.name.required">
                {{$t('components.Forms.profile.Personal.form.name.required')}}</p>
            <input type="email" :placeholder="$t('components.Forms.profile.Personal.form.surname.placeholder')"
                   class="mb10" required
                   v-model="surname"
                   @blur="$v.surname.$touch()"
                   autocomplete="name family-name"
                   :class="{'error': $v.surname.$dirty && $v.surname.$invalid}">
            <p class="errortext" v-if="$v.surname.$dirty && !$v.surname.required">
                {{$t('components.Forms.profile.Personal.form.surname.required')}}</p>
            <div class="flex flex--center">
                <div class="loading-indicator" v-if="sending"></div>
                <button class="btn" :disabled="$v.$invalid" v-else>{{$t('components.Forms.profile.Personal.form.submit')}}
                </button>
            </div>
            <div class="success-box flex flex--middle mt10"  v-if="success">
                <v-icon-checkCircle class="mr10" ></v-icon-checkCircle>
                <div style="width: calc(100% - 50px)">
                {{success}}
                </div>
            </div>
            <div class="error-box flex flex--middle mt10"  v-if="error">
                <v-icon-xCircle class="mr10" ></v-icon-xCircle>
                <div style="width: calc(100% - 50px)">
                {{error}}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'form-profile-personal',

    data() {
        return {
            sending: false,
            name: '',
            surname: '',
            error: '',
            success: '',
        };
    },

    validations: {
        name: {
            required,
        },

        surname: {
            required,
        },
    },

    watch: {
        account(val) {
            if (val) {
                this.name = val.name;
                this.surname = val.surname;
            }
        },
    },

    computed: {
        ...mapState({
            account: state => state.auth.account,
        }),
    },

    methods: {
        submit() {
            const self = this;
            this.success = '';
            this.error = '';
            this.sending = true;
            this.$store.dispatch('auth/userEdit', {
                name: this.name,
                surname: this.surname,
            })
                .then((res) => {
                    self.sending = false;
                    if (res) {
                        this.$v.$reset();
                        this.name = '';
                        this.surname = '';
                        this.success = this.$t('components.Forms.profile.Personal.form.success');
                        this.$store.state.auth.account = {
                            name: res.name,
                            surname: res.surname,
                            email: res.email,
                        };
                    } else {
                        this.error = self.$t('components.Forms.profile.Personal.form.error');
                    }
                })
                .catch((err) => {
                    self.sending = false;
                    self.error = err;
                });
        },
    },

    mounted() {
        if (this.account) {
            this.name = this.account.name;
            this.surname = this.account.surname;
        }
    },
};
</script>

<template>
    <video ref="video" v-bind="$attrs">
        <source v-for="(s, idx) in sources" :key="idx" :src="s" type="application/x-mpegURL">
    </video>
</template>

<script>
import videojs from 'video.js';

export default {
    name: 'v-video',

    props: {
        sources: {
            type: Array,
            default: () => ([]),
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            sourcesWithType: [],
            style: {},
        };
    },

    methods: {
        bindEvent() {
            if (this.videojsObject) {
                const keys = Object.keys(this.$listeners);

                for (let i = 0; i < keys.length; i += 1) {
                    this.videojsObject.on(keys[i], this.$listeners[keys[i]]);
                }
            }
        },
    },

    mounted() {
        const me = this;
        videojs.options.hls.overrideNative = true;
        videojs.options.html5.nativeAudioTracks = false;
        videojs.options.html5.nativeVideoTracks = false;
        videojs.Hls.xhr.beforeRequest = (options) => {
            if (!options.headers) {
                options.headers = {};
            }
            if (this.$store.state.auth.token) {
                options.headers.Authorization = `Bearer ${this.$store.state.auth.token}`;
            }
            options.headers['X-Portal'] = process.env.VUE_APP_PORTAL_ID;
            return options;
        };
        this.videojsObject = videojs(this.$refs.video, this.options, () => {
            me.bindEvent();
        });
    },
};
</script>

<style lang="scss">
    @import '../../assets/video-css/vjs';
</style>

<template>
    <section class="s-about-2" :class="sectionClass">
        <div class="container pt120 pb60 pt60:sm pb60:sm">
            <div class="row flex--center pb60">
                <div class="col-md-6">
                    <h3 class="text-center text-section-title" v-html="$t('components._sections.About2.title')">
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.About2.subtitle') }}
                    </h4>
                </div>
            </div>

            <carousel class="owl-dark s-about-2__carousel" :nav="false" :responsive="{0:{items:1,dots: true},992:{items:3,dots: false}}">
                <div class="s-about-2__carousel-item flex pr10 pl10">
                    <div class="s-about-2__carousel-box">
                        <div class="s-about-2__carousel-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="0 0 42.1 42.1" xml:space="preserve">
                                <path fill="#FFE6E3" d="M31.9,41.7H10.2c-5.2,0-9.4-4.2-9.4-9.4l0-22.4c0-5.2,4.2-9.4,9.4-9.4l21.7,0c5.2,0,9.4,4.2,9.4,9.4v22.4  C41.3,37.4,37.1,41.7,31.9,41.7z"></path>
                                <rect x="11" y="27" transform="matrix(0.9936 -0.1126 0.1126 0.9936 -3.3133 1.7982)" fill="#1F75FE" width="6.5" height="6.5"></rect>
                                <circle fill="#1F75FE" cx="12.1" cy="15.8" r="3.5"></circle>
                                <circle fill="#FFB600" cx="20.8" cy="21.1" r="9.1"></circle>
                                <circle fill="#1F75FE" cx="31" cy="25.8" r="5.6"></circle>
                                <polygon fill="#1F75FE" points="23.6,10.3 26.4,6.6 28.2,10.8 30,15.1 25.4,14.5 20.8,14 "></polygon>
                            </svg>
                        </div>
                        <div>
                            <h4 class="s-about-2__carousel-title">
                                {{ $t('components._sections.About2.carousel.0.title') }}
                            </h4>
                            <p class="s-about-2__carousel-text">
                                {{ $t('components._sections.About2.carousel.0.description') }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="s-about-2__carousel-item flex pr10 pl10">
                    <div class="s-about-2__carousel-box">
                        <div class="s-about-2__carousel-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="0 0 42.1 42.1" xml:space="preserve">
                                <path fill="#E4EFFF" d="M31.9,41.7H10.2c-5.2,0-9.4-4.2-9.4-9.4l0-22.4c0-5.2,4.2-9.4,9.4-9.4l21.7,0c5.2,0,9.4,4.2,9.4,9.4v22.4  C41.3,37.4,37.1,41.7,31.9,41.7z"></path>
                                <path fill="#4C9BF7" d="M30.7,14.3c1,0.9,2.5,0.8,3.3-0.2l2.7-3c0.7,1.9,0.4,4.1-1,5.7c-1.4,1.6-3.6,2.1-5.5,1.6L14.4,35.9  c-0.9,1-2.4,1-3.3,0.2l0,0c-1-0.9-1.1-2.4-0.2-3.3l15.8-17.5c-0.7-1.8-0.4-4,0.9-5.6C29,8,31.2,7.4,33.2,8l-2.7,3  C29.6,11.9,29.7,13.4,30.7,14.3z M12.7,33c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1  C13.8,33.5,13.3,33,12.7,33z"></path>
                                <g id="XMLID_6618_">
                                    <g>
                                        <path fill="#9DCDF4" d="M22.4,25.9l-0.3,0.3L8.9,12.1c-0.3-0.2-0.5-0.3-0.8-0.5l0,0c-0.4-0.1-0.7-0.3-1-0.7C6.4,9.8,5,7.5,5,7.5    l0.2-0.2L22.4,25.9z"></path>
                                    </g>
                                </g>
                                <g id="XMLID_6617_">
                                    <g>
                                        <path fill="#9DCDF4" d="M22.4,25.9l0.3-0.3l-13-14.1c-0.1-0.3-0.4-0.8-0.5-1.1l0,0c-0.1-0.4-0.3-0.8-0.6-1c-1-0.8-3.2-2.3-3.2-2.3    L5.2,7.3L22.4,25.9z"></path>
                                    </g>
                                </g>
                                <g id="XMLID_6615_">
                                    <g>
                                        <path fill="#FFD400" d="M20.7,20.5l11.1,12.2c0.9,1,0.8,2.5-0.2,3.3l0,0l-15-16.5l0,0C17.6,18.7,18.4,18.2,20.7,20.5z"></path>
                                    </g>
                                </g>
                                <g id="XMLID_6614_">
                                    <g>
                                        <path fill="#FFB600" d="M16.6,19.6L16.6,19.6l15,16.4l0,0c-1,0.9-2.5,0.8-3.3-0.2L17.1,23.7C15.1,21.7,15.6,20.5,16.6,19.6z"></path>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div>
                            <h4 class="s-about-2__carousel-title">
                                {{ $t('components._sections.About2.carousel.1.title') }}
                            </h4>
                            <p class="s-about-2__carousel-text">
                                {{ $t('components._sections.About2.carousel.1.description') }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="s-about-2__carousel-item flex pr10 pl10">
                    <div class="s-about-2__carousel-box">
                        <div class="s-about-2__carousel-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="0 0 42.1 42.1" xml:space="preserve">
                                <path fill="#FFF6E0" d="M31.9,41.7H10.2c-5.2,0-9.4-4.2-9.4-9.4l0-22.4c0-5.2,4.2-9.4,9.4-9.4l21.7,0c5.2,0,9.4,4.2,9.4,9.4v22.4  C41.3,37.4,37.1,41.7,31.9,41.7z"></path>
                                <rect x="18.2" y="28" fill="#FFCE61" width="5.6" height="8"></rect>
                                <path fill="#FFCE61" d="M28.9,13.1c-0.3-3.5-5.6-5-8.3-5c-3,0-7.1,1.4-7.5,5c-0.2,2.2-0.3,4.4-0.3,6.5c0,2-0.1,4.2,0.5,6.1  c1,2.9,4.6,3.6,7.2,4.4c0.2,0,0.3,0.1,0.4,0c0.1,0,0.3,0,0.4,0c2.6-0.7,6-1.4,7.1-4.2c0.7-1.8,0.5-4.1,0.6-6  C29.2,17.6,29.1,15.3,28.9,13.1z"></path>
                                <g>
                                    <g>
                                        <path fill="#FFCE61" d="M13.5,18.6C13.5,18.6,13.5,18.6,13.5,18.6L13.5,18.6z"></path>
                                        <path fill="#FFCE61" d="M13.9,19.5c-0.1-0.3-0.2-0.5-0.3-0.7c0,0-0.1-0.1-0.1-0.2c0,0,0,0.1,0.1,0.2c0-0.1-0.1-0.2-0.1-0.3    c-0.2-0.3-0.3-0.3,0,0c-0.2-0.3-0.6-0.6-1-0.6c0,0-0.1,0-0.1,0c-0.6-0.2-1.2,0.1-1.6,0.6c-0.9,1-0.6,2.6,0.1,3.6    c0.3,0.4,0.9,0.7,1.5,0.7c0.7,0,1.3-0.5,1.6-1.1C14.1,20.8,14.2,20.1,13.9,19.5z"></path>
                                    </g>
                                    <g>
                                        <path fill="#FFCE61" d="M28.6,18.6C28.6,18.6,28.6,18.6,28.6,18.6L28.6,18.6z"></path>
                                        <path fill="#FFCE61" d="M28.3,21.5c0.3,0.6,0.9,1.1,1.6,1.1c0.5,0,1.1-0.2,1.5-0.7c0.8-1,1-2.5,0.1-3.6c-0.4-0.5-1-0.7-1.6-0.6    c0,0-0.1,0-0.1,0c-0.4,0-0.8,0.3-1,0.6c0.2-0.3,0.2-0.3,0,0c-0.1,0.1-0.1,0.2-0.1,0.3c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0.1-0.1,0.2    c-0.1,0.2-0.2,0.5-0.3,0.7C27.9,20.1,27.9,20.8,28.3,21.5z"></path>
                                    </g>
                                </g>
                                <polygon fill="#E84C3D" points="26,33.6 23.9,31.3 21.1,32.6 21,32.6 21,32.6 18.2,31.3 16.1,33.6 18,38.6 21,36.1 24.1,38.6 "></polygon>
                                <path fill="#1F75FE" d="M30.7,8.3C29.8,8,28.6,8.2,27.9,8c-0.7-0.2-1.1-1.4-1.5-2c-2.2-3.9-8.5-1.8-10.2,1.7  c-0.3,0.3-0.5,0.7-0.7,1.1c-1.6-0.2-3.1,1.9-3.6,3.4c-0.8,2.5-0.2,5.5,1.2,7.7c0.3,0.5,1.2,0.2,0.9-0.4c-0.6-1.6-0.7-4-0.2-5.7  c0.2-0.6,0.5-1.1,0.8-1.7c0.3-0.5,0.3-0.5,0.5-0.3c0.1,0.4,0.2,0.7,0.4,1.1c1.2,2.4,4.4,2.5,6.6,1.7c1.2-0.4,1.9-0.6,2.8,0.1  c0.9,0.7,1.6,1.4,2.7,1.8c0.4,0.2,0.8,0.2,1.3,0.1c0,1-0.2,2-0.5,2.8c-0.2,0.6,0.6,0.9,0.9,0.4c0.9-1.4,1.5-3.3,1.5-5.1  c0.2-0.6,0.4-1.2,0.7-1.6c0.7-0.8,1.2-1.5,1.3-2.6C32.9,9.4,31.7,8.6,30.7,8.3z"></path>
                            </svg>
                        </div>
                        <div>
                            <h4 class="s-about-2__carousel-title">
                                {{ $t('components._sections.About2.carousel.2.title') }}
                            </h4>
                            <p class="s-about-2__carousel-text">
                                {{ $t('components._sections.About2.carousel.2.description') }}
                            </p>
                        </div>
                    </div>
                </div>
            </carousel>
        </div>

    </section>
</template>

<script>
    import carousel from 'vue-owl-carousel';

    export default {
        name: 'section-about-2',

        components: {
            carousel,
        },

        props: {
            sectionClass: {
                type: String,
                default: null,
            }
        }
    };
</script>

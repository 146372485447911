<template>
    <section class="bg--webinar-section">
        <div class="container pt150 pb60 pt40:sm pb30:sm">
            <div class="row">
                <div class="col-md-6 mb15">
                    <div class="quadric bg--image box pb20 pl30 pr30 mb30"  :style="{'background-image': 'url('+ require('../../assets/images/bg_cover_01.jpg') +')'}"></div>
                </div>
                <div class="col-md-5 mr45 pt10">

                    <h6 class="small text--blue-medium text-thin">
                        {{ $t('components._sections.Webinar.subtitle') }}
                    </h6>
                    <h3 class="mb15 pt10">
                        {{$t('components._sections.Webinar.title')}}
                    </h3>
                    <span class=" pl5 pr5 pt5 pb5" style="background: rgb(179, 230, 187); border-radius: 5px;">
                        {{$t('components._sections.Webinar.date')}}
                    </span>
                    <p class="mt10 pt10 pb20">
                        {{$t('components._sections.Webinar.content')}}
                    </p>
                    <div>
                        <a href="#" class="btn">
                            {{$t('components._sections.Webinar.button')}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-webinar',
};
</script>

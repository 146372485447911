<template>
    <div>
        <section class="bg--image ph" v-if="header===0"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover_small.jpg')})`}">
            <div class="container pt150 pt45:sm pb150 pb45:sm">
                <div class="row flex--center flex--middle text--white">
                    <div class="col-md-8 pb20">
                        <h1>{{$t('views.Courses.Courses.title')}}</h1>
                        <h4 class="text-thin">{{$t('views.Courses.Courses.subtitle')}}</h4>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg--image ph" v-else-if="header===1" :style="{
            'background-image': 'linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.1)), url('+ require('../../assets/images/bg_cover_courses.jpg') +')'}"
        >
            <div class="container pt10 pt30:sm pb60 pb30:sm">
                <div class="row pt60 flex--middle pt0:sm">
                    <div class="col-md-6 pb20:sm">
                        <div class="video__iframe mb15">
                            <template>
                                <iframe
                                        id="ytplayer"
                                        type="text/html"
                                        width="640"
                                        height="360"
                                        frameborder="0"
                                        allowfullscreen
                                        src="https://www.youtube.com/embed/Oke-E4nC3AE"
                                ></iframe>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-6 pl40 pl15:sm">
                        <h4 class="text--white text-section-subtitle ">{{$t('views.Courses.Courses.content.subtitle')}}</h4>
                        <h3 class="text--white text-section-title">{{$t('views.Courses.Courses.content.title')}}</h3>
                        <div class="flex pt20">
                            <a href="#" class="btn mr15 mr10:sm btn--round pl30 pr30 mb10">
                                <div class="flex flex--middle flex--center">
                                    <div style="height: 20px;" class="mr5">
                                        <v-icon-android></v-icon-android>
                                    </div>
                                    <div>Android</div>
                                </div>
                            </a>
                            <a href="#" class="btn btn--round pl30 pr30 mb10">
                                <div class="flex flex--middle flex--center">
                                    <div style="height: 20px;" class="mr5">
                                        <v-icon-ios></v-icon-ios>
                                    </div>
                                    <div>iOS</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="bg--image bg--primary ph" v-else-if="header===2">
            <!-- :style="{
            'background-image': 'linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.1)), url('+ require('../../assets/images/bg_cover_courses.jpg') +')',
            }" -->

            <div class="container pt120 pt60:sm pb120 pb60:sm">
                <!-- <div class="row flex--center flex--middle">
                    <div class="col-md-8">
                        <h2 class="text--white text-center pb20">{{$t('views.Courses.Courses.title')}}</h2>
                    </div>
                </div> -->
                <div class="pl30 pr30 pl0:sm pr0:sm">
                    <carousel :loop="true" v-if="featuredLoaded"
                              :responsive="{0:{nav:false,dots:true,items:1},992:{nav:true,dots:false,items:1}}">
                        <div v-for="(feature, fid) of featured" :key="fid"
                             class="flex--one pl15 pr15 pl5:sm pr5:sm width100">
                            <div class="row flex--row-reverse flex--middle width100">
                                <div class="col-md-6 mb30:sm">
                                    <div class="quadric bg--image box pl10 pr10 pt10 pb10"
                                         :style="{'background-image': 'url(' + (feature.image ? feature.image.uri : '') + ')'}"></div>
                                </div>
                                <div class="col-md-6">
                                    <h4 class="text--white mb15">{{feature.name}}</h4>
                                    <h6 class="text--white mb15">
                                        {{ feature.authors.map((author)=> (`${author.name}
                                        ${author.surname}`)).join(", ") }}
                                    </h6>
                                    <p class="text--white mb30">
                                        {{feature.abstract}}
                                    </p>
                                    <!-- <div class="flex mb10 flex--middle text--white">
                                        <v-icon-film height="18px" width="18px" class="mr5"></v-icon-film>
                                        <b>{{$t('views.Courses.Courses.list.lectures')}}:</b>&nbsp;&nbsp;{{feature.lectures.length}}
                                    </div>
                                    <div class="flex mb10 flex--middle text--white" v-if="feature.duration">
                                        <v-icon-clock height="18px" width="18px" class="mr5"></v-icon-clock>
                                        <b>{{ feature.duration | duration }}</b>
                                    </div> -->
                                    <router-link class="btn" :to="{ name: 'course', params: {  stub: feature.stub, lang: $i18n.locale } }">
                                        {{$t('views.Courses.Courses.list.apply')}}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </carousel>
                </div>

            </div>
        </section>


        <!-- <section>
            <div class="container pt40 pb20 pt20:sm pb0:sm">
                <div class="row flex--right">
                    <div class="col-md-4 pl0:sm pr0:sm">
                        <form class="flex flex--between"
                             style="border: solid 1px #1f2432; width: 100%;" @submit.prevent="searchQuery()">
                            <input type="text" :placeholder="$t('views.Courses.Courses.search')" class="pl20"
                                   style="border: 0; width: calc(100% - 55px);" v-model="search">
                            <button class="btn btn--icon" style="min-width: inherit">
                                <v-icon-search></v-icon-search>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="bg--bg">
            <div class="container pb120 pb60:sm">
                <div class="row">
                    <!-- Categories -->
                    <div class="col-md-12">
                        <div class="c-categories">
                            <div class="c-categories__item"
                                 :class="{ 'is-active': category === null }"
                                 @click="category = null">
                                <div class="c-categories__icon">
                                    <img :src="categories.uri" alt="" v-if="categories.uri">

                                    <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="0 0 42.1 42.1" xml:space="preserve">
                                        <path fill="#1F75FE" d="M40,9.9h-6.4c-0.3,0-0.6,0.2-0.7,0.6l-1.6,6.2H6.2c-1,0-1.7,0.9-1.5,1.9l3.2,12.6c0.2,0.7,0.8,1.1,1.5,1.1  h19.6l3.9-15.6h0l1.3-5.3H40c0.4,0,0.8-0.3,0.8-0.8C40.8,10.2,40.5,9.9,40,9.9z"></path>
                                        <circle fill="#1F75FE" cx="13.3" cy="36.6" r="3.2"></circle>
                                        <circle fill="#1F75FE" cx="23.9" cy="36.6" r="3.2"></circle>
                                        <g>
                                            <path fill="#FFFFFF" d="M28,19.7H9.1c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8H28c0.4,0,0.8-0.3,0.8-0.8   C28.8,20,28.4,19.7,28,19.7z"></path>
                                            <path fill="#FFFFFF" d="M26,27.8H11.1c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8H26c0.4,0,0.8-0.3,0.8-0.8S26.4,27.8,26,27.8z"></path>
                                            <path fill="#FFFFFF" d="M26.9,23.7H10.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h16.6c0.4,0,0.8-0.3,0.8-0.8   C27.6,24.1,27.3,23.7,26.9,23.7z"></path>
                                        </g>
                                        <g>
                                            <g>
                                                <polygon fill="#FFB600" points="17.4,15.3 8.2,2.2 13.6,20.6 16.9,16   "></polygon>
                                            </g>
                                            <g>
                                                <polygon fill="#FFB600" points="15,12.2 24.3,25.2 18.9,6.8 15.5,11.5   "></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 class="c-categories__title">
                                    {{$t('views.Courses.Courses.categories')}}
                                </h5>
                            </div>

                            <div class="c-categories__item"
                                 :class="{ 'is-active': category === cat.id }"
                                 v-for="cat of categories"
                                 @click="category = cat.id">
                                <div class="c-categories__icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="0 0 42.1 42.1" xml:space="preserve">
                                        <path fill="#1F75FE" d="M40,9.9h-6.4c-0.3,0-0.6,0.2-0.7,0.6l-1.6,6.2H6.2c-1,0-1.7,0.9-1.5,1.9l3.2,12.6c0.2,0.7,0.8,1.1,1.5,1.1  h19.6l3.9-15.6h0l1.3-5.3H40c0.4,0,0.8-0.3,0.8-0.8C40.8,10.2,40.5,9.9,40,9.9z"></path>
                                        <circle fill="#1F75FE" cx="13.3" cy="36.6" r="3.2"></circle>
                                        <circle fill="#1F75FE" cx="23.9" cy="36.6" r="3.2"></circle>
                                        <g>
                                            <path fill="#FFFFFF" d="M28,19.7H9.1c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8H28c0.4,0,0.8-0.3,0.8-0.8   C28.8,20,28.4,19.7,28,19.7z"></path>
                                            <path fill="#FFFFFF" d="M26,27.8H11.1c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8H26c0.4,0,0.8-0.3,0.8-0.8S26.4,27.8,26,27.8z"></path>
                                            <path fill="#FFFFFF" d="M26.9,23.7H10.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h16.6c0.4,0,0.8-0.3,0.8-0.8   C27.6,24.1,27.3,23.7,26.9,23.7z"></path>
                                        </g>
                                        <g>
                                            <g>
                                                <polygon fill="#FFB600" points="17.4,15.3 8.2,2.2 13.6,20.6 16.9,16   "></polygon>
                                            </g>
                                            <g>
                                                <polygon fill="#FFB600" points="15,12.2 24.3,25.2 18.9,6.8 15.5,11.5   "></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 class="c-categories__title">
                                    {{cat.name}}
                                    <!-- ({{cat.count}}) -->
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="flex flex--center pt20 pb20" v-if="loading">
                            <div class="loading-indicator"></div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-4 col-sm-6 pb30 flex--one" v-for="(course, ci) in courses" :key="ci">
                                <course-card :course="course" :grid="true" :featured="true" :disabled="course.notAvailable" :disabledText="$t('components.Courses.CourseCard.waitlist')" @disabledClick="displayPopup(course)"></course-card>
                            </div>
                        </div>

                        <div class="flex flex--center pb20 mt30" v-if="totalPages > 1">
                            <button class="btn--round btn--icon" style="min-width: inherit;" @click="page-=1" v-if="page > 1">
                                <v-icon-chevronLeft height="20px" width="20px"></v-icon-chevronLeft>
                            </button>

                            <div style="height: 50px; width: 50px;" v-else></div>

                            <div class="flex flex--middle flex--center pl15 pr15" style="height: 50px">
                                <h4>{{ page }}/{{totalPages}}</h4>
                            </div>

                            <button class="btn--round btn--icon" style="min-width: inherit;" @click="page+=1" v-if="page<totalPages">
                                <v-icon-chevronRight height="20px" width="20px"></v-icon-chevronRight>
                            </button>
                            <div style="height: 50px; width: 50px;" v-else></div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section-about-2 sectionClass="bg--cool-gray-light" />

        <section-customers />

        <section-faq-4 />

        <!-- <section-instructors1 version="half-half" /> -->

        <section-newsletter />

        <waitlist-popup></waitlist-popup>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import { mapState } from 'vuex';
import videojs from 'video.js';

import CourseCard from '../../components/Courses/CourseCard.vue';
import SectionAbout2 from '../../components/_sections/About2.vue';
import SectionInstructors1 from '../../components/_sections/Instructors1.vue';
import SectionCustomers3 from '../../components/_sections/Customers.vue';
import SectionFaq4 from '../../components/_sections/Faq4.vue';
import SectionNewsletter from '../../components/_sections/Newsletter.vue';
import WaitListPopup from '../../components/Courses/WaitlistPopup.vue';

export default {
    components: {
        SectionNewsletter,
        'course-card': CourseCard,
        carousel,
        SectionAbout2,
        SectionInstructors1,
        SectionCustomers3,
        SectionFaq4,
        SectionNewsletter,
        'waitlist-popup': WaitListPopup
    },

    data() {
        return {
            header: 2,
            featuredLoaded: false,
            loading: true,
        };
    },

    watch: {
        featured() {
            this.featuredLoaded = true;
        },

        '$i18n.locale'() {
            document.title = `${this.portalName} - ${this.$t('views.Courses.Courses.title')}`;
            this.loading = true;
            this.$store.dispatch('courses/fetch').then(() => {
                this.loading = false;
            });
            this.$store.dispatch('courses/fetchFeatured');
            this.$store.dispatch('categories/fetch');
        },

        portalName(portalName) {
            document.title = `${portalName} - ${this.$t('views.Courses.Courses.title')}`;
        },
    },

    computed: {
        ...mapState({
            courses: state => state.courses.list.data,
            coursesAll: state => state.courses.all,
            categories: state => state.categories.categories,
            featured: state => state.courses.featured,
            limit: state => state.courses.list.limit,
            total: state => state.courses.list.total,
            portalName: state => state.other.portalName,
        }),

        filteredCategories() {
            const catObject = {};
            this.coursesAll.map(el => el.categories).flat().forEach((cat) => {
                if (cat.id in catObject) {
                    catObject[cat.id].count += 1;
                } else {
                    catObject[cat.id] = cat;
                    catObject[cat.id].count = 1;
                }
            });
            return Object.values(catObject);
        },

        page: {
            get() {
                return this.$store.state.courses.list.page;
            },
            set(page) {
                this.$store.dispatch('courses/changePagination', page);
                this.loading = true;
                this.$store.dispatch('courses/fetch').then(() => {
                    this.loading = false;
                });
            },
        },

        search: {
            get() {
                return this.$store.state.courses.list.search;
            },
            set(search) {
                this.$store.dispatch('courses/changeSearch', search);
            },
        },

        category: {
            get() {
                return this.$store.state.courses.list.category;
            },
            set(slug) {
                this.$store.dispatch('courses/changeCategory', slug);
                this.loading = true;
                this.$store.dispatch('courses/fetch').then(() => {
                    this.loading = false;
                });
            },
        },

        totalPages() {
            return Math.ceil(this.total / this.limit);
        },
    },

    methods: {
        searchQuery() {
            this.page = 1;
            this.loading = true;
            this.$store.dispatch('courses/fetch').then(() => {
                this.loading = false;
            });
        },

        displayPopup(course) {
            this.$store.commit('courses/waitlist', { field: 'success', value: false });
            this.$store.commit('courses/waitlist', { field: 'course', value: course });
            this.$store.commit('courses/waitlist', { field: 'popup', value: true });
        },
    },

    beforeMount() {
        document.title = `${this.portalName} - ${this.$t('views.Courses.Courses.title')}`;
        this.$store.dispatch('courses/fetch').then(() => {
            this.loading = false;
        });
        this.$store.dispatch('courses/fetchFeatured');
        this.$store.dispatch('categories/fetch');
    },

    mounted() {
        if (document.getElementById('normal-video')) {
            videojs('normal-video');
        }
    },
};
</script>

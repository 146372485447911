<template>
    <section>
        <div class="container pt90">
            <div class="row flex--center">
                <div class="col-md-8">
                    <h3 class="pb10 text-center text-section-title">
                        {{ $t('components._sections.Customers3.title') }}
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.Customers3.subtitle') }}
                    </h4>
                </div>
            </div>

            <div class="row pt40">
                <div class="col-md-3">
                    <customer-card
                        :text="$t('components._sections.Customers3.testimonials.0.text')"
                        :image="require('../../assets/images/customers/gregor-rupnik.jpg')"
                        :name="$t('components._sections.Customers3.testimonials.0.name')"
                        :country="$t('components._sections.Customers3.testimonials.0.country')">
                    </customer-card>

                </div>

                <div class="col-md-3">
                    <customer-card
                        :text="$t('components._sections.Customers3.testimonials.1.text')"
                        :image="require('../../assets/images/customers/maja-mileusnic-grahovic.jpg')"
                        :name="$t('components._sections.Customers3.testimonials.1.name')"
                        :country="$t('components._sections.Customers3.testimonials.1.country')">
                    </customer-card>

                </div>

                <div class="col-md-3">
                    <customer-card
                        :text="$t('components._sections.Customers3.testimonials.2.text')"
                        :image="require('../../assets/images/customers/ines-suholeznik.jpg')"
                        :name="$t('components._sections.Customers3.testimonials.2.name')"
                        :country="$t('components._sections.Customers3.testimonials.2.country')">
                    </customer-card>


                </div>

                <div class="col-md-3">
                    <customer-card
                        :text="$t('components._sections.Customers3.testimonials.3.text')"
                        :image="require('../../assets/images/customers/luka-penger.jpg')"
                        :name="$t('components._sections.Customers3.testimonials.3.name')"
                        :country="$t('components._sections.Customers3.testimonials.3.country')">
                    </customer-card>


                </div>
            </div>
        </div>
    </section>
</template>

<script>
import CustomerCard from '../Customers/CustomersCard';

export default {
    name: 'section-customers',

    components: {
        CustomerCard
    }
};
</script>

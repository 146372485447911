export function passwordStrength(pass) {
    let score = 0;
    if (!pass) return score;

    const letters = {};
    for (let i = 0; i < pass.length; i += 1) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    const variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    const variationsKeys = Object.keys(variations);
    for (let i = 0; i < variationsKeys; i += 1) {
        variationCount += variations[variationsKeys[i]] ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return parseInt(score, 10);
}

export function sampleValidator() {
    return true;
}

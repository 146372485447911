<template>
    <div>
        <section
                class="bg--image ph"
                :style="{
                'background-image': `linear-gradient(rgba(0,0,0,.65),rgba(0,0,0,.65)), url(${event.image && event.image.uri})`
            }">
            <div class="container pt60 pt45:sm pb90 pb30:sm">
                <div class="row flex--center flex--middle">
                    <div class="col-md-8">
                        <vue-content-loading v-if="loading" :width="100" :height="8">
                            <rect x="0" y="0" width="100" height="8"/>
                        </vue-content-loading>
                        <h2 class="text--white text-center" v-else>{{ event.name }}</h2>
                        <vue-content-loading v-if="loading" :width="100" :height="4" class="mt10 mb10">
                            <rect x="0" y="0" width="100" height="4"/>
                        </vue-content-loading>
                        <p class="h5 text-normal text--white text-center pt10" v-else>
                            {{ event.city }} / {{ event.country }}
                        </p>
                        <vue-content-loading v-if="loading" :width="100" :height="4">
                            <rect x="0" y="0" width="100" height="4"/>
                        </vue-content-loading>
                        <div class="flex flex--middle flex--center text--white" v-else>
                            <v-icon-calendar class="mr5" height="24px" width="24px"></v-icon-calendar>
                            <p class="mb0 h5" style="margin-top: 2px">
                                {{ event.dateFrom | date }} -
                                {{ event.dateTo | date }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container pt30 pb60 pt20:sm pb20:sm">
                <div class="row flex--center">
                    <div class="col-md-8">
                        <div class="pr10 box pl15 pr15 pt15 pb1" style="width: 100%;">

                        <vue-content-loading v-if="loading" :width="100" :height="33">
                            <rect x="0" y="0" width="100" height="33"/>
                        </vue-content-loading>
                        <template v-else>
                            <div class="pt20 pb20" v-html="event.description"></div>
                        </template>

                    </div>
                        <div class="relative">
                            <div class="absolute absolute--top absolute--right pt10"
                                 v-if="tabs===$t('views.Events.Event.tabs.curiculum')">
                                <div class="flex flex--right hide:sm">
                                    <div @click="grid=true" class="cursor">
                                        <v-icon-grid class="mr10" :class="{'text--blue': grid}"></v-icon-grid>
                                    </div>
                                    <div @click="grid=false" class="cursor">
                                        <v-icon-list :class="{'text--blue': !grid}"></v-icon-list>
                                    </div>
                                </div>
                            </div>
                            <v-tabs v-model="tabs">
                                <v-tab :name="$t('views.Events.Event.tabs.curiculum')" :selected="tabs">
                                    <div class="row" v-if="grid">
                                        <template v-if="loading.one">
                                            <div class="col-md-4 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-4 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-4 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-md-4 mb20 flex--one" v-for="(lecture, li) in event.lectures" :key="li">
                                                <lecture-card :lecture="lecture" :grid="grid" type="events"
                                                              :tstub="$route.params.stub"></lecture-card>
                                            </div>
                                        </template>

                                    </div>
                                    <div class="row" v-else>
                                        <template v-if="loading.one">
                                            <div class="col-md-12 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-12 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-12 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-md-12 mb20" v-for="(lecture, li) in event.lectures" :key="li">
                                                <lecture-card :lecture="lecture" :grid="grid" type="events"
                                                              :tstub="$route.params.stub"></lecture-card>
                                            </div>
                                        </template>
                                    </div>
                                </v-tab>
                                <v-tab :name="$t('views.Events.Event.tabs.authors')" :selected="tabs">
                                    <div class="row">
                                        <template v-if="loading.one">
                                            <div class="col-md-4 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-4 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-4 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-md-4 pb30" v-for="(author, aid) in event.authors" :key="aid">
                                                <author-card :author="author"></author-card>
                                            </div>
                                        </template>
                                    </div>
                                </v-tab>
                            </v-tabs>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="box pl15 pr15 pt20 pb15 mb20">
                            <template v-if="!enrolled">
                                <h3 class="text-center pb10">
                                    {{$t('views.Events.Event.free')}}
                                    <span class="h5" style="text-decoration: line-through;color: #a4a4a4">0€</span>
                                </h3>
                                <button @click="enroll" class="btn btn--full btn--outline">
                                    {{$t('views.Courses.Course.apply')}}
                                </button>
                            </template>
                            <template v-else>
                                <h4 class="text-center pb10">
                                    {{$t('views.Events.Event.applied')}}
                                </h4>
                                <p class="text-center">{{$t('views.Events.Event.enroll_success_date')}}{{enrolledEvent && enrolledEvent.created | date}}</p>
                            </template>
                            <!--<button @click="buyNow()" class="btn btn--full mt10"
                                    v-if="!enrolled && !(course.free || !course.priceCourse)">
                                {{$t('views.Events.Event.buyNow')}}
                            </button>-->
                            <p class="text-center errortext">{{enrollError}}</p>
                            <p>{{enrollSuccess}}</p>
                        </div>
                        <div class="box pl15 pr15 pt15 pb15 mb20">
                            <social-sharing url="https://vuejs.org/" inline-template>
                                <div class="flex">
                                    <network network="facebook">
                                        <div class="mr5 ml5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                                        </div>
                                    </network>
                                    <network network="linkedin">
                                        <div class="mr5 ml5">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                                        </div>
                                    </network>
                                    <network network="twitter">
                                        <div class="mr5 ml5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                                        </div>
                                    </network>
                                </div>
                            </social-sharing>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <v-modal :open="popup" @close="popup=false">
            <template v-if="!enrolled">
                <h3 class="text-center pb10">
                    {{$t('views.Events.Event.free')}}
                    <span class="h5" style="text-decoration: line-through;color: #a4a4a4">0€</span>
                </h3>
                <button @click="enroll" class="btn btn--full btn--outline">
                    {{$t('views.Courses.Course.apply')}}
                </button>
            </template>
            <template v-else>
                <h4 class="text-center pb10">
                    {{$t('views.Events.Event.applied')}}
                </h4>
                <p class="text-center">{{$t('views.Events.Event.enroll_success_date')}}{{enrolledEvent && enrolledEvent.created | date}}</p>
            </template>
            <!--<button @click="buyNow()" class="btn btn--full mt10"
                    v-if="!enrolled && !(course.free || !course.priceCourse)">
                {{$t('views.Events.Event.buyNow')}}
            </button>-->
            <p class="text-center errortext">{{enrollError}}</p>
            <p>{{enrollSuccess}}</p>
        </v-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VueContentLoading from 'vue-content-loading';
import lectureCard from '../../components/Lectures/LectureCard.vue';
import AuthorCard from '../../components/Authors/AuthorCard.vue';

export default {
    components: {
        AuthorCard,
        lectureCard,
        VueContentLoading,
    },

    data() {
        return {
            grid: true,
            tabs: this.$t('views.Events.Event.tabs.curiculum'),
            loading: true,
            enrollError: '',
            enrollSuccess: '',
        };
    },

    watch: {
        '$i18n.locale': {
            handler() {
                this.loading = true;
                this.tabs = this.$t('views.Events.Event.tabs.curiculum');
                this.$store.dispatch('events/fetchOne', this.$route.params.stub)
                    .then((val) => {
                        document.title = `${this.portalName} - ${val.name}`;
                        this.loading = false;
                    });
            },
        },

        portalName: {
            handler(portalName) {
                if (this.event && this.event.name) {
                    document.title = `${portalName} - ${val.name}`;
                } else {
                    document.title = `${portalName}`;
                }
            },
        },

        tabs: {
            handler(tab){
                this.$matomo && this.$matomo.trackEvent('event', 'tab change');
            }
        }
    },

    computed: {
        ...mapState({
            event: state => state.events.one,
            portalName: state => state.other.portalName,
            auth: state => state.auth.token !== null,
            enrolledEvent(state) {
                return state.auth.enrolledEvents && state.auth.enrolledEvents.find(el => el.event.stub === this.$route.params.stub);
            },
        }),
        popup: {
            get() {
                return this.$store.state.events.enrollPopup;
            },
            set(value) {
                this.$store.dispatch('events/enrollPopup', value);
            },
        },
        enrolled() {
            return !!this.enrolledEvent;
        },
    },

    methods: {
        enroll() {
            const self = this;
            if (this.auth) {
                if (!this.enrolled) {
                    this.$store.dispatch('events/eventEnroll', this.event.id)
                        .then(() => {
                            self.$store.dispatch('auth/tokenUser');
                            self.enrollSuccess = self.$t('views.Events.Event.enroll_success');
                            this.$matomo && this.$matomo.trackEvent('event', 'enrolled');
                        })
                        .catch((err) => {
                            console.log(err);
                            self.enrollError = err;
                            this.$matomo && this.$matomo.trackEvent('event', 'enroll failed');
                        });
                }
            } else {
                window.location = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
                // this.$store.dispatch('other/popup', true);
            }
        },
    },

    beforeMount() {
        this.$matomo && this.$matomo.trackPageView();
        this.$store.dispatch('events/fetchOne', this.$route.params.stub)
            .then((val) => {
                document.title = `${this.portalName} - ${val.name}`;
                this.loading = false;
            });
    },
};
</script>

<template>
    <div>
        <section class="bg--image ph"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover.jpg')})`}">
            <div class="container pt90 pt45:sm pb60 pb20:sm">
                <div class="row flex--middle">
                    <div class="col-md-3">
                        <vue-content-loading v-if="loading" :width="100" :height="100">
                            <rect x="0" y="0" width="100" height="100"/>
                        </vue-content-loading>
                        <div class="bg--image quadric--full" v-else
                             :style="{'background-image': 'url(' + (author.image ? author.image.uri : '') + ')'}"></div>
                    </div>
                    <div class="col-md-8 pt10:sm">
                        <vue-content-loading v-if="loading" :width="100" :height="6">
                            <rect x="0" y="0" width="100" height="6"/>
                        </vue-content-loading>
                        <h3 class="text--white" v-else>{{author.academicTitle}} {{author.name}} {{author.surname}}</h3>
                        <vue-content-loading v-if="loading" :width="100" :height="15" class="mt15">
                            <rect x="0" y="0" width="100" height="15"/>
                        </vue-content-loading>
                        <div class="pt15" v-else>
                            <div class="pt10 pb10 text--white" v-html="author.abstract"></div>
                            <p class="text--white">
                                {{ $t('views.Authors.Author.courses')}}: {{ author.courses ? author.courses.length : 0
                                }}<br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container pt60 pb60 pt20:sm pb20:sm">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-8">
                        <vue-content-loading v-if="loading" :width="100" :height="25" class="mt15">
                            <rect x="0" y="0" width="100" height="25"/>
                        </vue-content-loading>
                        <div v-else v-html="author.description" class="text-left"></div>
                    </div>
                    <div class="col-md-12">
                        <div class="relative">
                            <div class="absolute absolute--top absolute--right pt10"
                                 v-if="tabs===$t('views.Authors.Author.tabs.courses')">
                                <div class="flex flex--right hide:sm">
                                    <div @click="grid=true" class="cursor">
                                        <v-icon-grid class="mr10" :class="{'text--blue': grid}">

                                        </v-icon-grid>
                                    </div>
                                    <div @click="grid=false" class="cursor">
                                        <v-icon-list :class="{'text--blue': !grid}"></v-icon-list>
                                    </div>
                                </div>
                            </div>
                            <v-tabs v-model="tabs">
                                <v-tab :name="$t('views.Authors.Author.tabs.courses')" :selected="tabs">
                                    <div class="row">

                                        <template v-if="!grid">
                                            <template v-for="(course, ci) in author.courses">
                                                <course-card :course="course" :grid="grid"></course-card>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-for="(course, ci) in author.courses">
                                                <div class="col-md-3 pb30 flex--one" :key="ci">
                                                    <course-card :course="course" :grid="grid"></course-card>
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </v-tab>
                            </v-tabs>
                        </div>

                    </div>


                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    import VueContentLoading from 'vue-content-loading';
    import CourseCard from '../../components/Courses/CourseCard.vue';

    export default {
        components: {
            'course-card': CourseCard,
            VueContentLoading,
        },

        data() {
            return {
                grid: true,
                tabs: this.$t('views.Authors.Author.tabs.courses'),
                loading: true,
            };
        },

        watch: {
            '$i18n.locale': {
                handler() {
                    this.loading = true;
                    this.tabs = this.$t('views.Authors.Author.tabs.courses');
                    this.$store.dispatch('authors/fetchOne', this.$route.params.stub)
                        .then((val) => {
                            document.title = `${this.portalName} - ${val.name}`;
                            this.loading = false;
                        });
                },
            },

            portalName: {
                handler(portalName) {
                    if (this.author && this.author.name) {
                        document.title = `${portalName} - ${this.author.name}`;
                    } else {
                        document.title = `${portalName}`;
                    }
                },
            },
        },

        computed: {
            ...mapState({
                author: state => state.authors.one,
                portalName: state => state.other.portalName,
            }),
        },

        beforeMount() {
            this.$matomo && this.$matomo.trackPageView();
            document.title = `${this.portalName}`;
            this.$store.dispatch('authors/fetchOne', this.$route.params.stub)
                .then((val) => {
                    document.title = `${this.portalName} - ${val.name}`;
                    this.loading = false;
                });
        },
    };
</script>

import Vue from 'vue';
import Vuex from 'vuex';

import courses from './courses';
import authors from './authors';
import events from './events';
import lectures from './lectures';
import other from './other';
import auth from './auth';
import shop from './shop';
import categories from './categories';
import webinars from './webinars';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        courses,
        authors,
        events,
        lectures,
        other,
        auth,
        shop,
        categories,
        webinars,
    },
});

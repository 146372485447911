<template>
    <section class="bg--bg pt120 pb60 pt60:sm pb60:sm">
        <div class="container">
            <div class="row flex--center pb60">
                <div class="col-md-6">
                    <h3 class="text-center text-section-title wow slideInUp" v-html="$t('components._sections.FeatureVideos.title')"></h3>
                    <h4 class="text-center text-section-subtitle wow slideInUp">
                        {{$t('components._sections.FeatureVideos.description')}}
                    </h4>
                </div>
            </div>
            <div class="flex flex--center" v-if="loading">
                <div class="loading-indicator"></div>
            </div>
            <div class="row" v-else>
                <div class="col-sm-3 pb40 pb20:sm flex--one wow slideInUp" v-for="course of featured" :key="course.id">
                    <course-card :course="course" :grid="grid" :featured="featuredCourses" :disabled="course.notAvailable" :disabledText="$t('components.Courses.CourseCard.waitlist')" @disabledClick="displayPopup(course)"></course-card>
                </div>
            </div>
            <div class="flex flex--center pt20 pt15:sm">
                <router-link :to="{ name: 'courses', params: {lang: $i18n.locale} }" class="btn">{{$t('components._sections.FeatureVideos.viewmore')}}
                </router-link>
            </div>
        </div>
        <waitlist-popup></waitlist-popup>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import CourseCard from '../Courses/CourseCard.vue';
import WaitListPopup from '../Courses/WaitlistPopup.vue';

export default {
    name: 'section-feature-videos',
    components: {
        'course-card': CourseCard,
        'waitlist-popup': WaitListPopup,
    },

    props: {
        grid: {
            type: Boolean,
            default: true,
            required: false,
        },

        featuredCourses: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    data() {
        return {
            loading: true,
        };
    },
    watch: {
        '$i18n.locale': {
            handler() {
                this.loading = true;
                this.$store.dispatch('courses/fetchFeatured')
                    .then(() => {
                        this.loading = false;
                    });
            },
        },
    },

    beforeCreate() {
        this.$store.dispatch('courses/fetchFeatured')
            .then(() => {
                this.loading = false;
            });
    },

    computed: {
        ...mapState({
            featured: state => state.courses.featured,
        }),
    },

    methods: {
        displayPopup(course) {
            this.$store.commit('courses/waitlist', { field: 'success', value: false });
            this.$store.commit('courses/waitlist', { field: 'course', value: course });
            this.$store.commit('courses/waitlist', { field: 'popup', value: true });
        },
    }
};
</script>

<style>

</style>

<template>
    <div class="relative width100 height100 lecture-card box box--hover" :class="{ 'lecture-card--vertical' : !grid }">
        <div v-if="lecture.live && (!lecture.liveStream || !lecture.liveStream.startedAt)">
            <lecture-card-content :lecture="lecture" :grid="grid"/>
        </div>
        <div v-else-if="!$store.state.auth.token && !lecture.free" class="cursor"
             @click="redirect()">
            <lecture-card-content :lecture="lecture" :grid="grid" :disabled="true" />
        </div>
        <div v-else-if="!lecture.free && !enrolled" class="cursor" @click="enrollmentPopup()">
            <lecture-card-content :lecture="lecture" :grid="grid" />
        </div>
        <router-link :to="link" class="block" :class="{ 'box bg--white mb20' : isActive } " v-else>
            <lecture-card-content :lecture="lecture" :grid="grid" :is-active="isActive" />
        </router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LectureCardContent from './LectureCardContent.vue';

export default {
    name: 'lecture-card',
    components: { LectureCardContent },
    props: {
        lecture: {
            type: Object,
            required: true,
        },

        grid: {
            type: Boolean,
            required: false,
            default: true,
        },

        type: {
            type: String,
            required: true,
        },

        tstub: {
            type: String,
            required: true,
        },

        isActive: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        currentUrl() {
            return window.location.pathname;
        },

        ...mapState({
            enrolledCourse(state) {
                return state.auth.enrolledCourses && state.auth.enrolledCourses.find(el => el.course.stub === this.tstub);
            },
            enrolledEvent(state) {
                return state.auth.enrolledEvents && state.auth.enrolledEvents.find(el => el.event.stub === this.tstub);
            },
        }),

        enrolled() {
            switch (this.type) {
            case 'courses':
                return !!this.enrolledCourse;
            case 'events':
                return !!this.enrolledEvent;
            default:
                return false;
            }
        },

        link() {
            if (this.lecture.live) {
                return { name: 'lecture-live', params: { stub: this.lecture.stub, type: this.type, tstub: this.tstub } };
            }

            return { name: 'lecture', params: { stub: this.lecture.stub, type: this.type, tstub: this.tstub } };
        },
    },
    methods: {
        enrollmentPopup() {
            switch (this.type) {
            case 'courses':
                window.location = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
                //this.$store.dispatch('courses/enrollPopup', true);

                break;
            case 'events':
                window.location = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
                //this.$store.dispatch('courses/enrollPopup', true);
                break;
            }
        },

        redirect() {
            window.location.href = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
        },
    },
};
</script>

export default {
    saveFeatured(state, payload) {
        state.featured = payload;
    },
    saveAll(state, data) {
        state.all = data;
    },

    saveListAttr(state, { field, payload }) {
        state.list[field] = payload;
    },

    one(state, course) {
        state.one = course;
    },

    setEnrollPopup(state, value) {
        state.enrollPopup = value;
    },

    waitlist(state, { field, value }) {
        state.waitlist[field] = value;
    },

    waitlistReset(state) {
        state.waitlist.popup = false;
        state.waitlist.course = null;
        state.waitlist.success = false;
    },
};

<template>
    <section class="ph bg--primary" v-if="lecture.id">
        <div class="container pt40 pt15:sm" ref="fullscreen">
            <div class="relative height100 pb40">
                <div class="pb20" v-if="enrolled">
                    <!-- <h5 class="text--white">{{ lecture.name }}</h5>
                    <p class="small text--white">
                        {{ lecture.authors && lecture.authors.map((author)=> (`${author.name}
                        ${author.surname}`)).join(", ") }}
                    </p> -->
                    <!-- <router-link :to="{ name: 'course', params: { stub: this.course.stub } }" class="btn btn--cool-gray">
                        <v-icon-chevronLeft class="mr10" /> Nazaj na trening
                    </router-link> -->
                </div>

                <div class="flex flex--center" :class="{'flex--row-reverse': switchMode}"
                     style="max-height: calc(100vh - 130px)">
                    <div :style="{'width': [50,70,30,100,0][layout]+'%'}"
                         style="transition: 200ms; transition-timing-function: linear;max-width: calc((100vh - 130px) / 0.5625);"
                         class="relative"
                         :class="{'pl15': layout!==4 && layout!==3,'pr15': layout!==4 && layout!==3}" @click="switchPlay()">
                        <div class="lecture-play-icon" v-if="!play">
                            <img :src="require('../../assets/images/play-button.png')">
                        </div>
                        <c-video
                                ref="video"
                                width="600"
                                height="400"
                                class="video-js"
                                :poster="lecture.video && lecture.video.image ? lecture.video.image.uri : null"
                                webkit-playsinline
                                playsinline
                                x-webkit-airplay="allow"
                                x5-video-player-type="h5"
                                x5-video-player-fullscreen="true"
                                x5-video-orientation="portraint"
                                :sources="[`${lecture.video.sources[quality]}${appendToken()}`]"
                                @ended="videoEnd"
                                @ready="videoReady"
                                @timeupdate="timeUpdate"
                                @loadstart="loadStart"
                        ></c-video>
                    </div>

                    <div
                            v-if="$store.state.lectures.one.video.slides.length > 0"
                            :style="{'width': [50,30,70,0,70][layout]+'%'}"
                            style="transition: 200ms; transition-timing-function: linear;max-width: calc((100vh - 282px)/0.75)"
                            class="width-mobile slide flex flex--center"
                            :class="{'pl15': layout!==3,'pr15': layout!==3}"
                    >
                        <div class="relative width100">
                            <div class="relative">
                                <img :src="$store.state.lectures.one.video.slides[slideId].resource.uri" class="img">
                                <div class="slide__prev" @click="prevSlide()" v-if="slidePrev"></div>
                                <div class="slide__next" @click="nextSlide()" v-if="slideNext"></div>
                            </div>
                        </div>
                    </div>
                    <div
                            v-if="$store.state.lectures.one.video.chapters.length > 0 && !($store.state.lectures.one.video.slides.length > 0)"
                            :style="{'width': [50,30,70,0,70][layout]+'%'}"
                            style="transition: 200ms; transition-timing-function: linear;max-width: calc((100vh - 282px)/0.75)"
                            class="width-mobile slide flex flex--center"
                            :class="{'pl15': layout!==3,'pr15': layout!==3}"
                    >
                        <div class="box bg--white width100 absolute absolute--full"
                             style="overflow: auto">
                            <div class="pt10 pb10 pl15 pr15">
                                <h4>{{$t('components.VideoPlayer.PlayerChapters.chapters')}}</h4>
                            </div>
                            <hr>
                            <template v-for="(chapter,i) of $store.state.lectures.one.video.chapters">
                                <div :class="{'bg--blue text--white': chapterId===i}"
                                     class="cursor pl15 pr15 pt5 pb5" @click="changeChapter(i)">
                                    {{ chapter.time | playertime }} - {{chapter.name}}
                                </div>
                                <hr>
                            </template>

                        </div>

                    </div>
                </div>

                <Timeline :type="type" :stub="stub" :enrolled="enrolled"></Timeline>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';

import Video from '../Shared/Video.vue';
import Timeline from './Includes/Timeline.vue';

export default {
    name: 'player',

    components: {
        'c-video': Video,
        Timeline,
    },

    props: {
        type: {
            type: String,
            required: true,
        },

        stub: {
            type: String,
            required: true,
        },

        enrolled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            newSource: false,
            timeDif: 0,
        };
    },

    beforeMount() {
        document.addEventListener('fullscreenchange', this.exitHandler);
        document.addEventListener('webkitfullscreenchange', this.exitHandler);
        document.addEventListener('mozfullscreenchange', this.exitHandler);
        document.addEventListener('MSFullscreenChange', this.exitHandler);
    },

    beforeDestroy() {
        document.removeEventListener('fullscreenchange', this.exitHandler);
        document.removeEventListener('webkitfullscreenchange', this.exitHandler);
        document.removeEventListener('mozfullscreenchange', this.exitHandler);
        document.removeEventListener('MSFullscreenChange', this.exitHandler);
    },

    watch: {
        fullscreen(fullscreen) {
            if (!fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                    document.msExitFullscreen();
                }
            } else if (this.$refs.fullscreen.requestFullscreen) {
                this.$refs.fullscreen.requestFullscreen();
            } else if (this.$refs.fullscreen.mozRequestFullScreen) { /* Firefox */
                this.$refs.fullscreen.mozRequestFullScreen();
            } else if (this.$refs.fullscreen.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                this.$refs.fullscreen.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (this.$refs.fullscreen.msRequestFullscreen) { /* IE/Edge */
                this.$refs.fullscreen.msRequestFullscreen();
            }
        },

        quality(q) {
            this.newSource = true;
            this.video.src({ type: 'application/x-mpegURL', src: `${this.lecture.video.sources[q]}${this.appendToken()}` });

            this.video.currentTime(this.time);

            this.video.play();
        },
        lecture() {
            if (this.video) {
                this.newSource = true;
                this.video.src({ type: 'application/x-mpegURL', src: `${this.lecture.video.sources[this.quality]}${this.appendToken()}` });
                this.video.poster(this.lecture.video && this.lecture.video.image ? this.lecture.video.image.uri : null);
                this.video.currentTime(0);
                this.time = 0;
                this.play = false;
            }
        },
    },

    computed: {
        ...mapState({
            course: state => state.courses.one,
            lecture: state => state.lectures.one,
            quality: state => state.lectures.selectedQuality,
        }),

        video: {
            get() {
                return this.$store.state.lectures.player.video;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'video', value });
            },
        },

        fullscreen: {
            get() {
                return this.$store.state.lectures.player.fullscreen;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'fullscreen', value });
            },
        },

        play: {
            get() {
                return this.$store.state.lectures.player.play;
            },
            set(value) {
                this.$store.dispatch('lectures/videoLog', value ? 'PLAY' : 'PAUSE');
                this.$store.commit('lectures/player', { field: 'play', value });
            },
        },

        layout: {
            get() {
                return this.$store.state.lectures.player.layout;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'layout', value });
            },
        },

        switchMode: {
            get() {
                return this.$store.state.lectures.player.switchMode;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'switchMode', value });
            },
        },

        time: {
            get() {
                return this.$store.state.lectures.player.time;
            },
            set(value) {
                if (Math.abs(this.timeDif - value) > 3) {
                    this.timeDif = value;
                    this.$store.dispatch('lectures/videoLog', 'PROGRESS');
                }

                this.$store.commit('lectures/player', { field: 'time', value });
            },
        },

        disabled: {
            get() {
                return this.$store.state.lectures.player.disabled;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'disabled', value });
            },
        },

        slideId: {
            get() {
                return this.$store.state.lectures.player.slideId;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'slideId', value });
            },
        },

        chapterId: {
            get() {
                return this.$store.state.lectures.player.chapterId;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'chapterId', value });
            },
        },

        slidePrev() {
            return this.slideId > 0;
        },

        slideNext() {
            return this.slideId < this.$store.state.lectures.one.video.slides.length - 1;
        },
    },

    methods: {
        loadStart() {
            if (this.time != 0) {
                return;
            }

            this.video.currentTime(this.time);
        },

        switchPlay() {
            this.play = !this.play;
            if (this.play) {
                this.$matomo && this.$matomo.trackEvent('lecture', 'play');
                this.video.play();
            } else {
                this.$matomo && this.$matomo.trackEvent('lecture', 'pause');
                this.video.pause();
            }
        },

        nextSlide() {
            if (this.slideId < this.$store.state.lectures.one.video.slides.length - 1) {
                this.$matomo && this.$matomo.trackEvent('lecture', 'next slide');
                this.slideId += 1;
                this.disabled = true;
                this.time = this.$store.state.lectures.one.video.slides[this.slideId].time;
                this.video.currentTime(this.$store.state.lectures.one.video.slides[this.slideId].time);
                this.disabled = false;
            }
        },

        prevSlide() {
            if (this.slideId > 0) {
                this.$matomo && this.$matomo.trackEvent('lecture', 'previous slide');
                this.slideId -= 1;
                this.disabled = true;
                this.time = this.$store.state.lectures.one.video.slides[this.slideId].time;
                this.video.currentTime(this.$store.state.lectures.one.video.slides[this.slideId].time);
                this.disabled = false;
            }
        },

        changeChapter(i) {
            this.$matomo && this.$matomo.trackEvent('lecture', 'change chapter');
            this.chapterId = i;
            this.disabled = true;
            this.time = this.$store.state.lectures.one.video.chapters[this.chapterId].time;
            this.video.currentTime(this.$store.state.lectures.one.video.chapters[this.chapterId].time);
            this.disabled = false;
        },

        videoReady() {
            this.video = this.$refs.video.videojsObject;
        },

        videoEnd() {
            this.play = false;
            this.video.currentTime(0);
            this.video.pause();
        },

        timeUpdate() {
            if (this.newSource) {
                this.newSource = false;
                return;
            }

            this.time = this.video.currentTime();

            let id = 0;
            for (let i = 0; i < this.lecture.video.slides.length; i += 1) {
                if (this.lecture.video.slides[i].time <= this.time) {
                    id = i;
                }
            }
            if (id !== this.slideId) {
                this.slideId = id;
            }
            for (let i = 0; i < this.lecture.video.chapters.length; i += 1) {
                if (this.lecture.video.chapters[i].time <= this.time) {
                    id = i;
                }
            }
            if (id !== this.chapterId) {
                this.chapterId = id;
            }
        },

        exitHandler() {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                ///fire your event
                this.fullscreen = false;
            }
        },

        appendToken() {
            if (localStorage.getItem('auth_token')) {
                return `?token=${localStorage.getItem('auth_token')}`;
            }

            return '';

        },
    },
};
</script>

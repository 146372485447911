<template>
    <div class="app-wrapper">
        <v-header type="center"></v-header>
        <main>
            <router-view/>
        </main>
        <v-footer></v-footer>
        <v-modal :open="popup" @close="popup=false">
            <template v-if="popupView === 'login'">
                <h3 class="text-center pb20">{{ $t('views.Auth.Login.title')}}</h3>
                <form-auth-login @success="popup=false"></form-auth-login>
                <p class="text-center pt5">
                    {{ $t('views.Auth.Login.form.additional.text')}}
                    <a @click="popupView='register'" to="/register" class="text--blue">{{
                        $t('views.Auth.Login.form.additional.link')}}
                    </a>
                </p>
            </template>
            <template v-if="popupView === 'register'">
                <h3 class="text-center pb20">{{ $t('views.Auth.Register.title')}}</h3>
                <form-auth-register @success="popup=false"></form-auth-register>
                <p class="text-center pt5">
                    {{ $t('views.Auth.Register.form.additional.text')}}
                    <a @click="popupView = 'login'" class="text--blue">{{
                        $t('views.Auth.Register.form.additional.link')}}
                    </a>
                </p>
            </template>
        </v-modal>
        <cookie-popup></cookie-popup>
    </div>
</template>

<script>
import header from './components/Base/Header.vue';
import footer from './components/Base/Footer.vue';
import FormAuthLogin from './components/Forms/auth/Login.vue';
import FormAuthRegister from './components/Forms/auth/Register.vue';
import CookiePopup from './components/Base/Cookie.vue';


export default {
    components: {
        CookiePopup,
        FormAuthRegister,
        FormAuthLogin,
        'v-header': header,
        'v-footer': footer,
    },

    watch: {
        '$i18n.locale': {
            handler() {
                this.$store.dispatch('shop/loadCart');
                this.$store.dispatch('other/pages');
            },
        },
    },

    computed: {
        popup: {
            get() {
                return this.$store.state.other.popup;
            },
            set(value) {
                window.location = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
                // this.$store.dispatch('other/popup', true);
            },
        },

        popupView: {
            get() {
                return this.$store.state.other.popupView;
            },
            set(value) {
                window.location = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
                // this.$store.dispatch('other/popup', true);
            },
        },
    },

    beforeMount() {
        let lang = localStorage.getItem('lang');
        if (!['sl_SI', 'en_US', 'hr_HR'].includes(lang)) {
            localStorage.removeItem('lang');
            lang = null;
        }
        if (lang) {
            this.$i18n.locale = lang;
        }

        this.$store.dispatch('other/portal')
            .then((portal) => {
                this.$store.dispatch('auth/tokenUser')
                    .then((res) => {
                        if (portal.facebookPixel && res) {
                            this.$analytics.fbq.init(portal.facebookPixel, {
                                em: res.account.email,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
        this.$store.dispatch('shop/loadCart');
        this.$store.dispatch('other/pages');
        // this.$store.dispatch('courses/fetchAll');
        // this.$store.dispatch('events/fetchAll');
    },
};
</script>

<template>
    <div class="box box--hover mb30">
        <div v-if="image">
            <div class="flex flex--middle">
                <div class="bg--image" style="height: 240px; width: 100%; border-radius: 0px;" :style="{'background-image': `url(${image})`}"></div>
            </div>
        </div>

        <div class="pl30 pt30 pb10 pr30 pl15:sm pr15:sm pb5:sm pt15:sm">
            <p>
                <i>
                    {{ text }}
                </i>
            </p>
        </div>

        <div class="pl30 pb20 pr30 pl15:sm pr15:sm pb15:sm pt15:sm">
            <h6 v-if="name">
                {{ name }}
            </h6>
            <p class="mb0 text--grey small" v-if="country">
                {{ country }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CustomerCard',

        props: {
            image: {
                type: String,
                required: false,
            },

            text: {
                type: String,
                required: true,
            },

            name: {
                type: String,
                required: false,
            },

            country: {
                type: String,
                required: false,
            },
        }
    }
</script>

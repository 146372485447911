<template>
    <div class="row">
        <div class="col-md-3 pb20">
            <div class="flex flex--column box">
                <div class="tab__head" @click="selectTab(tab)" v-for="(tab, i) in tabs" :key="i"
                     :class="{'active': tab.name === value}">{{ tab.name }}
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-tabs-columns',

    props: {
        value: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            tabs: [],
        };
    },

    methods: {
        selectTab(selectedTab) {
            this.$emit('input', selectedTab.name);
        },
    },

    created() {
        this.tabs = this.$children;
    },
};
</script>

<template>
    <div>
        <form @submit.prevent="submit($event)" novalidate>
            <input type="text" :placeholder="$t('views.Auth.Register.form.name.placeholder')"
                   v-model="name.value" class="mb10" required
                   @blur="$v.name.value.$touch()"
                   autocomplete="name given-name"
                   :class="{'error': $v.name.value.$dirty && $v.name.value.$invalid}">
            <p v-if="!$v.name.value.required && $v.name.value.$dirty" class="errortext">{{
                $t('views.Auth.Register.form.name.required')}}</p>

            <input type="text" :placeholder="$t('views.Auth.Register.form.surname.placeholder')"
                   v-model="surname.value" class="mb10" required
                   @blur="$v.surname.value.$touch()"
                   autocomplete="name family-name"
                   :class="{'error': $v.surname.value.$dirty && $v.surname.value.$invalid}">
            <p v-if="!$v.surname.value.required && $v.surname.value.$dirty" class="errortext">{{
                $t('views.Auth.Register.form.surname.required')}}</p>

            <input type="email" :placeholder="$t('views.Auth.Register.form.email.placeholder')"
                   v-model="email.value" class="mb10" required
                   @blur="$v.email.value.$touch()"
                   autocomplete="email"
                   :class="{'error': $v.email.value.$dirty && $v.email.value.$invalid}">
            <p v-if="!$v.email.value.required && $v.email.value.$dirty" class="errortext">{{
                $t('views.Auth.Register.form.email.required')}}</p>
            <p v-if="!$v.email.value.email && $v.name.value.$dirty" class="errortext">{{
                $t('views.Auth.Register.form.email.email')}}</p>

            <div class="relative mb10">
                <input :type="password.visible?'text':'password'"
                       :placeholder="$t('views.Auth.Register.form.password.placeholder')"
                       v-model="password.value"
                       style="padding-right: 40px" required
                       @blur="$v.password.value.$touch()"
                       autocomplete="new-password"
                       :class="{'error':  $v.password.value.$dirty && $v.password.value.$invalid}">
                <div :style="{'width': Math.min(100, scorePassword(password.value))+'%', 'background':scorePassword(password.value)>=60? 'green':'red' }"
                     style="height: 3px;"></div>
                <div class="absolute absolute--right absolute--top absolute--bottom">
                    <div class="flex flex--middle height100 pr10">
                        <div @click="password.visible=!password.visible" class="cursor">
                            <div>
                                <v-icon-eye v-if="password.visible"></v-icon-eye>
                                <v-icon-eyeOff v-else></v-icon-eyeOff>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <p v-if="!$v.password.value.required && $v.password.value.$dirty" class="errortext">{{
                $t('views.Auth.Register.form.password.required')}}</p>
            <p v-if="!$v.password.value.passwordStrength && $v.password.value.$dirty && $v.password.value.required"
               class="errortext">{{ $t('views.Auth.Register.form.password.strong')}}</p>
            <div class="relative mb10">
                <input :type="repassword.visible?'text':'password'"
                       :placeholder="$t('views.Auth.Register.form.repassword.placeholder')"
                       v-model="repassword.value" style="padding-right: 40px"
                       required
                       @blur="$v.repassword.value.$touch()"
                       :class="{'error': $v.repassword.value.$dirty && $v.repassword.value.$invalid}">
                <div :style="{'width': Math.min(100, scorePassword(repassword.value))+'%', 'background':scorePassword(repassword.value)>=60? 'green':'red' }"
                     style="height: 3px;"></div>
                <div class="absolute absolute--right absolute--top absolute--bottom">
                    <div class="flex flex--middle height100 pr10">
                        <div @click="repassword.visible=(!repassword.visible)" class="cursor">
                            <div>
                                <v-icon-eye v-if="repassword.visible"></v-icon-eye>
                                <v-icon-eyeOff v-else></v-icon-eyeOff>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p v-if="!$v.repassword.value.required && $v.repassword.value.$dirty" class="errortext">{{
                $t('views.Auth.Register.form.repassword.required')}}</p>
            <p v-if="!$v.repassword.value.same && $v.repassword.value.$dirty && $v.repassword.value.required"
               class="errortext">{{ $t('views.Auth.Register.form.repassword.match')}}</p>
            <label class="radio pb20">
                <input type="checkbox" v-model="newsletter">
                <span></span>
                <p>{{$t('components.Forms.auth.Register.newsletter')}}</p>
            </label>
            <p class="pb5 text-center">
                    {{ $t('views.Auth.Register.form.terms.text')}} 
                <a href="/SI/static/terms-conditions" target="_blank"class="text--blue text-bold">
                    {{$t('views.Auth.Register.form.terms.terms')}}
                </a> 
                    {{$t('views.Auth.Register.form.terms.and')}} 
                <a href="/SI/static/privacy" target="_blank" class="text--blue text-bold">
                    {{$t('views.Auth.Register.form.terms.privacy')}}
                </a>
            </p>
            <div class="loading-indicator" v-if="sending"></div>
            <button class="btn--full" :disabled="$v.$invalid" v-else>
                {{$t('views.Auth.Register.form.button')}}
            </button>
            <div class="success-box flex flex--middle mt10"  v-if="success">
                <v-icon-checkCircle class="mr10" />
                <div style="width: calc(100% - 50px)">
                {{success}}
                </div>
            </div>
            <div class="error-box flex flex--middle mt10"  v-if="error">
                <v-icon-xCircle class="mr10" />
                <div style="width: calc(100% - 50px)">
                {{error}}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import {
    required, email, sameAs,
} from 'vuelidate/lib/validators';
import { passwordStrength } from '../../../validators';

export default {
    name: 'form-auth-register',

    data() {
        return {
            sending: false,
            name: {
                value: '',
            },
            surname: {
                value: '',
            },
            email: {
                value: '',
            },
            password: {
                value: '',
                visible: false,
            },
            repassword: {
                value: '',
                visible: false,
            },
            newsletter: false,
            error: '',
            success: '',
        };
    },

    validations: {
        name: {
            value: {
                required,
            },
        },

        surname: {
            value: {
                required,
            },
        },

        email: {
            value: {
                required,
                email,
            },
        },

        password: {
            value: {
                required,
                passwordStrength,
            },
        },

        repassword: {
            value: {
                required,
                same: sameAs(function () {
                    return this.password.value;
                }),
            },
        },
    },

    methods: {
        submit() {
            const self = this;
            this.sending = true;
            this.error = '';
            this.success = '';
            this.$store.dispatch('auth/register', {
                name: this.name.value,
                surname: this.surname.value,
                email: this.email.value,
                password: this.password.value,
                newsletter: this.newsletter,
            })
                .then(() => {
                    this.sending = false;
                    this.$emit('success');
                    this.name.value = '';
                    this.surname.value = '';
                    this.email.value = '';
                    this.password.value = '';
                    this.repassword.value = '';
                    this.success = self.$t('components.Forms.auth.Register.success');
                    this.$analytics.fbq.event('CompleteRegistration');
                    this.$v.$reset();
                })
                .catch((err) => {
                    this.sending = false;
                    this.error = this.$t(`components.Forms.auth.Register.${err}`);
                });
        },

        scorePassword(pass) {
            return passwordStrength(pass);
        },
    },
};
</script>

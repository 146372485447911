import { portal } from '../../api/api';
import PaymentIntent from '../../api/graphql/shop/paymentIntent.graphql';
import PaymentProcessing from '../../api/graphql/shop/paymentProcessing.graphql';

export default {
    loadCart: ({ commit }) => {
        commit('loadCart');
    },
    addToCart: ({ commit }, course) => {
        commit('addToCart', course);
    },
    removeFromCart: ({ commit }, id) => {
        commit('removeFromCart', id);
    },
    emptyCart: ({ commit }) => {
        commit('emptyCart');
    },
    paymentIntent: (context, data) => portal(PaymentIntent, {
        idType: 'COURSE',
        ids: data.cart.map(el => el.id),
    }),
    paymentProcessing: (_, paymentId) => {
        portal(PaymentProcessing, { id: paymentId }).then((success) => {
            console.log(success);
        });
    },
};

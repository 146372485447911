export default {
    setLanguages(state, languages) {
        state.languages = languages;
        localStorage.setItem('languages', JSON.stringify(languages));
    },
    setPortalName(state, name) {
        state.portalName = name;
    },
    setPages(state, pages) {
        state.pages = pages;
    },
    setPopup(state, value) {
        state.popup = value;
    },
    setPopupView(state, value) {
        state.popupView = value;
    },
    setFbPixel(state, value) {
        state.fbPixel = value;
        localStorage.setItem('fbp', value);
    },
    setGAnalytics(state, value) {
        state.gAnalytics = value;
        localStorage.setItem('ga', value);
    },

    index6FormData(state, { field, value }) {
        state.index6Form[field] = value;
    },
    index6FormReset(state) {
        state.index6Form.name = null;
        state.index6Form.email = null;
    },

    booksFormData(state, { field, value }) {
        state.booksForm[field] = value;
    },
    booksFormReset(state) {
        state.booksForm.name = null;
        state.booksForm.email = null;
    },
};

import { callAuth, portal, base } from '../../api/api';

import Portals from '../../api/graphql/portal/portal.graphql';
import Pages from '../../api/graphql/pages/pages.graphql';
import DataCollector from '../../api/graphql/dataCollector/dataCollector.graphql';

export default {
    portal: ({ commit, state }) => {
        const languages = localStorage.getItem('languages');
        const fbp = localStorage.getItem('fbp');
        const ga = localStorage.getItem('ga');
        if (languages) {
            state.languages = JSON.parse(languages);
        }
        if (fbp) {
            state.fbPixel = fbp;
        }
        if (ga) {
            state.gAnalytics = ga;
        }
        return callAuth(Portals, { filter: { id: { value: process.env.VUE_APP_PORTAL_ID, comparison: 'EQ' } } })
            .then((success) => {
                if (success.data.data && success.data.data.portals) {
                    commit('setLanguages', success.data.data.portals[0].languages);
                    commit('setFbPixel', success.data.data.portals[0].facebookPixel);
                    commit('setGAnalytics', success.data.data.portals[0].googleAnalyticId);
                    commit('setPortalName', success.data.data.portals[0].name);
                    return Promise.resolve(success.data.data.portals[0]);
                }
                return Promise.reject(success);
            })
            .catch(error => Promise.reject(error));
    },
    pages: ({ commit }) => portal(Pages, {})
        .then((success) => {
            if (success.data.data && success.data.data.pages) {
                commit('setPages', success.data.data.pages);
                return Promise.resolve(success.data.data.pages);
            }
            return Promise.reject(success);
        })
        .catch(error => Promise.reject(error)),
    popup: ({ commit }, value) => {
        commit('setPopup', value);
    },
    popupView: ({ commit }, value) => {
        commit('setPopupView', value);
    },
    index6Form: ({ commit, state }) => {
        base(DataCollector, { keyword: 'index6Form', value: JSON.stringify(state.index6Form), }).then((success) => {
            commit('index6FormReset');
        })
    },
    booksForm: ({ commit, state }) => {
        base(DataCollector, { keyword: 'booksForm', value: JSON.stringify(state.booksForm), }).then((success) => {
            commit('booksFormReset');
        })
    },
    waitlistForm: ({ commit, rootState }, waitlist) => {
        base(DataCollector, { keyword: `waitlist-${rootState.courses.waitlist.course.stub}`, value: JSON.stringify(waitlist), }).then((success) => {
            commit('courses/waitlist', { field: 'success', value: true }, { root: true });
        })
    },
};

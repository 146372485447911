export default {
    latest: [],
    list: {
        page: 1,
        limit: 5,
        category: null,
        search: '',
        data: [],
        total: 0,
    },
    upcoming: [],

    all: [],

    one: {
        id: null,
        name: null,
        abstract: null,
        authors: [],
        stub: null,
        city: null,
        description: null,
        thumb: {
            uri: {
                http: null,
            },
        },
        image: {
            uri: {
                http: null,
            },
        },
        gallery: [],
        lectures: [],
        dateFrom: null,
        dateTo: null,
    },

    enrollPopup: false,
};

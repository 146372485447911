<template>
    <section>

    <div class="container pt60 pb60 pt30:sm pb30:sm">
        <div class="row flex--center">
            <div class="col-md-8">
                <h3 class="mb10 text-center text-section-title">
                    {{ $t('components._sections.Faq2.title') }}
                </h3>
                <h4 class="text-center text-section-subtitle">
                    {{ $t('components._sections.Faq2.subtitle') }}
                </h4>
            </div>
        </div>
        <div class="row pt20">
            <div class="col-md-6">
                <div class="box bg--image quadric"
                     :style="{'background-image': `url(${require('../../assets/images/faq.jpg')})`}"></div>
            </div>
            <div class="col-md-6 pt15 pb15 wow slideInUp">
                <u-animate-container>
                    <u-animate
                            name="slideInUp"
                            delay="0s"
                            duration="1s"
                            animateClass="animated"
                    >
                        <v-accordion :title="$t('components._sections.Faq2.faq.0.question')">
                            <p class="mb0 pt15">
                                {{ $t('components._sections.Faq2.faq.0.answer') }}
                            </p>
                        </v-accordion>
                        <v-accordion :title="$t('components._sections.Faq2.faq.1.question')">
                            <p class="mb0 pt15">
                                {{ $t('components._sections.Faq2.faq.1.answer') }}
                            </p>
                        </v-accordion>
                        <v-accordion :title="$t('components._sections.Faq2.faq.2.question')">
                            <p class="mb0 pt15">
                                {{ $t('components._sections.Faq2.faq.2.answer') }}
                            </p>
                        </v-accordion>
                    </u-animate>
                </u-animate-container>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { UAnimateContainer, UAnimate } from 'vue-wow';

export default {
    name: 'section-faq-2',

    components: {
        UAnimateContainer,
        UAnimate,
    },
};
</script>

<template>
    <div>
        <div @click="open=!open">
            <slot name="trigger"></slot>
        </div>
        <article class="popup" :class="{'open': open}" @click.self="open=false">
            <div class="popup__content col-md-8 pl0 pr0">
                <slot name="content"></slot>
            </div>
        </article>
    </div>
</template>

<script>
export default {
    name: 'c-popup',

    props: {
        youtube: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            open: false,
        };
    },

    watch: {
        open(val) {
            if (!val && this.youtube) {
                document.getElementById('iframevideo').contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
            }
        },
    },
};
</script>

<style scoped>

</style>

export default {
    list: {
        page: 1,
        limit: 12,
        search: '',
        data: [],
        total: 0,
    },
    one: {
        id: null,
        stub: null,
        name: null,
        surname: null,
        academictitle: null,
        abstract: null,
        description: null,
        image: { uri: { http: null } },
        lectures: [],
    },
};

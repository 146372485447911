<template>
    <section>

    <div class="container pt60 pb60 pt30:sm pb30:sm">
        <div class="row flex--center">
            <div class="col-md-8">
                <h3 class="text-center text-section-title wow slideInUp">
                    {{ $t('components._sections.PopularCourses.title') }}
                </h3>
                <h4 class="text-center text-section-subtitle wow slideInUp">
                    {{ $t('components._sections.PopularCourses.subtitle') }}
                </h4>
            </div>
        </div>
        <div class="row flex--center pt30">
            <div class="col-md-11">
                <carousel class="owl-dark" :loop="true" v-if="featuredLoaded"
                          :responsive="{0:{nav:false,dots:true,items:1},992:{nav:true,dots:false,items:3}}">
                    <div v-for="(feature, fid) of featured" :key="fid"
                         class="pl15 pr15 pb30">
                       <course-card :course="feature"></course-card>
                    </div>
                </carousel>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import carousel from 'vue-owl-carousel';
import { mapState } from 'vuex';
import CourseCard from '../Courses/CourseCard.vue';

export default {
    name: 'section-popular-courses',

    components: {
        CourseCard,
        carousel,
    },

    data() {
        return {
            featuredLoaded: false,
        };
    },

    computed: {
        ...mapState({
            featured: state => state.courses.featured,
        }),
    },

    beforeCreate() {
        this.$store.dispatch('courses/fetchFeatured')
            .then(() => {
                this.featuredLoaded = true;
            });
    },
};
</script>
<template>
    <div>
        <section class="bg--image ph"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover_small.jpg')})`}">
            <div class="container pt90 pt30:sm pb60 pb30:sm">
                <div class="row flex--center flex--middle pb20">
                    <div class="col-md-8">
                        <h2 class="text--white text-center">{{$t('views.Events.Events.title')}}</h2>
                    </div>
                </div>
            </div>
        </section>


        <!-- <section>
            <div class="container pt40 pb20 pt20:sm pb0:sm">
                <div class="flex--right">
                    <div class="col-md-4 pl0:sm pr0:sm">
                        <form class="flex flex--between box"
                              style="border: solid 1px #eae9e9; width: 100%; border-radius: 200px;"
                              @submit.prevent="searchQuery()">
                            <input type="text" placeholder="Search" class="pl20" v-model="search"
                                   style="border: 0; width: calc(100% - 54px); border-radius: 200px;">
                            <button class="btn--round" style="min-width: inherit">
                                <v-icon-search></v-icon-search>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="bg--bg pb60 pt60 pb20:sm">
            <div class="container">
                <div class="row">
                    <!-- <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-12 col-xs-6 flex--one pb20">
                                <div class="box pl15 pr15 pt15 pb15 height100 width100">
                                    <h6 class="text-left pb10">{{$t('views.Events.Events.categories')}}</h6>
                                    <div class="flex flex--column pl15">
                                        <div class="mb5 cursor" :class="{'text--blue text-bold': category === null}"
                                             @click="category=null">{{ $t('global.all-categories')}}
                                        </div>
                                        <div class="mb5 cursor" v-for="(cat, catid) of filteredCategories" :key="catid"
                                             :class="{'text--blue text-bold': category === cat.id}"
                                             @click="category=cat.id">
                                            {{cat.name}} ({{cat.count}})
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-12">
                        <div class="flex flex--center pt20 pb20" v-if="loading">
                            <div class="loading-indicator"></div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-6 pb30" v-for="(event, ei) in events" :key="ei">
                                <event-card :event="event" :grid="true"></event-card>
                            </div>
                        </div>

                        <div class="flex flex--center pb20" v-if="totalPages > 1">
                            <button class="btn--round" style="min-width: inherit" @click="page-=1" v-if="page > 1">
                                <v-icon-chevronLeft height="20px" width="20px"></v-icon-chevronLeft>
                            </button>
                            <div style="height: 50px; width: 50px;" v-else></div>

                            <div class="flex flex--middle flex--center pl15 pr15" style="height: 50px">
                                <h4>{{ page }}/{{totalPages}}</h4>
                            </div>

                            <button class="btn--round" style="min-width: inherit" @click="page+=1"
                                    v-if="page<totalPages">
                                <v-icon-chevronRight height="20px" width="20px"></v-icon-chevronRight>
                            </button>
                            <div style="height: 50px; width: 50px;" v-else></div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section-newsletter />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import EventCard from '../../components/Events/EventCard.vue';
import SectionNewsletter from '../../components/_sections/Newsletter.vue';

export default {
    components: {
        SectionNewsletter,
        EventCard,
    },

    data() {
        return {
            loading: true,
        };
    },

    watch: {
        '$i18n.locale': {
            handler() {
                document.title = `${this.portalName} - ${this.$t('views.Events.Events.title')}`;
                this.loading = true;
                this.$store.dispatch('categories/fetch');
                this.$store.dispatch('events/fetch')
                    .then(() => {
                        this.loading = false;
                    });
            },
        },

        portalName: {
            handler(portalName) {
                document.title = `${portalName} - ${this.$t('views.Events.Events.title')}`;
            },
        },
    },


    computed: {
        ...mapState({
            events: state => state.events.list.data,
            eventsAll: state => state.events.all,
            categories: state => state.categories.categories,
            limit: state => state.events.list.limit,
            total: state => state.events.list.total,
            portalName: state => state.other.portalName,
        }),

        filteredCategories() {
            const catObject = {};
            this.eventsAll.map(el => el.categories).flat().forEach((cat) => {
                if (cat.id in catObject) {
                    catObject[cat.id].count += 1;
                } else {
                    catObject[cat.id] = cat;
                    catObject[cat.id].count = 1;
                }
            });
            return Object.values(catObject);
        },

        page: {
            get() {
                return this.$store.state.events.list.page;
            },
            set(page) {
                this.$store.dispatch('events/changePagination', page);
                this.loading = true;
                this.$store.dispatch('events/fetch').then(() => {
                    this.loading = false;
                });
            },
        },

        search: {
            get() {
                return this.$store.state.events.list.search;
            },
            set(search) {
                this.$store.dispatch('events/changeSearch', search);
            },
        },

        category: {
            get() {
                return this.$store.state.events.list.category;
            },
            set(slug) {
                this.$store.dispatch('events/changeCategory', slug);
                this.loading = true;
                this.$store.dispatch('events/fetch').then(() => {
                    this.loading = false;
                });
            },
        },

        totalPages() {
            return Math.ceil(this.total / this.limit);
        },
    },

    methods: {
        searchQuery() {
            this.page = 1;
            this.loading = true;
            this.$store.dispatch('events/fetch').then(() => {
                this.loading = false;
            });
        },
    },

    beforeMount() {
        document.title = `${this.portalName} - ${this.$t('views.Events.Events.title')}`;
        this.$store.dispatch('categories/fetch');
        this.$store.dispatch('events/fetch')
            .then(() => {
                this.loading = false;
            });
    },
};
</script>

<template>
    <section class="s-video pt150 pb60 pt60:sm pb60:sm">
        <div class="container">
            <router-link :to="{ path: '/SI/lecture/30dni_intervju02_Indira-Ekic/courses/30-dnevni-izziv'}">
                <div class="s-video__image" :style="{'background-image': 'url('+ require('../../assets/images/indira.jpg') +')'}">
                    <h5 class="text-thin mb20">
                       {{ $t('components._sections.Video.caption') }}
                    </h5>
                    <h3 class="mb20">
                        {{ $t('components._sections.Video.title') }}
                    </h3>
                    <p>
                        {{ $t('components._sections.Video.text') }}
                    </p>

                    <div class="s-video__icon">
                        <img :src="require('../../assets/images/play-button.png')">
                    </div>
                </div>
            </router-link>
        </div>
        <div class="s-video__bottom-bg"></div>
    </section>
</template>

<script>
export default {
    name: 'section-video',
};
</script>

import Vue from 'vue';
import moment from 'moment';

function pad(value, size) {
    let s = String(value);
    while (s.length < (size || 2)) {
        s = `0${s}`;
    }

    return s;
}

Vue.filter('datetime', (value) => {
    if (!value) return '';

    return moment(value).format('D. M. YYYY HH:ss');
});

Vue.filter('date', (value) => {
    if (!value) return '';

    return moment(value).format('D. M. YYYY');
});

Vue.filter('time', (value) => {
    if (!value) return '';

    return moment(value).format('HH:ss');
});

Vue.filter('playertime', (value) => {
    if (!value) return '00:00:00';

    return `${pad(Math.floor(value / 3600), 2)}:${pad(Math.floor((value / 60) % 60), 2)}:${pad(Math.floor((value % 60), 2))}`;
});

Vue.filter('duration', (value) => {
    if (!value) return '';

    return `${pad(Math.floor(value / 3600), 2)}:${pad(Math.floor((value / 60) % 60), 2)}:${pad((value % 60), 2)}`;
});

// Stringify
Vue.filter('daysStringify', (days) => {
    switch (days) {
        case 1:
            return `${days} dan`;

        case 2:
            return `${days} dneva`;

        default:
            return `${days} dni`;
    }
});

Vue.filter('hoursStringify', (hours) => {
    switch (hours) {
        case 1:
            return `${hours} ura`;

        case 2:
            return `${hours} uri`;

        default:
            return `${hours} ur`;
    }
});

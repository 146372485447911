<template>
    <section>
        <div class="container pt150 pb60 pt40:sm pb30:sm">
            <div class="row pl15:sm pr15:sm">
                <div class="col-md-5">
                    <p class="text--blue text-uppercase">
                        {{$t('components._sections.Subscription.subtitle')}}
                    </p>
                    <h3 class="text-normal text-section-title pb15">
                        {{$t('components._sections.Subscription.title')}}
                    </h3>
                    <h4 class="text-section-subtitle" v-html="$t('components._sections.Subscription.description')">
                    </h4>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-6 pt20:sm">
                    <div class="box flex">
                        <div class="flex width100">
                            <div class="col-xs-5 flex--one bg--blue-light">
                                <div class="flex flex--center pt40 pb40 text--white width100">
                                    <span class="h6">€</span>
                                    <h1>12</h1>
                                </div>
                                <div class="row flex--center pb40 bg--blue pt40" style="width: calc(100% + 30px)">
                                    <v-icon-plus></v-icon-plus>
                                </div>
                            </div>
                            <div class="col-xs-7 pt20 pb20">
                                <div class="height100 flex flex--middle pl15 pr15 pt10">
                                    <div>
                                        <h4 class="pb20">{{$t('components._sections.Subscription.package.title')}}</h4>
                                        <p>
                                            {{$t('components._sections.Subscription.package.description.0')}}
                                        </p>
                                        <p>
                                            {{$t('components._sections.Subscription.package.description.1')}}
                                        </p>
                                        <p>
                                            {{$t('components._sections.Subscription.package.description.2')}}
                                        </p>
                                        <p>
                                            {{$t('components._sections.Subscription.package.description.3')}}
                                        </p>
                                        <p>
                                            {{$t('components._sections.Subscription.package.description.4')}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-subscription',
};
</script>

import defaultState from './defaultState';

export default {
  mainCategories: [],
  subCategories: [],

  section: 'Napovednik',

  list: [],
  archivedList: [],
  enrolledList: [],
  liveEnrolledList: [],
  archivedEnrolledList: [],

  one: JSON.parse(JSON.stringify(defaultState.one)),
  oneAuthor: JSON.parse(JSON.stringify(defaultState.oneAuthor)),

  lives: [],
  successModal: false,
  uploadVideoModal: false,
  instructionsModal: false,
  packageModal: false,

  edit: JSON.parse(JSON.stringify(defaultState.edit)),
};

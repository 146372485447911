<template>
    <section class="s-faq-4 pt120 pb120 pt60:sm pb60:sm">
        <!-- TODO: change accordions -->
        <div class="container">
            <div class="row flex--center pb45">
                <div class="col-md-6">
                    <h2 class="mb10 text-center text-section-title">
                        {{ $t('components._sections.Faq4.title') }}
                    </h2>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.Faq4.subtitle') }}
                    </h4>
                </div>
            </div>
            <div class="row flex--center">
                <div class="col-md-8 wow slideInUp">
                    <u-animate-container>
                        <u-animate
                                name="slideInUp"
                                delay="0s"
                                duration="1s"
                                animateClass="animated"
                        >
                            <h4 class="mb20">
                                {{ $t('components._sections.Faq4.section_title') }}
                            </h4>

                            <v-accordion :title="$t('components._sections.Faq4.faq.0.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.0.answer') }}
                                </p>
                            </v-accordion>

                            <v-accordion :title="$t('components._sections.Faq4.faq.1.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.1.answer') }}
                                </p>
                            </v-accordion>

                            <v-accordion :title="$t('components._sections.Faq4.faq.2.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.2.answer') }}
                                </p>
                            </v-accordion>

                            <v-accordion :title="$t('components._sections.Faq4.faq.3.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.3.answer') }}
                                </p>
                            </v-accordion>
                            <v-accordion :title="$t('components._sections.Faq4.faq.4.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.4.answer') }}
                                </p>
                            </v-accordion>
                            <v-accordion :title="$t('components._sections.Faq4.faq.5.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.5.answer') }}
                                </p>
                            </v-accordion>
                            <v-accordion :title="$t('components._sections.Faq4.faq.6.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.6.answer') }}
                                </p>
                            </v-accordion>
                            <v-accordion :title="$t('components._sections.Faq4.faq.7.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.7.answer') }}
                                </p>
                            </v-accordion>
                            <v-accordion :title="$t('components._sections.Faq4.faq.8.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.8.answer') }}
                                </p>
                            </v-accordion>
                            <v-accordion :title="$t('components._sections.Faq4.faq.9.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.9.answer') }}
                                </p>
                            </v-accordion>
                            <v-accordion :title="$t('components._sections.Faq4.faq.10.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.10.answer') }}
                                </p>
                            </v-accordion>
                            <v-accordion :title="$t('components._sections.Faq4.faq.11.question')">
                                <p>
                                    {{ $t('components._sections.Faq4.faq.11.answer') }}
                                </p>
                            </v-accordion>
                        </u-animate>
                    </u-animate-container>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { UAnimateContainer, UAnimate } from 'vue-wow';

export default {
    name: 'section-faq-1',

    components: {
        UAnimate,
        UAnimateContainer,
    },
};
</script>

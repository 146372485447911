<template>
    <div class="flex  pt10 pb20 pb5:sm absolute--bottom absolute--left absolute--right slide__list__hover">
        <!-- <div
                class="slide__list pb20 hide:sm"
                style="background-color: #030810; left: 15px; width: calc(100% - 30px); padding: 10px;"
                v-if="$store.state.lectures.one.video.slides.length > 0"
        >
            <carousel :loop="false" :nav="true" :dots="false" :margin="15"
                      :responsive="{0:{items: 3},992:{items:6}}">
                <div class="item" v-for="(slide,i) of $store.state.lectures.one.video.slides" :key="i">
                    <div class="width100 cursor" @click="changeSlide(i)">
                        <div class="bg--image quadric"
                             :style="{'background-image': 'url('+slide.resource.uri+')'}"></div>
                        <span class="time" ref="370">{{ convertTime(slide.time) }}</span>
                    </div>
                </div>
            </carousel>

        </div> -->

        <div class="flex">
            <div @click="switchPlay()" class="cursor">
                <div v-if="play">
                    <v-icon-stop></v-icon-stop>
                </div>
                <div v-else class="text--white">
                    <v-icon-play height="24px" width="24px" class="mr5 ml5"></v-icon-play>
                </div>
            </div>
            <span class="text--white">
                {{ time | playertime }}
            </span>
        </div>
        <div class="track pl10 pr10" style="height: 30px; margin-top: -2px;"
             :style="{'width': 'calc(100% - 195px)'}">
        </div>


        <div class="flex">
            <div class="cursor dropdown_indicator hide:sm mr5">
                <div class="text--white" @click="switchVolume()">

                    <div v-if="volume>0 && volume <=0.5">
                        <v-icon-volumea></v-icon-volumea>
                    </div>
                    <div v-else-if="volume>0.5">
                        <v-icon-volumeb></v-icon-volumeb>
                    </div>
                    <div v-else>
                        <v-icon-volume></v-icon-volume>
                    </div>
                </div>
                <div class="dropdown box" style="z-index: 999; background: transparent; width: 50px;height: 50px;left: 20px;
    transform: translateX(-50%) rotate(-90deg);box-shadow: none">
                    <input type="range" min="0" max="1" step="0.1" v-model="volume"
                           style="width: 50px;">
                </div>
            </div>
            <div class="cursor dropdown_indicator">
                <div class="text--white">
                    <v-icon-monitor></v-icon-monitor>
                </div>
                <div class="dropdown box pt5 pb5" style="z-index: 999; right: -5px;">
                    <div @click="$store.commit('lectures/changeQuality', 'q360p')"
                         :class="{'text--white bg--gradient': quality==='q360p'}" class="pl5 pr5"
                         style="height: 22px; margin: 2px 0">
                        360p
                    </div>
                    <div @click="$store.commit('lectures/changeQuality', 'q480p')"
                         :class="{'text--white bg--gradient': quality==='q480p'}" class="pl5 pr5"
                         style="height: 22px; margin: 2px 0">
                        480p
                    </div>
                    <div @click="$store.commit('lectures/changeQuality', 'q720p')"
                         :class="{'text--white bg--gradient': quality==='q720p'}" class="pl5 pr5"
                         style="height: 22px; margin: 2px 0">
                        720p
                    </div>
                    <div @click="$store.commit('lectures/changeQuality', 'q1080p')"
                         :class="{'text--white bg--gradient': quality==='q1080p'}" class="pl5 pr5"
                         style="height: 22px; margin: 2px 0">
                        1080p
                    </div>
                </div>
            </div>
            <div v-if="available">
                <div class="cursor" @click="switchFullscreen()">
                    <div v-if="fullscreen">
                        <v-icon-minimize></v-icon-minimize>
                    </div>
                    <div v-else>
                        <v-icon-maximize></v-icon-maximize>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import carousel from 'vue-owl-carousel';

import IconBigSlides from './Icons/BigSlides.vue';
import IconBigVideo from './Icons/BigVideo.vue';
import IconSameSize from './Icons/SameSize.vue';
import IconSlideOnly from './Icons/SlideOnly.vue';
import IconVideoOnly from './Icons/VideoOnly.vue';

export default {
    components: {
        carousel,
        IconBigSlides,
        IconBigVideo,
        IconSameSize,
        IconSlideOnly,
        IconVideoOnly,
    },

    props: {
        type: {
            type: String,
            required: true,
        },

        stub: {
            type: String,
            required: true,
        },

        enrolled: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        volume(val) {
            this.video.volume(val);
        },
        play(val) {
            if (val) {
                this.$matomo && this.$matomo.trackEvent('lecture', 'play');
            } else {
                this.$matomo && this.$matomo.trackEvent('lecture', 'pause');
            }
        },
    },

    computed: {
        ...mapState({
            video: state => state.lectures.player.video,
            lecture: state => state.lectures.one,
            quality: state => state.lectures.selectedQuality,
        }),

        typeData() {
            switch (this.type) {
            case 'courses':
                return this.$store.state.courses.one;
            case 'events':
                return this.$store.state.events.one;
            default:
                return { lectures: [] };
            }
        },

        fullscreen: {
            get() {
                return this.$store.state.lectures.player.fullscreen;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'fullscreen', value });
            },
        },

        play: {
            get() {
                return this.$store.state.lectures.player.play;
            },
            set(value) {
                // this.$store.dispatch('lectures/videoLog', value ? 'PLAY' : 'PAUSE');
                this.$store.commit('lectures/player', { field: 'play', value });
            },
        },

        layout: {
            get() {
                return this.$store.state.lectures.player.layout;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'layout', value });
            },
        },

        switchMode: {
            get() {
                return this.$store.state.lectures.player.switchMode;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'switchMode', value });
            },
        },

        time: {
            get() {
                return this.$store.state.lectures.player.time;
            },
            set(value) {
                // this.$store.dispatch('lectures/videoLog', 'PROGRESS');
                this.$store.commit('lectures/player', { field: 'time', value });
            },
        },

        volume: {
            get() {
                return this.$store.state.lectures.player.volume;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'volume', value });
            },
        },

        disabled: {
            get() {
                return this.$store.state.lectures.player.disabled;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'disabled', value });
            },
        },

        showSlides: {
            get() {
                return this.$store.state.lectures.player.showSlides;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'showSlides', value });
            },
        },

        available: {
            get() {
                return this.$store.state.lectures.player.available;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'available', value });
            },
        },
    },

    methods: {
        seek() {
            this.$matomo && this.$matomo.trackEvent('lecture', 'seek');
            // this.$store.dispatch('lectures/videoLog', 'SEEK');
        },

        convertTime(seconds) {
            return `${`0${Math.floor(seconds / 3600)}`.slice(-2)}:${`0${Math.floor((seconds % 3600) / 60)}`.slice(-2)}:${`0${seconds % 60}`.slice(-2)}`;
        },

        switchFullscreen() {
            this.fullscreen = !this.fullscreen;
        },


        switchPlay() {
            this.play = !this.play;
            if (this.play) {
                this.$matomo && this.$matomo.trackEvent('lecture', 'play');
                this.video.play();
            } else {
                this.$matomo && this.$matomo.trackEvent('lecture', 'pause');
                this.video.pause();
            }
        },

        switchLayout() {
            this.switchMode = !this.switchMode;
        },

        changeTime() {
            this.video.currentTime(this.time);
        },

        changeSlide(id) {
            this.disabled = true;
            this.time = this.$store.state.lectures.one.video.slides[id].time;
            this.video.currentTime(this.$store.state.lectures.one.video.slides[id].time);
            this.disabled = false;
        },

        switchVolume() {
            if (this.volume > 0) {
                this.volume = 0;
            } else {
                this.volume = 1;
            }
        },
    },

    mounted() {
        this.time = 0;
        document.querySelectorAll('.dropdown_indicator').forEach((el) => {
            el.addEventListener('click', () => {
                el.classList.toggle('open');
            });
        });
    },
};
</script>

<style>
    .text-underline {
        text-decoration: underline;
    }
</style>

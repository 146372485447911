export default {
  update(state) {
    const webinar = {};

    webinar.name = state.edit.name;

    if (state.edit.stub) {
      webinar.stub = state.edit.stub;
    } else {
      webinar.stub = state.edit.name ? state.edit.name.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-') : null;
    }

    webinar.description = state.edit.description;
    webinar.status = state.edit.status;
    webinar.startsAt = state.edit.starts;
    webinar.endsAt = state.edit.ends;
    webinar.free = state.edit.free;
    webinar.price = state.edit.price;
    webinar.image = state.edit.image ? state.edit.image.id : null;
    webinar.category = state.edit.category;
    webinar.organizations = state.edit.organization ? [state.edit.organization] : null;
    webinar.onEnd = state.edit.onEnd;

    webinar.additionalContent = [];
    for (let i = 0; i < state.edit.additionalContent.length; i += 1) {
      const ac = state.edit.additionalContent[i];
      const nac = {
        id: parseInt(ac.id, 10),
      };

      if (ac.image) {
        nac.type = 'image';
        nac.refId = parseInt(ac.image.id, 10);
      }

      if (ac.video) {
        nac.type = 'video';
        nac.refId = parseInt(ac.video.id, 10);
      }

      webinar.additionalContent.push(nac);
    }

    return webinar;
  },
};

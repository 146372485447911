<template>
    <div>
        <section class="bg--image ph"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover.jpg')})`}">
            <div class="container pt90 pt45:sm pb60 pb20:sm">
                <div class="row flex--center flex--middle">
                    <div class="col-md-4">
                        <div class="bg--image quadric" style="background-image: url(/images/sample.jpg)"></div>
                    </div>
                    <div class="col-md-4">
                        <h3 class="text--white">Company name</h3>
                        <div class="pt15">
                            <p class="text--white">
                                {{$t('views.Partners.Partner.lectures')}}: 11<br>
                                {{$t('views.Partners.Partner.video')}}: 80
                            </p>
                            <a href="#" class="text--white">https://www.company.com</a>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container pt60 pb60 pt20:sm pb20:sm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="pt20 pt0:sm">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolore est et explicabo
                                nostrum quasi vitae! Eos facere ipsum natus quis voluptatibus! Consequatur consequuntur
                                fuga
                                incidunt nisi repellendus sed vel.
                            </p>
                            <p>Distinctio eligendi fugiat optio soluta voluptas. Autem dolorem fuga magnam
                                necessitatibus
                                officia recusandae sit suscipit velit! A aliquid doloremque dolorum ipsa ipsum iste, non
                                placeat sed unde. Consectetur, eum, voluptas.
                            </p>
                            <p>Aut doloribus molestias quod temporibus. Aliquam ipsam nobis numquam omnis quae quam
                                voluptatum! Ab ad aliquam blanditiis consequatur dignissimos, doloribus eum fugit maxime
                                quia quo repellat sunt. Blanditiis consequatur, eveniet!
                            </p>
                        </div>
                        <div class="relative">
                            <div class="absolute absolute--top absolute--right pt10"
                                 v-if="tabs===$t('views.Partners.Partner.tabs.curiculum')">
                                <div class="flex flex--right hide:sm">
                                    <div @click="grid=true" class="cursor">
                                        <v-icon-grid class="mr10" :class="{'text--blue': grid}">

                                        </v-icon-grid>
                                    </div>
                                    <div @click="grid=false" class="cursor">
                                        <v-icon-list :class="{'text--blue': !grid}"></v-icon-list>
                                    </div>
                                </div>
                            </div>
                            <v-tabs v-model="tabs">
                                <v-tab :name="$t('views.Partners.Partner.tabs.curiculum')" :selected="tabs">
                                    <div class="row" v-if="grid">
                                        <div class="col-md-4 flex--one mb20" >
                                            <lecture-card :lecture="lecture" :grid="grid"
                                                          class="height100 width100 block relative"></lecture-card>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-md-12 mb20">
                                            <lecture-card :lecture="lecture" :grid="grid"></lecture-card>
                                        </div>
                                    </div>
                                </v-tab>

                                <v-tab :name="$t('views.Partners.Partner.tabs.faq')" :selected="tabs">
                                    <div class="box width100 pt10 pb10 pl15 pr15 mb15">
                                        <h6>Sample faq question?</h6>
                                        <div class="mb10" v-html="course.courseFaq"></div>
                                    </div>
                                </v-tab>


                                <v-tab :name="$t('views.Partners.Partner.tabs.updates')" :selected="tabs">
                                    <div class="box width100 pt10 pb10 pl15 pr15 mb15">
                                        <h6>Sample faq question?</h6>
                                        <div class="mb10" v-html="course.news"></div>
                                    </div>
                                </v-tab>
                            </v-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            grid: true,
            tabs: this.$t('views.Partners.Partner.tabs.curiculum'),
        };
    },

    watch: {
        '$i18n.locale': {
            handler() {
            },
        },

        portalName: {
            handler(portalName) {
                document.title = `${portalName} - Partner`;
            },
        },
    },

    computed: {
        ...mapState({
            portalName: state => state.other.portalName,
        }),
    },

    beforeMount() {
        this.$matomo && this.$matomo.trackPageView();
        document.title = `${this.portalName} - Partner`;
    },
};
</script>

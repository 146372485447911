<template>
    <div>

    <index-header :version="0" :waves="false" />

    <!-- <section-webinar />  -->

    <!-- <section-about-1 /> -->

    <section-about-2 />

    <!-- <section-author /> -->

    <section-feature-videos :grid="true" :featuredCourses="false" />

    <!-- <section-latest-events/> -->

    <section-ribbon :skew="false" />

    <section-content-3/>

    <section-faq-4 />

    <section-customers/>

    <!-- <section-instructors1 /> -->

    <section-partners-1 />

    <section-newsletter/>

    <!--  <section-partners-2 /> -->


    <!-- <section-books version="subscription" /> -->

    <!-- <section-video /> -->

    <!-- <section-blog /> -->

    <!-- <section-customers3 /> -->

    <!-- <section-faq-4 class="bg--cool-gray-light mt90"/> -->

    <!-- <section-newsletter/> -->

    <!-- <section-courses /> -->

    <!-- <section-popular-courses/> -->

    <!--<section-content-2/> -->

    <!--<section-content-3/> -->

    <!--<section-content-4/> -->

    <!--<section-calendar /> -->

    <!-- <section-faq-2/> -->

    <!-- <section-faq-3/> -->

    <!--<section-customers2 /> -->

    <!--<section-blog /> -->

    <!--<section-beta /> -->

    <!--<section-books /> -->

    <!--<section-apply /> -->

    <!--<section-subscription/> -->

    </div>
</template>

<script>
import { mapState } from 'vuex';

import IndexHeader from '../../components/Index/Header.vue';

import SectionAbout1 from '../../components/_sections/About1.vue';
import SectionAbout2 from '../../components/_sections/About2.vue';

import SectionAuthor from '../../components/_sections/Author.vue';

import sectionPopularCourses from '../../components/_sections/PopularCourses.vue';
import sectionCourses from '../../components/_sections/Courses.vue';

import sectionFeatureVideos from '../../components/_sections/FeatureVideos.vue';
import sectionLatestEvents from '../../components/_sections/LatestEvents.vue';
import SectionRibbon from '../../components/_sections/Ribbon.vue';

import SectionContent1 from '../../components/_sections/Content1.vue';
import SectionContent2 from '../../components/_sections/Content2.vue';
import SectionContent3 from '../../components/_sections/Content3.vue';
import SectionContent4 from '../../components/_sections/Content4.vue';

import SectionFaq1 from '../../components/_sections/Faq1.vue';
import SectionFaq2 from '../../components/_sections/Faq2.vue';
import SectionFaq3 from '../../components/_sections/Faq3.vue';
import SectionFaq4 from '../../components/_sections/Faq4.vue';

import SectionCustomers from '../../components/_sections/Customers.vue';
import SectionCustomers2 from '../../components/_sections/Customers2.vue';
import SectionCustomers3 from '../../components/_sections/Customers3.vue';


import SectionCalendar from '../../components/_sections/Calendar.vue';

import SectionApply from '../../components/_sections/Apply.vue';

import SectionBlog from '../../components/_sections/Blog.vue';
import SectionInstructors1 from '../../components/_sections/Instructors1.vue';
import SectionBeta from '../../components/_sections/Beta.vue';
import SectionBooks from '../../components/_sections/Books.vue';
import SectionNewsletter from '../../components/_sections/Newsletter.vue';


import SectionSubscription from '../../components/_sections/Subscription.vue';

import SectionPartners1 from '../../components/_sections/Partners1.vue';
import SectionPartners2 from '../../components/_sections/Partners2.vue';
import SectionWebinar from '../../components/_sections/Webinar.vue';

import SectionVideo from '../../components/_sections/Video.vue';

export default {
    components: {
        SectionWebinar,

        IndexHeader,

        SectionAbout1,
        SectionAbout2,

        SectionAuthor,

        sectionPopularCourses,
        sectionCourses,

        sectionFeatureVideos,
        sectionLatestEvents,
        SectionRibbon,

        SectionContent1,
        SectionContent2,
        SectionContent3,
        SectionContent4,

        SectionFaq1,
        SectionFaq2,
        SectionFaq3,
        SectionFaq4,

        SectionCustomers,
        SectionCustomers2,
        SectionCustomers3,

        SectionCalendar,

        SectionBlog,
        SectionInstructors1,
        SectionBeta,
        SectionBooks,

        SectionApply,

        SectionNewsletter,

        SectionSubscription,

        SectionPartners1,
        SectionPartners2,

        SectionVideo
    },

    watch: {
        portalName(portalName) {
            document.title = `${portalName}`;
        },
    },

    computed: {
        ...mapState({
            portalName: state => state.other.portalName,
        }),
    },

    beforeMount() {
        document.title = `${this.portalName}`;
    },
};
</script>

<template>
    <section class="ph" style="background-color: #030810" v-if="lecture.id">
        <div class="container pt45 pt15:sm" ref="fullscreen">
            <div class="relative height100 pb90 pb45:sm">
                <div class="pl15 pr15 pb20">
                    <h5 class="text--white">{{ lecture.name }}</h5>
                    <p class="small text--white">
                        {{ lecture.authors && lecture.authors.map((author)=> (`${author.name}
                        ${author.surname}`)).join(", ") }}
                    </p>
                </div>

                <template v-if="isAlive">
                    <div class="flex flex--center" :class="{'flex--row-reverse': switchMode}"
                        style="max-height: calc(100vh - 130px)">
                        <div :style="{'width': [50,70,30,100,0][layout]+'%'}"
                            style="transition: 200ms; transition-timing-function: linear;max-width: calc((100vh - 130px) / 0.5625);"
                            :class="{'pl15': layout!==4,'pr15': layout!==4}" @click="switchPlay()">

                            <c-video
                                    ref="video"
                                    width="600"
                                    height="400"
                                    class="video-js"
                                    :poster="lecture.image ? lecture.image.uri : null"
                                    webkit-playsinline
                                    playsinline
                                    x-webkit-airplay="allow"
                                    x5-video-player-type="h5"
                                    x5-video-player-fullscreen="true"
                                    x5-video-orientation="portraint"
                                    :sources="[lecture.liveStream.sources[quality]]"
                                    @ended="videoEnd"
                                    @ready="videoReady"
                                    @timeupdate="timeUpdate"
                                    @loadstart="loadStart"
                            ></c-video>
                        </div>

                        <!-- <div
                                v-if="$store.state.lectures.one.video.slides.length > 0"
                                :style="{'width': [50,30,70,0,70][layout]+'%'}"
                                style="transition: 200ms; transition-timing-function: linear;max-width: calc((100vh - 282px)/0.75)"
                                class="width-mobile slide flex flex--center"
                                :class="{'pl15': layout!==3,'pr15': layout!==3}"
                        >
                            <div class="relative width100">
                                <div class="relative">
                                    <img :src="$store.state.lectures.one.video.slides[slideId].resource.uri" class="img">
                                    <div class="slide__prev" @click="prevSlide()" v-if="slidePrev"></div>
                                    <div class="slide__next" @click="nextSlide()" v-if="slideNext"></div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div
                                v-if="$store.state.lectures.one.video.chapters.length > 0 && !($store.state.lectures.one.video.slides.length > 0)"
                                :style="{'width': [50,30,70,0,70][layout]+'%'}"
                                style="transition: 200ms; transition-timing-function: linear;max-width: calc((100vh - 282px)/0.75)"
                                class="width-mobile slide flex flex--center"
                                :class="{'pl15': layout!==3,'pr15': layout!==3}"
                        >
                            <div class="box bg--white width100 absolute absolute--full"
                                style="overflow: auto">
                                <div class="pt10 pb10 pl15 pr15">
                                    <h4>{{$t('components.VideoPlayer.PlayerChapters.chapters')}}</h4>
                                </div>
                                <hr>
                                <template v-for="(chapter,i) of $store.state.lectures.one.video.chapters">
                                    <div :class="{'bg--blue text--white': chapterId===i}"
                                        class="cursor pl15 pr15 pt5 pb5" @click="changeChapter(i)">
                                        {{ chapter.time | playertime }} - {{chapter.name}}
                                    </div>
                                    <hr>
                                </template>

                            </div>

                        </div> -->
                    </div>

                    <Timeline :type="type" :stub="stub" :enrolled="enrolled"></Timeline>
                </template>
                <div
                    v-else
                    class="flex flex--center"
                    style="min-height: calc(100vh - 630px)"
                >
                    <div style="width: 600px; height: 400px; background-color: rgba(255, 255, 255, 0.03); padding-top: 130px;" class="text-center">
                        <h4 style="margin-bottom: 20px;">Video is not live yet.</h4>
                        <button class="btn btn--round pl30 pr30 mb10" @click="checkIsLive()">Refresh</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

import Video from '../Shared/Video.vue';
import Timeline from './Includes/TimelineLive.vue';

export default {
    name: 'player',

    components: {
        'c-video': Video,
        Timeline,
    },

    props: {
        type: {
            type: String,
            required: true,
        },

        stub: {
            type: String,
            required: true,
        },

        enrolled: {
            type: Boolean,
            default: false,
        },
    },

    beforeMount() {
        this.checkIsLive();
    },

    data() {
        return {
            newSource: false,
            timeDif: 0,
            isAlive: false,
        };
    },

    watch: {
        fullscreen(fullscreen) {
            if (!fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                    document.msExitFullscreen();
                }
            } else if (this.$refs.fullscreen.requestFullscreen) {
                this.$refs.fullscreen.requestFullscreen();
            } else if (this.$refs.fullscreen.mozRequestFullScreen) { /* Firefox */
                this.$refs.fullscreen.mozRequestFullScreen();
            } else if (this.$refs.fullscreen.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                this.$refs.fullscreen.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (this.$refs.fullscreen.msRequestFullscreen) { /* IE/Edge */
                this.$refs.fullscreen.msRequestFullscreen();
            }
        },

        quality(q) {
            this.newSource = true;
            this.video.src({ type: 'application/x-mpegURL', src: this.lecture.liveStream.sources[q] });

            this.video.currentTime(this.time);

            this.video.play();
        },
        lecture() {
            if (this.video) {
                this.newSource = true;
                this.video.src({ type: 'application/x-mpegURL', src: this.lecture.liveStream.sources[this.quality] });
                this.video.poster(this.lecture.image ? this.lecture.image.uri : null);
                this.video.currentTime(0);
                this.time = 0;
                this.play = false;
            }
        },
    },

    computed: {
        ...mapState({
            lecture: state => state.lectures.one,
            quality: state => state.lectures.selectedQuality,
        }),

        video: {
            get() {
                return this.$store.state.lectures.player.video;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'video', value });
            },
        },

        fullscreen: {
            get() {
                return this.$store.state.lectures.player.fullscreen;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'fullscreen', value });
            },
        },

        play: {
            get() {
                return this.$store.state.lectures.player.play;
            },
            set(value) {
                // this.$store.dispatch('lectures/videoLog', value ? 'PLAY' : 'PAUSE');
                this.$store.commit('lectures/player', { field: 'play', value });
            },
        },

        layout: {
            get() {
                return this.$store.state.lectures.player.layout;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'layout', value });
            },
        },

        switchMode: {
            get() {
                return this.$store.state.lectures.player.switchMode;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'switchMode', value });
            },
        },

        time: {
            get() {
                return this.$store.state.lectures.player.time;
            },
            set(value) {
                if (Math.abs(this.timeDif - value) > 3) {
                    this.timeDif = value;
                    // this.$store.dispatch('lectures/videoLog', 'PROGRESS');
                }

                this.$store.commit('lectures/player', { field: 'time', value });
            },
        },

        disabled: {
            get() {
                return this.$store.state.lectures.player.disabled;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'disabled', value });
            },
        },

        slideId: {
            get() {
                return this.$store.state.lectures.player.slideId;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'slideId', value });
            },
        },

        chapterId: {
            get() {
                return this.$store.state.lectures.player.chapterId;
            },
            set(value) {
                this.$store.commit('lectures/player', { field: 'chapterId', value });
            },
        },
    },

    methods: {

        loadStart() {
            this.video.currentTime(this.time);
        },

        switchPlay() {
            this.play = !this.play;
            if (this.play) {
                this.$matomo && this.$matomo.trackEvent('lecture', 'play');
                this.video.play();
            } else {
                this.$matomo && this.$matomo.trackEvent('lecture', 'pause');
                this.video.pause();
            }
        },

        nextSlide() {
            if (this.slideId < this.$store.state.lectures.one.video.slides.length - 1) {
                this.$matomo && this.$matomo.trackEvent('lecture', 'next slide');
                this.slideId += 1;
                this.disabled = true;
                this.time = this.$store.state.lectures.one.video.slides[this.slideId].time;
                this.video.currentTime(this.$store.state.lectures.one.video.slides[this.slideId].time);
                this.disabled = false;
            }
        },

        prevSlide() {
            if (this.slideId > 0) {
                this.$matomo && this.$matomo.trackEvent('lecture', 'previous slide');
                this.slideId -= 1;
                this.disabled = true;
                this.time = this.$store.state.lectures.one.video.slides[this.slideId].time;
                this.video.currentTime(this.$store.state.lectures.one.video.slides[this.slideId].time);
                this.disabled = false;
            }
        },

        changeChapter(i) {
            this.$matomo && this.$matomo.trackEvent('lecture', 'change chapter');
            this.chapterId = i;
            this.disabled = true;
            this.time = this.$store.state.lectures.one.video.chapters[this.chapterId].time;
            this.video.currentTime(this.$store.state.lectures.one.video.chapters[this.chapterId].time);
            this.disabled = false;
        },

        videoReady() {
            this.video = this.$refs.video.videojsObject;
        },

        videoEnd() {
            this.play = false;
            this.video.currentTime(0);
            this.video.pause();
        },

        timeUpdate() {
            if (this.newSource) {
                this.newSource = false;
                return;
            }

            this.time = this.video.currentTime();
        },

        checkIsLive() {
            console.log(this.lecture);
            if (!this.lecture.liveStream) {
                this.isAlive = false;
                return;
            }

            axios.get(this.lecture.liveStream.sources['q360p']).then((success) => {
                this.isAlive = true;
            }, (error) => {
                this.isAlive = false;
            })

        }
    },
};
</script>

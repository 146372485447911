<template>
    <div class="width100 height100">
        <div class="box width100 height100 block relative pt10 pl15 pr15">
            <div class="flex flex--between flex--middle">
                <h4>{{payment.amount}}€</h4>
                <p class="mb0">{{ payment.createdAt | date }}</p>
            </div>
            <div class="row pt20" v-for="(course, ci) of payment.enrolledCourses" :key="ci">
                <payment-course-card :course="course.course"></payment-course-card>
            </div>
        </div>
    </div>
</template>

<script>

import PaymentCourseCard from '../Courses/PaymentCourseCard.vue';

export default {
    name: 'payment-card',
    components: { PaymentCourseCard },
    props: {
        payment: {
            type: Object,
            required: true,
        },
    },
};
</script>

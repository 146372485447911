<template>
    <div v-show="name === selected" class="tab__content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'v-tab',

    props: {
        name: {
            type: String,
            required: true,
        },

        selected: {
            type: String,
            required: true,
        },
    },
};
</script>

<template>
    <section>
        <div class="container pt120 pt60:sm pb90 pb60:sm">
            <div class="row flex--center pb45">
                <div class="col-md-8">
                    <h3 class="text-center text-section-title">
                        {{ $t('components._sections.Content1.title') }}
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.Content1.subtitle') }}
                    </h4>
                </div>
            </div>
            <div class="row flex--center pb15 ">
                <div class="col-md-6 flex--one pb30">
                    <div class="box quadric bg--image width100" :style="{'background-image': `url(${require('../../assets/images/firstpage_01.jpg')})`}"></div>
                </div>
                <div class="col-md-6 flex--one pb30">
                    <div class="flex flex--middle width100 height100">
                        <div>
                            <h5 class="pb15">
                                {{ $t('components._sections.Content1.content.0.title') }}
                            </h5>
                            <hr class="left">
                            <p class="pt10">
                                {{ $t('components._sections.Content1.content.0.description') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row flex--center flex--row-reverse pb15">
                <div class="col-md-6 flex--one pb30">
                    <div class="box quadric bg--image width100" :style="{'background-image': `url(${require('../../assets/images/firstpage_02.jpg')})`}"></div>
                </div>
                <div class="col-md-6 flex--one pb30">
                    <div class="flex flex--middle width100 height100">
                        <div>
                            <h5 class="pb15">
                                {{ $t('components._sections.Content1.content.1.title') }}
                            </h5>
                            <hr class="right">
                            <p class="pt10">
                                {{ $t('components._sections.Content1.content.0.description') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row flex--center pb15">
                <div class="col-md-6 flex--one pb30">
                    <div class="box quadric bg--image width100" :style="{'background-image': `url(${require('../../assets/images/firstpage_03.jpg')})`}"></div>
                </div>
                <div class="col-md-6 flex--one pb30">
                    <div class="flex flex--middle width100 height100">
                        <div>
                            <h5 class="pb15">
                                {{ $t('components._sections.Content1.content.2.title') }}
                            </h5>
                            <hr class="left">
                            <p class="pt10">
                                {{ $t('components._sections.Content1.content.0.description') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex--center pt30">
                <a href="#" class="btn">
                    {{ $t('components._sections.Content1.button') }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-content-1',
};
</script>

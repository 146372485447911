<template>
    <div>
        <section class="bg--image ph"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover_small.jpg')})`}">
            <div class="container pt120 pt30:sm pb120 pb30:sm">
                <div class="row flex--center flex--middle pb60">
                    <div class="col-md-8">
                        <h2 class="text--white text-center pb15">{{$t('views.Courses.Finish.title')}}</h2>
                        <h4 class="text--white text-center text-normal">{{$t('views.Courses.Finish.subtitle')}}</h4>
                    </div>
                </div>
                <div class="row width100">
                    <div class="col-md-6">
                        <div class="quadric bg--image box pl10 pr10 pt10 pb10 mb10"
                             :style="{'background-image': 'url(' + (course.image ? course.image.uri : '') + ')'}"></div>
                    </div>
                    <div class="col-md-6 pt15">
                        <h4 class="text--white pb10">{{course.name}}</h4>
                        <h6 class="text--white">
                            {{ course.authors.map((author)=> (`${author.name}
                            ${author.surname}`)).join(", ") }}
                        </h6>
                        <p class="pt10 text--white">
                            {{course.abstract}}
                        </p>
                        <div class="flex mb10 flex--middle text--white">
                            <v-icon-film height="18px" width="18px" class="mr5"></v-icon-film>
                            <b>{{$t('views.Courses.Courses.list.lectures')}}:</b>&nbsp;&nbsp;{{course.lectures.length}}
                        </div>
                        <div class="flex mb10 flex--middle text--white" v-if="course.duration">
                            <v-icon-clock height="18px" width="18px" class="mr5"></v-icon-clock>
                            <b>{{ course.duration | duration }}</b>
                        </div>
                        <div class="pt10 flex">
                            <a class="btn mr15" href="#" v-if="course.certification">
                                {{$t('views.Courses.Finish.certificate')}}
                            </a>
                            <router-link class="btn" :to="{ name: 'courses', params: {lang: $i18n.locale}}">
                                {{$t('views.Courses.Finish.explore')}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import VueContentLoading from 'vue-content-loading';
import lectureCard from '../../components/Lectures/LectureCard.vue';


export default {
    data() {
        return {
            loading: true,
        };
    },
    watch: {
        course: {
            handler(course) {
                const progress = course.progress.length;
                let lectures = course.lectures.length;
                if (course.sections.length > 0) {
                    lectures = course.sections.map(section => section.lectures).flat().length;
                }
                if (progress < lectures) {
                    this.$router.push({
                        name: 'course',
                        params: {
                            stub: this.$route.params.stub,
                            lang: this.$i18n.locale
                        },
                    });
                }
            },
        },
    },
    computed: {
        ...mapState({
            course: state => state.courses.one,
        }),
    },
    beforeMount() {
        this.$matomo && this.$matomo.trackPageView();
        this.$store.dispatch('courses/fetchOne', this.$route.params.stub)
            .then((val) => {
                document.title = `${this.portalName} - ${val.name}`;
                this.loading = false;
            });
    },
};
</script>

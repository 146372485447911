<template>
    <section class="bg--cool-gray-light">
        <div class="container pt60 pb90 pt60:sm pb60:sm">
            <div class="row flex--center">
                <div class="col-md-8">
                    <h3 class="text-center text-section-title">
                        {{ $t('components._sections.Blog.title') }}
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.Blog.subtitle') }}
                    </h4>
                </div>
            </div>
            <div class="row pt40">
                <div class="col-md-4 mb15">
                        <router-link to="/SI/static/smucanje" class="box block">
                        <div class="quadric--full bg--image" :style="{'background-image': `url(${require('../../assets/images/blog1.jpg')})`}"></div>
                        <div class="pl15 pt15 pr15 pt20 pb20">
                            <!-- <p class="mb0 small">
                                11 February 2019
                            </p> -->
                            <h4 class="mb20">
                                {{ $t('components._sections.Blog.post.0.title') }}
                            </h4>
                            <p>
                                {{ $t('components._sections.Blog.post.0.description') }}
                            </p>
                            <p class="text-uppercase mt20 mb0">
                                {{ $t('components._sections.Blog.link') }}
                            </p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4 mb15">
                    <router-link to="/SI/static/prehrana" class="box block">
                        <div class="quadric--full bg--image" :style="{'background-image': `url(${require('../../assets/images/blog2.jpg')})`}"></div>
                        <div class="pl15 pt15 pr15 pt20 pb20">
                            <!-- <p class="mb0 small">
                                11 February 2019
                            </p> -->
                            <h4 class="mb20">
                                {{ $t('components._sections.Blog.post.1.title') }}
                            </h4>
                            <p>
                                {{ $t('components._sections.Blog.post.1.description') }} 
                            </p>
                            <p class="text-uppercase mt20 mb0">
                                {{ $t('components._sections.Blog.link') }}
                            </p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4 mb15">
                    <router-link to="/SI/static/tek" class="box block">
                        <div class="quadric--full bg--image" :style="{'background-image': `url(${require('../../assets/images/blog3.jpg')})`}"></div>
                        <div class="pl15 pt15 pr15 pt20 pb20">
                            <!-- <p class="mb0 small">
                                11 February 2019
                            </p> -->
                            <h4 class="mb20">
                                {{ $t('components._sections.Blog.post.2.title') }}
                            </h4>
                            <p>
                                {{ $t('components._sections.Blog.post.2.description') }} 
                            </p>
                            <p class="text-uppercase mt20 mb0">
                                {{ $t('components._sections.Blog.link') }}
                            </p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-blog',
};
</script>

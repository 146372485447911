<template>
    <div>
        <section class="bg--image ph"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover_small.jpg')})`}">
            <div class="container pt90 pt30:sm pb60 pb30:sm">
                <div class="row flex--center flex--middle pb20">
                    <div class="col-md-8">
                        <h2 class="text--white text-center">
                            {{$t('views.Partners.Partners.title')}}
                        </h2>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section>
            <div class="container pt40 pb20 pt20:sm pb0:sm">
                <div class="flex--right">
                    <div class="col-md-4 pl0:sm pr0:sm">
                        <div class="flex flex--between box"
                             style="border: solid 1px #eae9e9; width: 100%; border-radius: 200px;">
                            <input type="text" :placeholder="$t('views.Partners.Partners.search')" class="pl20"
                                   style="border: 0; width: calc(100% - 54px); border-radius: 200px;">
                            <button class="btn--round" style="min-width: inherit">
                                <v-icon-search></v-icon-search>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <section>
            <div class="container pb60 pt20 pb20:sm">
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-12 col-xs-6 flex--one pb20">
                                <div class="box pl15 pr15 pt15 pb15 height100 width100">
                                    <h6 class="text-left pb10">
                                        {{$t('views.Partners.Partners.categories')}}
                                    </h6>
                                    <div class="flex flex--column pl15">
                                        <a href="#" class="mb5 text--blue">> Technology</a>
                                        <a href="#" class="mb5">Bussines processes</a>
                                        <a href="#" class="mb5">Gold</a>
                                        <a href="#" class="mb5">Silver</a>
                                        <a href="#" class="mb5">Markets</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-xs-6 flex--one pb20">
                                <div class="box pl15 pr15 pt15 pb15 height100 width100">
                                    <h6 class="text-left pb10">{{$t('views.Partners.Partners.filters')}}</h6>
                                    <div class="flex flex--column pl15">
                                        <a href="#" class="mb5 text--blue">> Popular</a>
                                        <a href="#" class="mb5">Just relased</a>
                                        <a href="#" class="mb5">Popular</a>
                                        <a href="#" class="mb5">Free</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6 pb30">
                                <router-link :to="{name:'partner', params: {lang: $i18n.locale}}" class="box flex width100">
                                    <div class="col-md-6 pl0 pr0 flex--one">
                                        <div class="quadric bg--image height100 width100"
                                             :style="{'background-image': 'url('+ require('../../assets/images/placeholder.jpg') +')'}"></div>
                                    </div>
                                    <div class="col-md-6 flex--one">
                                        <div class="pt10 pb10">
                                            <h6>Company name</h6>
                                            <p class="pt5 small">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ...
                                            </p>
                                            <p class="small">
                                                <b>{{$t('views.Partners.Partners.list.video')}}: </b>11<br>
                                                <b>{{$t('views.Partners.Partners.list.authors')}}: </b>1
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>


                            <div class="col-md-6 pb30">
                                <router-link :to="{name:'partner', params: {lang: $i18n.locale}}" class="box flex width100">
                                    <div class="col-md-6 pl0 pr0 flex--one">
                                        <div class="quadric bg--image height100 width100"
                                             :style="{'background-image': 'url('+ require('../../assets/images/placeholder.jpg') +')'}"></div>
                                    </div>
                                    <div class="col-md-6 flex--one">
                                        <div class="pt10 pb10">
                                            <h6>Company name</h6>
                                            <p class="pt5 small">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ...                                            </p>
                                            <p class="small">
                                                <b>{{$t('views.Partners.Partners.list.video')}}: </b>11<br>
                                                <b>{{$t('views.Partners.Partners.list.authors')}}: </b>1
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>


                            <div class="col-md-6 pb30">
                                <router-link :to="{name:'partner', params: {lang: $i18n.locale}}" class="box flex width100">
                                    <div class="col-md-6 pl0 pr0 flex--one">
                                        <div class="quadric bg--image height100 width100"
                                             :style="{'background-image': 'url('+ require('../../assets/images/placeholder.jpg') +')'}"></div>
                                    </div>
                                    <div class="col-md-6 flex--one">
                                        <div class="pt10 pb10">
                                            <h6>Company name</h6>
                                            <p class="pt5 small">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ...
                                            </p>
                                            <p class="small">
                                                <b>{{$t('views.Partners.Partners.list.video')}}: </b>11<br>
                                                <b>{{$t('views.Partners.Partners.list.authors')}}: </b>1
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-md-6 pb30">
                                <router-link :to="{name:'partner', params: {lang: $i18n.locale}}" class="box flex width100">
                                    <div class="col-md-6 pl0 pr0 flex--one">
                                        <div class="quadric bg--image height100 width100"
                                             :style="{'background-image': 'url('+ require('../../assets/images/placeholder.jpg') +')'}"></div>
                                    </div>
                                    <div class="col-md-6 flex--one">
                                        <div class="pt10 pb10">
                                            <h6>Company name</h6>
                                            <p class="pt5 small">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ...
                                            </p>
                                            <p class="small">
                                                <b>{{$t('views.Partners.Partners.list.video')}}: </b>11<br>
                                                <b>{{$t('views.Partners.Partners.list.authors')}}: </b>1
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-md-6 pb30">
                                <router-link :to="{name:'partner', params: {lang: $i18n.locale}}" class="box flex width100">
                                    <div class="col-md-6 pl0 pr0 flex--one">
                                        <div class="quadric bg--image height100 width100"
                                            :style="{'background-image': 'url('+ require('../../assets/images/placeholder.jpg') +')'}"></div>
                                    </div>
                                    <div class="col-md-6 flex--one">
                                        <div class="pt10 pb10">
                                            <h6>Company name</h6>
                                            <p class="pt5 small">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ...
                                            </p>
                                            <p class="small">
                                                <b>{{$t('views.Partners.Partners.list.video')}}: </b>11<br>
                                                <b>{{$t('views.Partners.Partners.list.authors')}}: </b>1
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                        </div>
                        <div class="flex flex--center">
                            <button class="btn">{{$t('views.Partners.Partners.list.all')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section-newsletter />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SectionSubscription from '../../components/_sections/Subscription.vue';
import SectionNewsletter from '../../components/_sections/Newsletter.vue';

export default {
    components: {
        SectionNewsletter,
        SectionSubscription,
    },

    watch: {
        '$i18n.locale': {
            handler() {
                document.title = `${process.env.VUE_APP_SITE_NAME} - ${this.$t('views.Partners.Partners.title')}`;
            },
        },

        portalName: {
            handler(portalName) {
                document.title = `${portalName} - ${this.$t('views.Partners.Partners.title')}`;
            },
        },
    },

    computed: {
        ...mapState({
            portalName: state => state.other.portalName,
        }),
    },

    beforeMount() {
        document.title = `${process.env.VUE_APP_SITE_NAME} - ${this.$t('views.Partners.Partners.title')}`;
    },
};
</script>

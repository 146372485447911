export default {
    featured: [],

    list: {
        page: 1,
        limit: 9,
        category: null,
        search: '',
        data: [],
        total: 0,
    },

    all: [],

    one: {
        id: null,
        name: null,
        authors: [],
        courseIntro: null,
        description: null,
        lectures: [],
        image: {},
        lastPayment: null,
    },

    enrollPopup: false,

    waitlist: {
        popup: false,
        course: null,
        success: false,
    },
};

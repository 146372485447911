<template>
    <section class="bg--image" :style="{'background-image': 'url(' + require('../../assets/images/Map.png') + ')'}">
        <div class="container pt90 pb90 pt60:sm pb60:sm">
            <div class="row flex--center pb60">
                <div class="col-md-8">
                    <h3 class="text-center text-section-title">
                        {{$t('components._sections.Partners1.title')}}
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{$t('components._sections.Partners1.subtitle')}}
                    </h4>
                </div>
            </div>
            <div class="row bg--image">
                <div class="col-md-3 col-sm-6 mb30">
                    <div class="box overflow">
                        <img :src="require('../../assets/images/partner_01.jpg')">
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mb30">
                    <div class="box overflow">
                        <img :src="require('../../assets/images/partner_02.jpg')">
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mb30">
                    <div class="box overflow">
                        <img :src="require('../../assets/images/partner_03.jpg')">
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mb30">
                    <div class="box overflow">
                        <img :src="require('../../assets/images/partner_04.jpg')">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-partners-1',
};
</script>

<template>
    <div>
        <div style="overflow-x: auto;width: calc(100% - 65px)">
            <table style=" border-spacing: 0; border-bottom: solid 1px darkgrey;">
                <tbody>
                <tr>
                    <td
                        class="tab__head"
                        @click="selectTab(tab)"
                        v-for="(tab, i) in tabs"
                        :key="i"
                        :class="{'active': tab.name === value}"
                    >
                        {{ tab.name }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="pt20">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-tabs',

    props: {
        value: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            tabs: [],
        };
    },

    methods: {
        selectTab(selectedTab) {
            this.$emit('input', selectedTab.name);
        },
    },

    created() {
        this.tabs = this.$children;
    },
};
</script>

export default {
    saveLatest(state, payload) {
        state.latest = payload;
    },

    saveAll(state, data) {
        state.all = data;
    },

    saveListAttr(state, { field, payload }) {
        state.list[field] = payload;
    },

    saveUpcoming(state, events) {
        state.upcoming = events;
    },

    one(state, event) {
        state.one = event;
    },

    setEnrollPopup(state, value) {
        state.enrollPopup = value;
    },
};

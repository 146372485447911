<template>
    <footer class="footer pt60 pb10 pb40:sm">
        <div class="container">
            <div class="footer__content pt60 pb60 pt40:sm pb40:sm">
                <div class="row">
                    <div class="col-md-4 pb20:sm">
                        <h3 class=" pb10:sm">{{$t('components.Base.Footer.main.title')}}</h3>
                        <p class="">
                            {{$t('components.Base.Footer.main.description')}}
                        </p>
                    </div>

                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-4 pb20:sm">
                                <h4 class="pb15 pb10:sm">{{$t('components.Base.Footer.about.title')}}</h4>
                                <nav class="flex flex--column">
                                    <template v-for="(page, pid) of pages">
                                        <router-link :to="{name: 'static', params: {lang: $i18n.locale, stub:page.stub}}" v-if="page.showInMenu">{{page.title}}
                                        </router-link>
                                    </template>
                                </nav>
                            </div>
                            <div class="col-md-4 pb20:sm">
                                <h4 class="pb15 pb10:sm">{{$t('components.Base.Footer.help.title')}}</h4>
                                <nav class="flex flex--column ">
                                    <router-link to="#">{{$t('components.Base.Footer.help.links.0')}}</router-link>
                                    <router-link to="#">{{$t('components.Base.Footer.help.links.1')}}</router-link>
                                    <router-link to="#">{{$t('components.Base.Footer.help.links.2')}}</router-link>
                                </nav>
                            </div>
                            <div class="col-md-4">
                                <h4 class="pb15 pb10:sm">{{$t('components.Base.Footer.contact.title')}}</h4>
                                <a href="tel:123123123" target="_blank" class="flex pb10">
                                    <v-icon-phone height="23px" width="23px"/></v-icon-phone> 
                                    080 2646
                                </a>
                                <a href="mailto:info@efrend.si" target="_blank" class="flex ">
                                    <v-icon-mail height="23px" width="23px"/></v-icon-mail>
                                    info@efrend.si
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__copyright">
            <div class="container">
                <div class="footer__copyright-content">
                    <div class="flex flex--between pt40">
                        <div class="small " v-html="$t('components.Base.Footer.copyright')">
                        </div>
                        <div class="flex flex--middle">
                            <a href="https://www.facebook.com/eFrend.si/" target="_blank" class="ml5 mr5">
                                <v-icon-facebook></v-icon-facebook>
                            </a>
                            <a href="#" target="_blank" class="ml5 mr5">
                                <v-icon-instagram></v-icon-instagram>
                            </a>
                            <a href="https://www.linkedin.com/company/efrend" target="_blank" class="ml5 mr5">
                                <v-icon-linkedin></v-icon-linkedin>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            pages: state => state.other.pages,
        }),
    },
};
</script>

/* eslint-disable global-require */
import './assets/styles/main.scss';

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueI18n from 'vue-i18n';
import VueWow from 'vue-wow';
import VueAnalytics from 'vue-analytics';
import VueFacebookPixel from 'vue-analytics-fb';
import SocialSharing from 'vue-social-sharing';
import VueMatomo from 'vue-matomo';


// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import App from './App.vue';
import router from './router';
import store from './store/store';

import VTab from './components/Shared/Tab.vue';
import VTabs from './components/Shared/Tabs.vue';
import VTabsColumns from './components/Shared/Tabs-columns.vue';
import VModal from './components/Shared/Modal.vue';
import VVideo from './components/Shared/Video.vue';
import VAccordion from './components/Shared/Accordion.vue';

// Sentry.init({
//     dsn: 'https://386fc74f99b942999a15dc719d6acc4c@sentry.ilol.si/103',
//     integrations: [new Integrations.Vue({
//         Vue,
//         attachProps: true,
//     })],
// });

require('./filters/filters');

Vue.config.productionTip = false;

Vue.component('v-tab', VTab);
Vue.component('v-tabs', VTabs);
Vue.component('v-tabs-columns', VTabsColumns);
Vue.component('v-video', VVideo);
Vue.component('v-modal', VModal);
Vue.component('v-accordion', VAccordion);
Vue.use(Vuelidate);
Vue.use(VueI18n);
Vue.use(VueWow);
Vue.use(VueFacebookPixel);
Vue.use(SocialSharing);

if (process.env.VUE_APP_MATOMO_HOST) {
    Vue.use(VueMatomo, {
        host: process.env.VUE_APP_MATOMO_HOST,
        siteId: process.env.VUE_APP_MATOMO_SITE_ID,
        trackerFileName: 'matomo',
        trackerUrl: `${process.env.VUE_APP_MATOMO_HOST}matomo.php`,
        requireConsent: true,
        router,
    });
}

if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
    Vue.use(VueAnalytics, {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    });
}


// load all icons as component
const req = require.context('./assets/images/icons/', true, /\.(svg)$/i);
req.keys().map((key) => {
    const name = `v-icon-${key.match(/\w+/)[0]}`;
    return Vue.component(name, req(key).default);
});

const langFiles = require.context('./lang/', false, /\.(json)$/i);
const langs = {};
langFiles.keys().map((key) => {
    const name = `${key.match(/\w+/)[0]}`;
    langs[name] = require(`./lang/${name}.json`);
});

const i18n = new VueI18n({
    locale: 'SI',
    messages: langs,
});


new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');

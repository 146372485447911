<template>
    <div>
        <!-- <section-author-header :image="course.image ? course.image.uri : '../../assets/images/bg_cover.jpg)'" /> -->

        <section
                class="bg--image ph"
                :style="{ 'background-image': `linear-gradient(rgba(0,0,0,.65),rgba(0,0,0,.65)), url(${course.image ? course.image.uri : '../../assets/images/bg_cover.jpg)'}`}">
            <div class="container pt90 pt30:sm pb150 pb30:sm">
                <div class="row flex--center flex--middle">
                    <div class="col-md-8">
                        <vue-content-loading v-if="loading.one" :width="100" :height="8">
                            <rect x="0" y="0" width="100" height="8"/>
                        </vue-content-loading>
                        <h2 class="text--white text-center" v-else>{{ course.name }}</h2>
                        <vue-content-loading v-if="loading.one" :width="100" :height="3" class="pt10">
                            <rect x="0" y="0" width="100" height="3"/>
                        </vue-content-loading>
                        <p class="h5 text-normal text--white text-center pt10" v-else>
                            {{ course.authors.map((author)=> (author.name + " " + author.surname)).join(", ") }}
                        </p>
                    </div>
                </div>
            </div>
        </section>



        <!-- <section class="pt60 pb60 bg--cool-gray-light">
            <div class="features">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-4 features__item">
                            <div class="features__icon">
                                <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5fa408bac7b5ba756a1da2eb_0223-medal-first.png" alt="">
                            </div>
                            <h5 class="features__title">PREDNOST 1</h5>
                            <p>
                                Na začetku Reset poti prisluhnemo vašim željam, pričakovanjem in zgradimo močno vez medsebojnega zaupanja.
                            </p>
                        </div>
                        <div class="col-sm-4 features__item">
                            <div class="features__icon">
                                <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5fa408bab331b3cdbc92393f_0224-medal-second.png" alt="">
                            </div>
                            <h5 class="features__title">PREDNOST 2</h5>
                            <p>
                                Na podlagi telesnih meritev in analiz sestavimo učinkovit Reset vadbeni program, ki je namenjen izključno vam.
                            </p>
                        </div>
                        <div class="col-sm-4 features__item">
                            <div class="features__icon">
                                <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5fa408baeb48dfc5006910a8_0225-medal-third.png" alt="">
                            </div>
                            <h5 class="features__title">PREDNOST 3</h5>
                            <p>
                                Na začetku Reset poti prisluhnemo vašim željam, pričakovanjem in zgradimo močno vez medsebojnega zaupanja.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <!-- <section class="bg--image ph" :style="{ 'background-image': `linear-gradient(rgba(0,0,0,.65),rgba(0,0,0,.65)), url(${course.image ? course.image.uri : '../../assets/images/bg_cover.jpg)'}`}">
            <div class="container pt120 pt30:sm pb120 pb30:sm">
                <div class="row flex--center flex--middle">
                    <div class="col-md-8">
                        <vue-content-loading v-if="loading.one" :width="100" :height="8">
                            <rect x="0" y="0" width="100" height="8"/>
                        </vue-content-loading>
                        <h2 class="text--white text-center" v-else>{{ course.name }}</h2>
                        <vue-content-loading v-if="loading.one" :width="100" :height="3" class="pt10">
                            <rect x="0" y="0" width="100" height="3"/>
                        </vue-content-loading>
                        <p class="h5 text-normal text--white text-center pt10" v-else>
                            {{ course.authors.map((author)=> (author.name + " " + author.surname)).join(", ") }}
                        </p>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="bg--bg">
            <div class="container pt60 pb60 pt20:sm pb20:sm">
                <div class="row">
                    <div class="col-md-4 show-block:sm">
                        <div class="box pt30 pr30 pb30 pl30 mb30">
                            <template v-if="!enrolled">
                                <vue-content-loading class="mt15" v-if="loading.one" :width="100" :height="15">
                                    <rect x="0" y="0" width="100" height="15"/>
                                </vue-content-loading>
                                <h3 class="text-center pb10" v-else>
                                    {{course.priceCourse ? (course.priceCourse + "€") :
                                    $t('views.Courses.Course.free')}}
                                    <!--<span class="h5" style="text-decoration: line-through;color: #a4a4a4">0€</span>-->
                                </h3>
                                <template v-if="$store.state.auth.token">
                                    <button @click="enroll" class="btn btn--full"
                                            v-if="course.free || !course.priceCourse">
                                        {{$t('views.Courses.Course.apply')}}
                                    </button>
                                    <template v-else-if="course.lastPayment && course.lastPayment.status == 'processing'">
                                        <div class="info-box info-box--medium text-center">
                                            <div>
                                                Plačilo je v čakalni vrsti
                                            </div>

                                            <button class="btn btn--small mt20" @click="reloadCourse()">Osveži</button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <!-- <button @click="enroll" class="btn btn--full" v-if="!inCart">
                                            {{$t('views.Courses.Course.addToCart')}}
                                        </button>
                                        <button @click="$router.push({name: 'cart', params: {lang: $i18n.locale}})" class="btn btn--full"
                                                v-else>
                                            {{$t('views.Courses.Course.inCart')}}
                                        </button> -->
                                    </template>
                                </template>
                                <template v-else>
                                    <button @click="enroll" class="btn btn--full"
                                            v-if="course.free || !course.priceCourse">
                                        {{$t('views.Auth.Login.title')}}
                                    </button>
                                </template>
                            </template>
                            <template v-else>
                                <h3 class="text-center pb10">
                                    {{$t('views.Courses.Course.applied')}}
                                </h3>
                                <p class="text-center">{{enrolledCourse && enrolledCourse.created | date}}</p>
                            </template>
                            <button @click="buyNow()" class="btn btn--full mt10"
                                    v-if="!enrolled && !(course.free || !course.priceCourse) && (!course.lastPayment || course.lastPayment.status != 'processing')">
                                {{$t('views.Courses.Course.buyNow')}}
                            </button>
                            <p class="text-center errortext" v-if="enrollError">{{enrollError}}</p>
                            <p v-if="enrollSuccess">{{enrollSuccess}}</p>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="" style="width: 100%;">
                            <div class="box pl30 pr30 pt30 pb30 mb30">
                                <div class="video__iframe mb30" v-if="course.courseIntro">
                                    <vue-content-loading v-if="loading.one" :width="100" :height="68">
                                        <rect x="0" y="0" width="100" height="68"/>
                                    </vue-content-loading>
                                    <template v-else>
                                        <iframe
                                                id="ytplayer"
                                                type="text/html"
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allowfullscreen
                                                :src="`https://www.youtube.com/embed/${youtubeId(course.courseIntro)}`"
                                        ></iframe>
                                    </template>
                                </div>
                                <vue-content-loading v-if="loading.one" :width="100" :height="20">
                                    <rect x="0" y="0" width="100" height="20"/>
                                </vue-content-loading>
                                <template v-else>
                                    <p class="hide:sm" v-html="course.description" v-if="course.description"></p>
                                    <p class="show-block:sm" v-html="course.abstract" v-if="course.abstract"></p>
                                    <template v-if="course.coursePrerequisites">
                                        <p><b>{{ $t('views.Courses.Course.tabs.prerequisites') }}</b></p>
                                        <p v-html="course.coursePrerequisites"></p>
                                    </template>
                                </template>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="box pt30 pr30 pb30 pl30 mb30">
                            <template v-if="!enrolled">
                                <vue-content-loading class="mt15" v-if="loading.one" :width="100" :height="15">
                                    <rect x="0" y="0" width="100" height="15"/>
                                </vue-content-loading>
                                <h3 class="text-center pb10" v-else>
                                    {{course.priceCourse ? (course.priceCourse + "€") :
                                    $t('views.Courses.Course.free')}}
                                    <!--<span class="h5" style="text-decoration: line-through;color: #a4a4a4">0€</span>-->
                                </h3>
                                <template v-if="$store.state.auth.token">
                                    <button @click="enroll" class="btn btn--full"
                                            v-if="course.free || !course.priceCourse">
                                        {{$t('views.Courses.Course.apply')}}
                                    </button>
                                    <template v-else-if="course.lastPayment && course.lastPayment.status == 'processing'">
                                        <div class="info-box info-box--medium text-center">
                                            <div>
                                                Plačilo je v čakalni vrsti
                                            </div>

                                            <button class="btn btn--small mt20" @click="reloadCourse()">Osveži</button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <!-- <button @click="enroll" class="btn btn--full" v-if="!inCart">
                                            {{$t('views.Courses.Course.addToCart')}}
                                        </button>
                                        <button @click="$router.push({name: 'cart', params: {lang: $i18n.locale}})" class="btn btn--full"
                                                v-else>
                                            {{$t('views.Courses.Course.inCart')}}
                                        </button> -->
                                    </template>
                                </template>
                                <template v-else>
                                    <button @click="enroll" class="btn btn--full"
                                            v-if="course.free || !course.priceCourse">
                                        {{$t('views.Auth.Login.title')}}
                                    </button>
                                </template>
                            </template>
                            <template v-else>
                                <h3 class="text-center pb10">
                                    {{$t('views.Courses.Course.applied')}}
                                </h3>
                                <p class="text-center">{{enrolledCourse && enrolledCourse.created | date}}</p>
                            </template>
                            <button @click="buyNow()" class="btn btn--full mt10"
                                    v-if="!enrolled && !(course.free || !course.priceCourse) && (!course.lastPayment || course.lastPayment.status != 'processing')">
                                {{$t('views.Courses.Course.buyNow')}}
                            </button>
                            <p class="text-center errortext" v-if="enrollError">{{enrollError}}</p>
                            <p v-if="enrollSuccess">{{enrollSuccess}}</p>
                        </div>
                        <div class="box pl30 pr30 pt30 pb30 mb20">
                            <h4 class="pb10">{{$t('views.Courses.Course.course_features')}}</h4>
                            <vue-content-loading v-if="loading.one" :width="100" :height="33">
                                <rect x="0" y="0" width="100" height="33"/>
                            </vue-content-loading>
                            <div v-html="course.courseFeatures" v-else></div>
                        </div>
                        <!-- <div class="box pl30 pr30 pt30 pb30 mb20">
                            <h4 class="pb10">{{$t('views.Courses.Course.featured_courses')}}</h4>
                            <template v-if="loading.featured">
                                <vue-content-loading :width="100" :height="40">
                                    <rect x="0" y="0" width="100" height="40"/>
                                </vue-content-loading>
                                <vue-content-loading class="mt15" :width="100" :height="40">
                                    <rect x="0" y="0" width="100" height="40"/>
                                </vue-content-loading>
                                <vue-content-loading class="mt15" :width="100" :height="40">
                                    <rect x="0" y="0" width="100" height="40"/>
                                </vue-content-loading>
                            </template>
                            <template v-else>
                                <router-link class="mb10 block" :to="{ name: 'course', params: { stub: feature.stub, lang: $i18n.locale } }"
                                             v-for="(feature, fid) of featured" :key="fid">
                                    <div class="row pb10">
                                        <div class="col-xs-5">
                                            <div class="quadric bg--image"
                                                 :style="{ 'background-image': 'url(' + (feature.image ? feature.image.uri : '') + ')' }"></div>
                                        </div>
                                        <div class="col-xs-7">
                                            <h6>{{ feature.name }}</h6>
                                            <p>{{feature.priceCourse ? (feature.priceCourse + "€") :
                                                $t('components.Courses.CourseCard.free') }}</p>
                                        </div>
                                    </div>
                                    <hr>
                                </router-link>
                            </template>
                        </div> -->
                        <div class="box pl30 pr30 pt30 pb30 mb20">
                            <social-sharing url="https://vuejs.org/" inline-template>
                                <div class="flex">
                                    <network network="facebook">
                                        <div class="mr5 ml5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                 stroke-linecap="round" stroke-linejoin="round"
                                                 class="feather feather-facebook">
                                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                            </svg>
                                        </div>
                                    </network>
                                    <network network="linkedin">
                                        <div class="mr5 ml5">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                 stroke-linecap="round" stroke-linejoin="round"
                                                 class="feather feather-linkedin">
                                                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                                <rect x="2" y="9" width="4" height="12"></rect>
                                                <circle cx="4" cy="4" r="2"></circle>
                                            </svg>
                                        </div>
                                    </network>
                                    <network network="twitter">
                                        <div class="mr5 ml5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                 stroke-linecap="round" stroke-linejoin="round"
                                                 class="feather feather-twitter">
                                                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                            </svg>
                                        </div>
                                    </network>
                                </div>
                            </social-sharing>
                        </div>
                    </div>

                    <div class="col-md-12 mt40">
                        <div class="relative">
                            <div class="absolute absolute--top absolute--right pt10"
                                 v-if="tabs===$t('views.Courses.Course.tabs.curiculum')">
                                <div class="flex flex--right hide:sm">
                                    <div @click="grid=true" class="cursor">
                                        <v-icon-grid class="mr10" :class="{'text--blue': grid}">

                                        </v-icon-grid>
                                    </div>
                                    <div @click="grid=false" class="cursor">
                                        <v-icon-list :class="{'text--blue': !grid}"></v-icon-list>
                                    </div>
                                </div>
                            </div>
                            <v-tabs v-model="tabs">
                                <v-tab :name="$t('views.Courses.Course.tabs.curiculum')" :selected="tabs">
                                    <div class="row" v-if="grid">
                                        <template v-if="loading.one">
                                            <div class="col-md-4 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-4 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-4 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <template v-if="course.sections.length > 0">
                                                <template v-for="section in course.sections">
                                                    <div class="col-md-12 pb15 pt30">
                                                        <h4>{{section.name}}</h4>
                                                    </div>
                                                    <div class="col-md-4 flex--one mb30"
                                                         v-for="(lecture, lid) in section.lectures"
                                                         :key="`${section.id}${lid}`">
                                                        <lecture-card :lecture="lecture" :grid="grid" type="courses"
                                                                      :tstub="$route.params.stub"
                                                                      class="height100 width100 block relative"></lecture-card>
                                                    </div>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <div class="col-md-4 flex--one mb30"
                                                     v-for="(lecture, lid) in course.lectures"
                                                     :key="lid">
                                                    <lecture-card :lecture="lecture" :grid="grid" type="courses"
                                                                  :tstub="$route.params.stub"
                                                                  class="height100 width100 block relative"></lecture-card>
                                                </div>
                                            </template>


                                        </template>

                                    </div>
                                    <div class="row" v-else>
                                        <template v-if="loading.one">
                                            <div class="col-md-12 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-12 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                            <div class="col-md-12 mb20">
                                                <vue-content-loading :width="100" :height="120">
                                                    <rect x="0" y="0" width="100" height="120"/>
                                                </vue-content-loading>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <template v-if="course.sections.length > 0">
                                                <template v-for="section in course.sections">
                                                    <div class="col-md-12 pb15 pt30">
                                                        <h4>{{section.name}}</h4>
                                                    </div>
                                                    <div class="col-md-12 mb20" v-for="(lecture, lid) in section.lectures"
                                                         :key="`${section.id}${lid}`">
                                                        <lecture-card :lecture="lecture" :grid="grid" type="courses"
                                                                      :tstub="$route.params.stub"></lecture-card>
                                                    </div>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <div class="col-md-12 mb20" v-for="(lecture, lid) in course.lectures"
                                                     :key="lid">
                                                    <lecture-card :lecture="lecture" :grid="grid" type="courses"
                                                                  :tstub="$route.params.stub"></lecture-card>
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </v-tab>
                                <v-tab :name="$t('views.Courses.Course.tabs.courseSyllabus')" :selected="tabs"
                                       v-if="course.courseSyllabus">
                                    <template v-if="loading.one">
                                        <vue-content-loading :width="100" :height="33">
                                            <rect x="0" y="0" width="100" height="33"/>
                                        </vue-content-loading>
                                        <vue-content-loading class="mt15" :width="100" :height="33">
                                            <rect x="0" y="0" width="100" height="33"/>
                                        </vue-content-loading>
                                    </template>

                                    <div v-html="course.courseSyllabus" v-else></div>
                                </v-tab>
                                <v-tab :name="$t('views.Courses.Course.tabs.courseMaterials')" :selected="tabs"
                                       v-if="course.courseMaterials">
                                    <template v-if="loading.one">
                                        <vue-content-loading :width="100" :height="33">
                                            <rect x="0" y="0" width="100" height="33"/>
                                        </vue-content-loading>
                                        <vue-content-loading class="mt15" :width="100" :height="33">
                                            <rect x="0" y="0" width="100" height="33"/>
                                        </vue-content-loading>
                                    </template>
                                    <div v-html="course.courseMaterials" v-else></div>
                                </v-tab>
                                <v-tab :name="$t('views.Courses.Course.tabs.courseFaq')" :selected="tabs"
                                       v-if="course.courseFaq">
                                    <template v-if="loading.one">
                                        <vue-content-loading :width="100" :height="33">
                                            <rect x="0" y="0" width="100" height="33"/>
                                        </vue-content-loading>
                                        <vue-content-loading class="mt15" :width="100" :height="33">
                                            <rect x="0" y="0" width="100" height="33"/>
                                        </vue-content-loading>
                                    </template>
                                    <div v-html="course.courseFaq" v-else></div>
                                </v-tab>
                                <v-tab :name="$t('views.Courses.Course.tabs.courseNews')" :selected="tabs"
                                       v-if="course.courseNews">
                                    <template v-if="loading.one">
                                        <vue-content-loading :width="100" :height="33">
                                            <rect x="0" y="0" width="100" height="33"/>
                                        </vue-content-loading>
                                        <vue-content-loading class="mt15" :width="100" :height="33">
                                            <rect x="0" y="0" width="100" height="33"/>
                                        </vue-content-loading>
                                    </template>
                                    <div v-html="course.courseNews" v-else></div>
                                </v-tab>
                            </v-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         <section-newsletter />

        <!-- <section-pricing class="bg--cool-gray-light pt90 pb90 pt60:sm pb60:sm" /> -->

        <!-- <section-video-interview class="pt90 pb90 pt60:sm pb60:sm" /> -->

        <v-modal :open="popup" @close="popup=false">
            <template v-if="!enrolled">
                <vue-content-loading class="mt15" v-if="loading.one" :width="100" :height="15">
                    <rect x="0" y="0" width="100" height="15"/>
                </vue-content-loading>
                <h3 class="text-center pb10" v-else>
                    {{course.priceCourse ? (course.priceCourse + "€") :
                    $t('views.Courses.Course.free')}}
                    <!--<span class="h5" style="text-decoration: line-through;color: #a4a4a4">0€</span>-->
                </h3>
                <button @click="enroll" class="btn btn--full"
                        v-if="course.free || !course.priceCourse">
                    {{$t('views.Courses.Course.apply')}}
                </button>
                <template v-else>
                    <button @click="enroll" class="btn btn--full" v-if="!inCart">
                        {{$t('views.Courses.Course.addToCart')}}
                    </button>
                    <button @click="$router.push({name: 'cart', params: {lang: $i18n.locale}})" class="btn btn--full"
                            v-else>
                        {{$t('views.Courses.Course.inCart')}}
                    </button>
                </template>
            </template>
            <template v-else>
                <h3 class="text-center pb10">
                    {{$t('views.Courses.Course.applied')}}
                </h3>
                <p class="text-center">{{enrolledCourse && enrolledCourse.created | date}}</p>
            </template>
            <button @click="buyNow()" class="btn btn--full mt10"
                    v-if="!enrolled && !(course.free || !course.priceCourse)">
                {{$t('views.Courses.Course.buyNow')}}
            </button>
            <p class="text-center errortext">{{enrollError}}</p>
            <p>{{enrollSuccess}}</p>
        </v-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import VueContentLoading from 'vue-content-loading';
import lectureCard from '../../components/Lectures/LectureCard.vue';
import SectionAuthorHeader from '../../components/_sections/Author_Header.vue';
import SectionAuthor from '../../components/_sections/Author.vue';
import SectionPartners1 from '../../components/_sections/Partners1.vue';
import SectionAbout2 from '../../components/_sections/About2.vue';
import SectionCustomers from '../../components/_sections/Customers.vue';
import SectionFaq4 from '../../components/_sections/Faq4.vue';
import SectionPricing from '../../components/_sections/Pricing.vue';
import SectionVideoInterview from '../../components/_sections/VideoInterview.vue';
import SectionNewsletter from '../../components/_sections/Newsletter.vue';


export default {
    components: {
        lectureCard,
        VueContentLoading,
        SectionAuthorHeader,
        SectionAuthor,
        SectionPartners1,
        SectionAbout2,
        SectionCustomers,
        SectionFaq4,
        SectionPricing,
        SectionVideoInterview,
        SectionNewsletter
    },

    data() {
        return {
            grid: true,
            tabs: this.$t('views.Courses.Course.tabs.curiculum'),
            enrollError: '',
            enrollSuccess: '',
            loading: {
                one: true,
                featured: true,
            },
        };
    },

    watch: {
        '$route.params.stub': {
            handler(stub) {
                this.loading.one = true;
                this.$store.dispatch('courses/fetchOne', stub).then((val) => {
                    document.title = `${this.portalName} - ${val.name}`;
                    this.loading.one = false;
                });
            },
        },

        '$i18n.locale': {
            handler() {
                this.loading = {
                    one: true,
                    featured: true,
                };
                this.tabs = this.$t('views.Courses.Course.tabs.curiculum');
                this.$store.dispatch('courses/fetchOne', this.$route.params.stub)
                    .then((val) => {
                        document.title = `${this.portalName} - ${val.name}`;
                        this.loading.one = false;
                    });
                this.$store.dispatch('courses/fetchFeatured')
                    .then(() => {
                        this.loading.featured = false;
                    });
            },
        },

        portalName: {
            handler(portalName) {
                if (this.course && this.course.name) {
                    document.title = `${portalName} - ${this.course.name}`;
                } else {
                    document.title = `${portalName}`;
                }
            },
        },

        tabs: {
            handler(tab){
                this.$matomo && this.$matomo.trackEvent('course', 'tab change');
            }
        }
    },

    computed: {
        ...mapState({
            course: state => state.courses.one,
            auth: state => state.auth.token !== null,
            enrolledCourse(state) {
                return state.auth.enrolledCourses && state.auth.enrolledCourses.find(el => el.course.stub === this.$route.params.stub);
            },
            inCart(state) {
                return !!state.shop.cart.find(el => el.stub === this.$route.params.stub);
            },
            featured: state => state.courses.featured,
            portalName: state => state.other.portalName,
        }),

        popup: {
            get() {
                return this.$store.state.courses.enrollPopup;
            },
            set(value) {
                this.$store.dispatch('courses/enrollPopup', value);
            },
        },

        enrolled() {
            return !!this.enrolledCourse;
        },
    },

    methods: {
        youtubeId(link) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = link.match(regExp);
            if (match && match[2].length === 11) {
                return match[2];
            }
            return 'error';
        },

        enroll() {
            const self = this;
            this.enrollError = '';
            this.enrollSuccess = '';
            if (this.auth) {
                if (!this.enrolled) {
                    if (this.course.free || !this.course.priceCourse) {
                        this.$store.dispatch('courses/courseEnroll', this.course.id)
                            .then((res) => {
                                self.$store.dispatch('auth/tokenUser');
                                self.enrollSuccess = self.$t('views.Courses.Course.enroll_success');
                                this.$matomo && this.$matomo.trackEvent('course', 'enrolled');
                            })
                            .catch((err) => {
                                console.log(err);
                                self.enrollError = err;
                                this.$matomo && this.$matomo.trackEvent('course', 'enroll failed');
                            });
                    } else {
                        this.$store.dispatch('shop/addToCart', this.course);
                        this.$analytics.fbq.event('AddToCart');
                    }
                }
            } else {
                window.location = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
                // this.$store.dispatch('other/popup', true);
            }
        },

        buyNow() {
            this.enroll();

            if (this.auth) {
                this.$router.push({ name: 'cart', params: {lang: this.$i18n.locale}});
            }
        },

        reloadCourse() {
            this.loading.one = true;
            this.$store.dispatch('courses/fetchOne', this.$route.params.stub).then(() => {
                this.loading.one = false;
            });
        }
    },

    beforeMount() {
        this.$matomo && this.$matomo.trackPageView();
        this.$store.dispatch('courses/fetchOne', this.$route.params.stub)
            .then((val) => {
                document.title = `${this.portalName} - ${val.name}`;
                this.loading.one = false;
            });

        this.$store.dispatch('courses/fetchFeatured')
            .then(() => {
                this.loading.featured = false;
            });
    },
};
</script>

export default {
    selectedQuality: 'q720p',
    one: {
        id: null,
        name: null,
        filmed: null,
        stub: null,
        description: null,
        courses: [],
        video: {
            duration: null,
            image: null,
            slides: [],
            chapters: [],
            sources: [],
        },
        liveStream: {
            startedAt: null,
            finishedAt: null,
            sources: [],
        },
    },

    player: {
        video: null,
        fullscreen: false,
        play: false,
        layout: 1,
        switchMode: false,
        player: null,
        time: 0,
        duration: 10,
        volume: 1,
        disabled: false,
        slideId: 0,
        chapterId: 0,
        slidePrev: false,
        slideNext: true,
        showSlides: false,
        available: true,
    },
};

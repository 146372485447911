<template>
    <div>
        <form @submit.prevent="submit()" novalidate>
            <input type="text" :placeholder="$t('views.Auth.Login.form.email.placeholder')"
                   v-model="email.value"
                   @blur="$v.email.value.$touch()"
                   :class="{'error': $v.email.value.$invalid && $v.email.value.$dirty}"
                   class="mb10"
                   autocomplete="email"
                   required>
            <p v-if="!$v.email.value.required && $v.email.value.$dirty" class="errortext">{{
                $t('views.Auth.Login.form.email.required')}}</p>
            <p v-if="!$v.email.value.email && $v.email.value.$dirty" class="errortext">{{
                $t('views.Auth.Login.form.email.email')}}</p>
            <div class="relative mb10">
                <input style="padding-right: 40px" @blur="$v.password.value.$touch()"
                       :type="password.visible?'text':'password'"
                       :placeholder="$t('views.Auth.Login.form.password.placeholder')"
                       v-model="password.value"
                       :class="{'error': $v.password.value.$invalid && $v.password.value.$dirty}"
                       autocomplete="current-password"
                       class="mb10" required>
                <div class="absolute absolute--right absolute--top absolute--bottom">
                    <div class="flex flex--middle height100 pr10">
                        <div @click="password.visible=(!password.visible)" class="cursor">
                            <div>
                                <v-icon-eye v-if="password.visible"></v-icon-eye>
                                <v-icon-eyeOff v-else></v-icon-eyeOff>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p v-if="!$v.password.value.required && $v.password.value.$dirty" class="errortext">{{
                $t('views.Auth.Login.form.password.required')}}</p>
            <div class="loading-indicator" v-if="sending"></div>
            <button class="btn btn--full" :disabled="$v.$invalid" v-else>{{
                $t('views.Auth.Login.form.button')}}
            </button>
            <div class="error-box flex flex--middle mt10" v-if="error">
                <v-icon-xCircle class="mr10" />
                <div style="width: calc(100% - 50px)">
                {{error}}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: 'form-auth-login',

    data() {
        return {
            sending: false,
            email: {
                value: '',
            },
            password: {
                value: '',
                visible: false,
            },
            error: '',
        };
    },

    validations: {
        email: {
            value: {
                required,
                email,
            },
        },

        password: {
            value: {
                required,
            },
        },
    },

    methods: {
        submit() {
            this.error = '';
            this.sending = true;
            const self = this;
            this.$store.dispatch('auth/login', {
                email: this.email.value,
                password: this.password.value,
            })
                .then((res) => {
                    self.$store.dispatch('auth/enrolledCourses');
                    self.$store.dispatch('auth/enrolledEvents');
                    if (self.$store.state.other.fbPixel) {
                        self.$analytics.fbq.init(self.$store.state.other.fbPixel, {
                            em: res.account.email,
                        });
                    }
                    self.sending = false;
                    self.$emit('success');
                    self.email.value = '';
                    self.password.value = '';
                    self.$v.$reset();

                    if(self.$store.state.auth.loginCallback) {
                        self.$store.state.auth.loginCallback();
                        self.$store.commit('auth/loginCallback', null);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    self.sending = false;
                    self.error = this.$t(`components.Forms.auth.Login.${err}`);
                });
        },
    },
};
</script>

<template>
    <section :class="{'bg--gradient': dark, 'bg--bg': !dark}">
        <div class="container pt15 pb15">

            <h6 :class="{'text--white': dark}">
                {{$t('components._sections.Partners2.title')}}
            </h6>

            <div class="row bg--image pt10">
                <div class="col-md mb15 col-xs-6 col-sm-4">
                    <div class="box overflow">
                        <img :src="require('../../assets/images/partner_01.jpg')">
                    </div>
                </div>
                <div class="col-md mb15 col-xs-6 col-sm-4">
                    <div class="box overflow">
                        <img :src="require('../../assets/images/partner_02.jpg')">
                    </div>
                </div>
                <div class="col-md mb15 col-xs-6 col-sm-4">
                    <div class="box overflow">
                        <img :src="require('../../assets/images/partner_03.jpg')">
                    </div>
                </div>
                <div class="col-md mb15 col-xs-6 col-sm-4">
                    <div class="box overflow">
                        <img :src="require('../../assets/images/partner_04.jpg')">
                    </div>
                </div>
                <div class="col-md mb15 col-xs-6 col-sm-4">
                    <div class="box overflow">
                        <img :src="require('../../assets/images/partner_05.jpg')">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-partners-2',

    props: {
        dark: {
            type: Boolean,
            default: false,
        },
    },
};
</script>


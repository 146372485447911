<template>
    <section class="s-intructors" :class="'s-instructors--' + version">
        <template v-if="version === 'default'">
            <div class="container pt120 pb120 pt60:sm pb60:sm">
                <div class="row flex--center pb60">
                    <div class="col-md-6">
                        <h3 class="text-center text-section-title wow slideInUp" v-html="$t('components._sections.Instructors1.title')">
                        </h3>
                        <h4 class="text-center text-section-subtitle wow slideInUp">
                            {{ $t('components._sections.Instructors1.subtitle') }}
                        </h4>
                    </div>
                </div>
                <div class="row flex--center">
                    <div class="col-md-10">
                        <carousel class="owl-dark" :loop="true" v-if="!loading"
                                  :responsive="{0:{nav:false,dots:true,items:1},992:{nav:true,dots:true,items:1}}">
                            <div v-for="(featureAuthor, fid) of featureAuthors" :key="fid"
                                 class="pl15 pr15 pb30">
                                <div class="row flex--center flex--middle">
                                    <div class="col-md-3">
                                        <div class="quadric--full bg--image quadric--round" :style="{'background-image': `url(${featureAuthor.image && featureAuthor.image.uri})`}"></div>
                                    </div>

                                    <div class="col-md-7">
                                        <h4 class="mb15">{{featureAuthor.name}} {{featureAuthor.surname}}</h4>
                                        <div class="small mb15" v-html="(featureAuthor.abstract ? featureAuthor.abstract.substring(0,150) : '')"></div>
                                        <div class="flex flex--middle">
                                            <v-icon-award height="18px" width="18px"></v-icon-award>
                                            <p class="mb0 ml5">
                                                <b>{{ $t('views.Authors.AuthorList.list.courses')}}: </b>{{ featureAuthor.courses.length }}<br>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </carousel>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="version === 'half-half'">
            <div class="container pt90 pb90 pt60:sm pb60:sm">
                <!-- <div class="row flex--center pt30 pb60">
                    <div class="col-md-8">
                        <h3 class="text-center text-section-title wow slideInUp">
                            {{ $t('components._sections.Instructors1.title') }}
                        </h3>
                        <h4 class="text-center text-section-subtitle wow slideInUp">
                            {{ $t('components._sections.Instructors1.subtitle') }}
                        </h4>
                    </div>
                </div> -->
                <div class="row flex--center pt30">
                    <div class="col-md-10">
                        <div class="s-instructors__carousel-wrapper pr30 pb30 pl30">
                            <carousel class="s-instructors__carousel" :loop="true" v-if="!loading"
                                      :responsive="{0:{nav:false,dots:true,items:1},992:{nav:true,dots:true,items:1}}">
                                <div v-for="(featureAuthor, fid) of featureAuthors" :key="fid"
                                     class="pl15 pr15">

                                    <div class="text-center mt90 mb60">
                                        <h3>
                                            {{featureAuthor.name}} {{featureAuthor.surname}}
                                        </h3>
                                    </div>
                                    <div class="row flex--center">
                                        <div class="col-sm-6 mb30:sm">
                                            <div class="quadric--full bg--image" :style="{'background-image': `url(${featureAuthor.image && featureAuthor.image.uri})`}"></div>
                                        </div>

                                        <div class="col-sm-6">
                                            <h5 class="text-uppercase mb10 text-thin">
                                                Podjetnik
                                            </h5>
                                            <div class="s-instructors__carousel-text mb10" v-html="(featureAuthor.abstract ? featureAuthor.abstract.substring(0,150) : '')">
                                            </div>
                                            <!-- <div class="flex flex--middle">
                                                <v-icon-award height="18px" width="18px"></v-icon-award>
                                                <p class="mb0 ml5">
                                                    <b>{{ $t('views.Authors.AuthorList.list.courses')}}: </b>{{ featureAuthor.courses.length }}<br>
                                                </p>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </carousel>

                            <div class="s-intructors__bg-image">
                                <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5f4c9806b2f9219545ba8b41_qouote-upside-donw.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import carousel from 'vue-owl-carousel';
import { mapState } from 'vuex';

export default {
    name: 'section-instructors-1',

    components: {
        carousel,
    },

    props: {
        version: {
            type: String,
            default: 'default'
        }
    },

    data() {
        return {
            loading: true,
        };
    },

    computed: {
        ...mapState({
            featureAuthors: state => state.authors.list.data.slice(0, 6),
        }),
    },

    beforeCreate() {
        this.$store.dispatch('authors/fetch')
            .then(() => {
                this.loading = false;
            });
    },
};
</script>

<template>
    <div class="p-webinar">
        <section class="p-webinar__video bg--primary" v-if="isEnrolled">
            <div class="container">
                <webinar-video :url="webinar.url"></webinar-video>
            </div>
        </section>
        <section v-else
            class="bg--image ph"
            :style="{ 'background-image': `linear-gradient(rgba(0,0,0,.65),rgba(0,0,0,.65)), url(${webinar.image ? webinar.image.uri : '../../assets/images/bg_cover.jpg)'}`}">
            <div class="container pt90 pt30:sm pb150 pb30:sm">
                <div class="row flex--center flex--middle">
                    <div class="col-md-8">
                        <template v-if="loading.one">
                            <vue-content-loading  :width="100" :height="8">
                                <rect x="0" y="0" width="100" height="8"/>
                            </vue-content-loading>
                            <vue-content-loading v-if="loading.one" :width="100" :height="3" class="pt10">
                                <rect x="0" y="0" width="100" height="3"/>
                            </vue-content-loading>
                        </template>
                        <template v-else>
                            <div class="text-center text--white">
                                <h6 class="mb15">
                                    {{ webinar.authors.map((author)=> (author.name + " " + author.surname)).join(", ") }}
                                </h6>
                                <h2 class="text--white text-center">
                                    {{ webinar.name }}
                                </h2>
                                <h6 class="mt15">
                                    {{ startsAtTime }}
                                </h6>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg--bg pt60 pb60 pt20:sm pb20:sm">
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="p-webinar__content" style="width: 100%;">
                            <div class="box pl30 pr30 pt30 pb30 mb30">
                                <div class="video__iframe mb30" v-if="webinar.courseIntro">
                                    <vue-content-loading v-if="loading.one" :width="100" :height="68">
                                        <rect x="0" y="0" width="100" height="68"/>
                                    </vue-content-loading>
                                    <template v-else>
                                        <iframe
                                                id="ytplayer"
                                                type="text/html"
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allowfullscreen
                                                :src="`https://www.youtube.com/embed/${youtubeId(webinar.courseIntro)}`"
                                        ></iframe>
                                    </template>
                                </div>
                                <vue-content-loading v-if="loading.one" :width="100" :height="20">
                                    <rect x="0" y="0" width="100" height="20"/>
                                </vue-content-loading>
                                <template v-else>
                                    <div class="p-webinar__description">
                                        <h3 class="mb15">
                                            {{ webinar.name }}
                                        </h3>
                                        <div v-html="webinar.description" v-if="webinar.description"></div>
                                    </div>
                                    <template v-if="webinar.coursePrerequisites">
                                        <p><b>{{ $t('views.Courses.webinar.tabs.prerequisites') }}</b></p>
                                        <p v-html="webinar.coursePrerequisites"></p>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="box pl30 pr30 pt30 pb30 mb30">
                            <template v-if="webinar.authors.length > 1">
                                <h4 class="mb20">
                                    Predavatelji
                                </h4>

                                <div class="p-webinar__author" v-for="author in webinar.authors" :key="author.id">
                                    <div class="p-webinar__author-image">
                                        <!-- <img :src="author.image.uri" :alt="`${author.name} ${author.surname}`"> -->
                                        <img src="https://assets.vadbe.net/m7rUgISlZBFjzShZwfGlnzJ1oVPCkJ2MosoufKMvPnERFv5s5qcVukoVj36Dpknj?size=300" alt="">
                                    </div>
                                    <div class="p-webinar__author-text">
                                        <h5 class="p-webinar__author-name">
                                            {{ author.name }} {{ author.surname }}
                                        </h5>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="p-webinar__author p-webinar__author--single" v-for="author in webinar.authors" :key="author.id">
                                    <div class="p-webinar__author-image">
                                        <!-- <img :src="author.image.uri" :alt="`${author.name} ${author.surname}`"> -->
                                        <img src="https://assets.vadbe.net/m7rUgISlZBFjzShZwfGlnzJ1oVPCkJ2MosoufKMvPnERFv5s5qcVukoVj36Dpknj?size=300" alt="">
                                    </div>
                                    <div class="p-webinar__author-text">
                                        <h4 class="p-webinar__author-name">
                                            {{ author.name }} {{ author.surname }}
                                        </h4>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="box pl30 pr30 pt30 pb30 mb30">
                            <div class="p-webinar__details">
                                <div class="p-webinar__details-item" v-if="webinar.category">
                                    Kategorija: <strong>{{ webinar.category.name }}</strong>
                                </div>
                                <div class="p-webinar__details-item" v-html="webinarFullTimeDetail">
                                </div>
                                <div class="p-webinar__details-item">
                                    Št. prijavljenih: <strong>{{ webinar.enrolmentsCount }}</strong>
                                </div>
                            </div>
                            <template v-if="isEnrolled">
                                <div class="text-center">
                                    <h4 class="text-bold">
                                        Prijavljen na webinar
                                    </h4>
                                </div>
                            </template>
                            <template v-else>
                                <div class="text-center mb30">
                                    <h4 class="text-bold">
                                        <template v-if="webinar.free">
                                            Brezplačno
                                        </template>
                                        <template v-else>
                                            {{ webinar.price }}
                                        </template>
                                    </h4>
                                </div>
                                <template v-if="account">
                                    <button class="btn btn--full" @click="enroll">
                                        Naroči se na webinar
                                    </button>
                                </template>
                                <template v-else>
                                    <button class="btn btn--full" @click="login">
                                        Prijavi se
                                    </button>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Player from '../../components/VideoPlayer/Player.vue';
import WebinarVideo from '../../components/VideoPlayer/WebinarVideo.vue';

import VueContentLoading from 'vue-content-loading';

export default {
    name: 'Webinar',

    components: {
        VueContentLoading,
        'player': Player,
        WebinarVideo,
    },

    data() {
        return {
            loading: {
                one: true,
                featured: true,
            },
        };
    },

    computed: {
        ...mapState({
            // course: state => state.courses.one,
            portalName: state => state.other.portalName,
            webinar: state => state.webinars.one,
            account: state => state.auth.account,
        }),

        startsAtTime() {
            return `${moment(this.webinar.startsAt).format('DD.MM.')} ob ${moment(this.webinar.startsAt).format('hh:mm')}`;
        },

        webinarFullTimeDetail() {
            return `<span class="text-capitalize">${moment(this.webinar.startsAt).format('dddd, DD. MM. YYYY')}</span> <br/> Od <strong>${moment(this.webinar.startsAt).format('HH:mm')}</strong> do <strong>${moment(this.webinar.endsAt).format('HH:mm')}</strong>`;
        },

        isEnrolled() {
            // return true;
            return this.webinar.enrolled ? true : false

            // || (this.$store.state.app.user.authorId && this.webinar.authors.find((author) => parseInt(author.id, 10) === parseInt(this.$store.state.app.user.authorId, 10)));
        },
    },

    methods: {
        init() {
            this.$store.dispatch('webinars/fetchOne', this.$route.params.id)
            .then(() => {
                document.title = `${this.portalName} - ${this.webinar.name}`;
                this.loading.one = false;
            });
        },
        // login() {
        //     this.$store.commit('app/toggleLoginModal', true);
        // },
        login() {
            this.$store.commit('auth/loginCallback', this.init);
            window.location = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
            // this.$store.dispatch('other/popup', true);
        },

        enroll() {
            if (this.webinar.free) {
                this.$store.dispatch('webinars/enroll', { id: this.$route.params.id });
            }
        },

            // NOT LOGGED IN
            // this.$store.commit('app/paymentData', {
            //     type: 'WEBINAR',
            //     id: this.$route.params.id,
            //     free: this.webinar.free,
            //     price: this.webinar.price,
            //     name: this.webinar.name,
            // });

            // if (this.webinar.free) {
            //     this.$store.dispatch('webinars/enroll', { id: this.$route.params.id });
            // } else if (this.packageAmount > 0) {
            //     let pkg = null;
            //     for (let i = 0; i < this.webinar.recurring.myPackages.length; i += 1) {
            //         pkg = this.webinar.recurring.myPackages[i];

            //         if (pkg.package.amount - pkg.usedAmount > 0) {
            //             break;
            //         }
            //     }

            //     console.log(pkg);

            //     this.$store.dispatch('webinars/enroll', { id: this.$route.params.id, packageId: pkg.id });
            //     } else {
            //         this.$store.commit('app/togglePaymentModal', true);
            //     }
            // },
    },

    watch: {
        '$route.params.id': {
            handler(id) {
                this.loading.one = true;
                this.$store.dispatch('webinars/fetchOne', id).then((val) => {
                    document.title = `${this.portalName} - ${val.name}`;
                    this.loading.one = false;
                });
            },
        },

        portalName: {
            handler(portalName) {
                if (this.webinar && this.webinar.name) {
                    document.title = `${portalName} - ${this.webinar.name}`;
                } else {
                    document.title = `${portalName}`;
                }
            },
        },
    },

    beforeMount() {
        // this.$matomo && this.$matomo.trackPageView();
        this.init();

        const locale = this.$route.params.lang === 'SI' ? 'sl' : 'en';

        moment.locale(locale)
    },
};
</script>

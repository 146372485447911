<template>
    <router-link class="webinar-card box box--hover width100 height100 block relative"
                 :to="{ name: 'webinar', params: { id: webinar.id, lang: $i18n.locale } }">
        <div class="webinar-card__image bg--image quadric relative"
            :style="{ 'background-image': 'url(' + (webinar.image && webinar.image.uri) + ')' }">

            <div v-if="!isLive" class="absolute absolute--right absolute--top mt20 mr20 label label--secondary label--small mb5">
                Trenutno predvaja
            </div>
        </div>
        <div class="webinar-card__content">
            <div class="flex flex--wrap flex--middle mb20" v-if="webinar.categories && webinar.categories.length > 0">
                <p class="mb5 mr10" v-if="webinar.categories && webinar.categories.length > 0">
                    {{ webinar.categories && webinar.categories.map((category) => category.name).join(", ")}}
                </p>
            </div>

            <h6>{{ webinar.name }}</h6>

            <p class="mt20" v-if="webinar.authors && webinar.authors.length">
                {{ webinar.authors && webinar.authors.map((author)=> (`${author.name} ${author.surname}`)).join(", ")}}
            </p>
            <div class="mt20" v-html="webinar.description && webinar.description.substring(0,100) + '...'">
            </div>
        </div>
        <div class="webinar-card__footer absolute absolute--bottom absolute--left absolute--right">
            <div class="flex flex--between flex--middle">
                <div class="flex flex--middle" v-if="webinar.startsAt">
                    <v-icon-clock height="18px" width="18px" class="text--secondary mr5"></v-icon-clock>
                    {{ startsAtTime }}
                </div>
                <div style="height: 38px" v-else></div>
                <div>
                    <span v-if="webinar.price" class="label label--green">
                        {{ webinar.price + "€"}}
                    </span>
                    <span v-else class="label">{{ $t('components.Courses.CourseCard.free') }}</span>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import moment from 'moment';

export default {
    name: 'webinar-card',
    props: {
        webinar: {
            type: Object,
            required: true,
        },

        // disabled: {
        //     type: Boolean,
        //     default: false,
        // },

        // disabledText: {
        //     type: String,
        //     default: 'Prihaja kmalu',
        //     required: false,
        // },
    },

    computed: {
        startsAtTime() {
            return moment(this.webinar.startsAt).format('DD.MM.') + ' ob ' + moment(this.webinar.startsAt).format('hh:mm');
        },

        isLive () {
            const now = new Date();
            const to = new Date(this.webinar.startsAt);
            const end = new Date(this.webinar.endsAt);

            if ((to - now) < 0) {
                if ((end - now) > 0) {
                    return 0;
                }

                return end - now;
            }

            return to - now;
        },
    },
};
</script>

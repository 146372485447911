<template>
        <section>
            <div class="container pt60 pb60 pt30:sm pb30:sm">
                <div class="row flex--center">
                    <div class="col-md-8 pb40 pb0:sm">
                        <h3 class="text-center text-section-title wow slideInUp">
                             {{ $t('components._sections.Content4.title') }}
                        </h3>
                        <h4 class="text-center text-section-subtitle wow slideInUp">
                             {{ $t('components._sections.Content4.subtitle') }}
                        </h4>
                    </div>
                </div>
                <div class="row pb40 pt20:sm">
                    <div class="col-md-7 flex--one pb15:sm wow slideInUp">
                        <div class="quadric bg--image width100"
                             :style="{'background-image': `url(${require('../../assets/images/firstpage_01.jpg')})`}"></div>
                    </div>
                    <div class="col-md-5 flex--one wow slideInUp">
                        <div class="height100 flex flex--middle">
                            <div>
                                <h4 class="pb15">
                                    {{ $t('components._sections.Content4.content.0.title') }}
                                </h4>
                                <p>
                                    {{ $t('components._sections.Content4.content.0.description') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row flex--row-reverse pb40">
                    <div class="col-md-7 flex--one pb15:sm wow slideInUp">
                        <div class="quadric bg--image width100"
                             :style="{'background-image': `url(${require('../../assets/images/firstpage_02.jpg')})`}"></div>
                    </div>
                    <div class="col-md-5 flex--one wow slideInUp">
                        <div class="height100 flex flex--middle">
                            <div>
                                <h4 class="pb15 text-right text-left:sm">
                                    {{ $t('components._sections.Content4.content.1.title') }}
                                </h4>
                                <p class="text-right text-left:sm">
                                    {{ $t('components._sections.Content4.content.0.description') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pb40">
                    <div class="col-md-7 flex--one pb15:sm wow slideInUp">
                        <div class="quadric bg--image width100"
                             :style="{'background-image': `url(${require('../../assets/images/firstpage_03.jpg')})`}"></div>
                    </div>
                    <div class="col-md-5 flex--one wow slideInUp">
                        <div class="height100 flex flex--middle">
                            <div>
                                <h4 class="pb15">
                                    {{ $t('components._sections.Content4.content.2.title') }}
                                </h4>
                                <p>
                                    {{ $t('components._sections.Content4.content.0.description') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
    export default {
        name: 'section-content-4',
    };
</script>

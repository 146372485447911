import { portal} from '../../api/api';

import Categories from '../../api/graphql/categories/categories.graphql';

export default {
    fetch: ({ commit }) => portal(Categories, {})
        .then((success) => {
            commit('setCategories', success.data.data.categories);
            return Promise.resolve(success.data.data.categories);
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        })
};

<template>
    <section class="s-books" :class="">
        <template v-if="version == 'books'">
            <div class="container pt150 pb150 pt60:sm pb60:sm">

                <div class="row flex--center pb45">
                    <div class="col-md-10">
                        <h3 class="text-center text-section-title">
                             {{ $t('components._sections.Books.books.0.title') }}
                        </h3>
                        <h4 class="text-center text-section-subtitle">
                            {{ $t('components._sections.Books.books.0.subtitle') }}
                        </h4>
                    </div>
                </div>


                <div class="row pt30">
                     <div class="col-md-2 mb20:sm"></div>

                    <div class="col-md-3 mb20:sm">
                        <img :src="require('../../assets/images/learn-book4.jpg')">
                    </div>
                    <div class="col-md-7 mb20:sm">
                        <p>
                            {{ $t('components._sections.Books.books.0.content') }}
                        </p>
                        <p>
                            {{ $t('components._sections.Books.books.0.content1') }}
                        </p>
                        <div class="pt20">
                            <a href="#" class="btn">
                                {{ $t('components._sections.Books.books.0.button') }}
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </template>

        <template v-if="version == 'subscription'">
            <div class="s-books__wrapper pt60 pb60">
                <div class="s-books__stripes s-books__stripes--left">
                    <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5f49e0576c7d765b0b40c801_stripes-short.png" alt="">
                </div>

                <div class="container">
                    <div class="s-books__content">
                        <div class="row flex--middle">
                            <div class="col-sm-8 col-md-6">
                                <h5 class="text-thin mb20">
                                    {{ $t('components._sections.Books.subscription.0.subtitle') }}
                                </h5>
                                <h3 class="mb20">
                                    {{ $t('components._sections.Books.subscription.0.title') }}
                                </h3>
                                <p>
                                     {{ $t('components._sections.Books.subscription.0.description') }}
                                </p>
                            </div>
                            <div class="col-sm-4 col-md-6">
                                <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5fb63830a37e3fa397150ab7_reset-studio-prirocnik-1.jpg" alt="">
                            </div>
                        </div>

                        <div class="row mt20:sm">
                            <div class="col-md-12 col-lg-10">
                                <form action="">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-4 mb30:sm">
                                            <input type="text" name="name" placeholder="Ime" @keyup="$v.name6.$touch()" v-model="name6">

                                            <p class="errortext" v-if="$v.name6.$dirty && !$v.name6.required && !displaySuccessMessage">
                                                Prosimo, vnesite ime
                                            </p>
                                        </div>
                                        <div class="col-sm-6 col-md-4 mb30:sm">
                                            <input type="email" name="email" placeholder="E-pošta" @keyup="$v.email6.$touch()" v-model="email6">

                                            <p class="errortext" v-if="$v.email6.$dirty && !$v.email6.required && !displaySuccessMessage">
                                                {{$t('components._sections.Newsletter.form.email.required')}}
                                            </p>
                                            <p class="errortext" v-if="$v.email6.$dirty && !$v.email6.email">
                                                {{$t('components._sections.Newsletter.form.email.email')}}
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <button type="button" class="btn" @click="submit()" :disabled="$v.$invalid">Želim brezplačni priročnik</button>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="success-box flex flex--middle mt20" v-if="displaySuccessMessage">
                                                <v-icon-checkCircle class="mr10" />
                                                <div style="width: calc(100% - 50px)">
                                                    Uspešno ste se prijavili. Priročnik boste prejeli na vašo e-pošto.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="s-books__stripes s-books__stripes--right">
                    <img src="https://uploads-ssl.webflow.com/5f323e5a35f94f2e7215c155/5f49e0576c7d765b0b40c801_stripes-short.png" alt="">
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: 'section-books',

    data() {
        return {
            displaySuccessMessage: false,
        };
    },

    props: {
        version: {
            type: String,
            default: 'books',
        }
    },

    validations: {
        name6: {
            required,
        },
        email6: {
            required,
            email,
        },
    },

    methods: {
        submit() {
            this.$store.dispatch('other/booksForm');
            this.displaySuccessMessage = true;
        },
    },

    computed: {
        name6: {
            get() { return this.$store.state.other.booksForm.name; },
            set(value) { this.$store.commit('other/booksFormData', { field: 'name', value })},
        },
        email6: {
            get() { return this.$store.state.other.booksForm.email; },
            set(value) { this.$store.commit('other/booksFormData', { field: 'email', value })},
        },
    }
};
</script>

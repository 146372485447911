<template>
    <div>
        <section class="bg--image ph"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover_small.jpg')})`}">
            <div class="container pt90 pt30:sm pb60 pb30:sm">
                <div class="row flex--center flex--middle pb20">
                    <div class="col-md-8">
                        <h2 class="text--white text-center">{{ $t('views.Authors.AuthorList.title')}}</h2>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section>
            <div class="container pt40 pb20 pt20:sm pb0:sm">
                <div class="flex--right">
                    <div class="col-md-4 pl0:sm pr0:sm">
                        <form class="flex flex--between box"
                              style="border: solid 1px #eae9e9; width: 100%; border-radius: 200px;"
                              @submit.prevent="searchQuery()">
                            <input type="text" :placeholder="$t('views.Authors.AuthorList.search')" class="pl20"
                                   style="border: 0; width: calc(100% - 54px); border-radius: 200px;" v-model="search">
                            <button class="btn--round" style="min-width: inherit">
                                <v-icon-search></v-icon-search>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="bg--bg pb60 pt20 pb20:sm">
            <div class="container">
                <div class="row flex--center">
                    <!-- <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-12 col-xs-6 flex--one pb20">
                                <div class="box pl15 pr15 pt15 pb15 height100 width100">
                                    <h6 class="text-left pb10">{{$t('views.Partners.Partners.filters')}}</h6>
                                    <div class="flex flex--column pl15">
                                        <a href="#" class="mb5 text--blue">> Popular</a>
                                        <a href="#" class="mb5">Just relased</a>
                                        <a href="#" class="mb5">Popular</a>
                                        <a href="#" class="mb5">Free</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->


                    <div class="col-md-12">
                        <div class="flex flex--center pt20 pb20" v-if="loading">
                            <div class="loading-indicator"></div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-sm-4 col-md-3 pb30 flex--one" v-for="(author, ai) in authors" :key="ai">
                                <author-card :author="author"></author-card>
                            </div>
                        </div>


                        <div class="flex flex--center pb20" v-if="totalPages > 1">
                            <button class="btn--round" style="min-width: inherit" @click="page-=1" v-if="page > 1">
                                <v-icon-chevronLeft height="20px" width="20px"></v-icon-chevronLeft>
                            </button>
                            <div style="height: 50px; width: 50px;" v-else></div>

                            <div class="flex flex--middle flex--center pl15 pr15" style="height: 50px">
                                <h4>{{ page }}/{{totalPages}}</h4>
                            </div>

                            <button class="btn--round" style="min-width: inherit" @click="page+=1"
                                    v-if="page<totalPages">
                                <v-icon-chevronRight height="20px" width="20px"></v-icon-chevronRight>
                            </button>
                            <div style="height: 50px; width: 50px;" v-else></div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
        <section-newsletter />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import AuthorCard from '../../components/Authors/AuthorCard.vue';
import SectionNewsletter from '../../components/_sections/Newsletter.vue';

export default {
    components: {
        SectionNewsletter,
        AuthorCard,
    },

    data() {
        return {
            loading: true,
        };
    },

    watch: {
        '$i18n.locale': {
            handler() {
                document.title = `${this.portalName} - ${this.$t('views.Authors.AuthorList.title')}`;
                this.loading = true;
                this.$store.dispatch('authors/fetch')
                    .then(() => {
                        this.loading = false;
                    });
            },
        },

        portalName: {
            handler(portalName) {
                document.title = `${portalName} - ${this.$t('views.Authors.AuthorList.title')}`;
            },
        },
    },


    computed: {
        ...mapState({
            authors: state => state.authors.list.data,
            limit: state => state.authors.list.limit,
            total: state => state.authors.list.total,
            portalName: state => state.other.portalName,
        }),

        page: {
            get() {
                return this.$store.state.authors.list.page;
            },
            set(page) {
                this.$store.dispatch('authors/changePagination', page);
                this.loading = true;
                this.$store.dispatch('authors/fetch').then(() => {
                    this.loading = false;
                });
            },
        },

        search: {
            get() {
                return this.$store.state.authors.list.search;
            },
            set(search) {
                this.$store.dispatch('authors/changeSearch', search);
            },
        },

        totalPages() {
            return Math.ceil(this.total / this.limit);
        },
    },

    methods: {
        searchQuery() {
            this.page = 1;
            this.loading = true;
            this.$store.dispatch('authors/fetch').then(() => {
                this.loading = false;
            });
        },
    },

    beforeMount() {
        document.title = `${this.portalName} - ${this.$t('views.Authors.AuthorList.title')}`;
        this.$store.dispatch('authors/fetch')
            .then(() => {
                this.loading = false;
            });
    },
};
</script>

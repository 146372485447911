<template>
    <section class="s-customers bg--bg pt120 pb90 pt60:sm pb60:sm">
        <div class="container">
            <div class="row flex--center pb60">
                <div class="col-md-6">
                    <h3 class="pb10 text-center text-section-title">
                        {{ $t('components._sections.Customers.title') }}
                    </h3>
                    <h4 class="text-center text-section-subtitle">
                        {{ $t('components._sections.Customers.subtitle') }}
                    </h4>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <div class="s-customers__item box box--hover cursor mb30">
                        <div class="s-customers__content pl40 pt40 pb20 pr40 pl20:sm pr20:sm pb10:sm pt20:sm">
                            <p>
                                {{ $t('components._sections.Customers.testimonials.0.text') }}
                            </p>
                        </div>
                        <div class="s-customers__author pl40 pr40 pb40">
                            <div class="flex flex--middle">
                                <div class="bg--image quadric--round" style="height: 40px; width: 40px;" :style="{'background-image': `url(${require('../../assets/images/learn.jpg')})`}"></div>
                                <div style="width: calc(100% - 41px)" class="pl20">
                                    <h5 class="s-customers__author-title text-normal">
                                        {{ $t('components._sections.Customers.testimonials.0.name') }}
                                    </h5>
                                    <p class="mb0 text--grey small">
                                        {{ $t('components._sections.Customers.testimonials.0.country') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="s-customers__item box box--hover cursor mb30">
                        <div class="s-customers__content pl40 pt40 pb20 pr40 pl20:sm pr20:sm pb10:sm pt20:sm">
                            <p>
                                {{ $t('components._sections.Customers.testimonials.1.text') }}
                            </p>
                        </div>
                        <div class="s-customers__author pl40 pr40 pb40">
                            <div class="flex flex--middle">
                                <div class="bg--image quadric--round" style="height: 40px; width: 40px;" :style="{'background-image': `url(${require('../../assets/images/learn.jpg')})`}"></div>
                                <div style="width: calc(100% - 41px)" class="pl20">
                                    <h5 class="s-customers__author-title text-normal">
                                        {{ $t('components._sections.Customers.testimonials.1.name') }}
                                    </h5>
                                    <p class="mb0 text--grey small">
                                        {{ $t('components._sections.Customers.testimonials.1.country') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="s-customers__item box box--hover cursor mb30">
                        <div class="s-customers__content pl40 pt40 pb20 pr40 pl20:sm pr20:sm pb10:sm pt20:sm">
                            <p>
                                {{ $t('components._sections.Customers.testimonials.2.text') }}
                            </p>
                        </div>
                        <div class="s-customers__author pl40 pr40 pb40">
                            <div class="flex flex--middle">
                                <div class="bg--image quadric--round" style="height: 40px; width: 40px;" :style="{'background-image': `url(${require('../../assets/images/learn.jpg')})`}"></div>
                                <div style="width: calc(100% - 41px)" class="pl20">
                                    <h5 class="s-customers__author-title text-normal">
                                        {{ $t('components._sections.Customers.testimonials.2.name') }}
                                    </h5>
                                    <p class="mb0 text--grey small">
                                        {{ $t('components._sections.Customers.testimonials.2.country') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="s-customers__item box box--hover cursor mb30">
                        <div class="s-customers__content pl40 pt40 pb20 pr40 pl20:sm pr20:sm pb10:sm pt20:sm">
                            <p>
                                {{ $t('components._sections.Customers.testimonials.3.text') }}
                            </p>
                        </div>
                        <div class="s-customers__author pl40 pr40 pb40">
                            <div class="flex flex--middle">
                                <div class="bg--image quadric--round" style="height: 40px; width: 40px;" :style="{'background-image': `url(${require('../../assets/images/learn.jpg')})`}"></div>
                                <div style="width: calc(100% - 41px)" class="pl20">
                                    <h5 class="s-customers__author-title text-normal">
                                        {{ $t('components._sections.Customers.testimonials.3.name') }}
                                    </h5>
                                    <p class="mb0 text--grey small">
                                        {{ $t('components._sections.Customers.testimonials.3.country') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-customers',
};
</script>

// import defaultState from './defaultState';
// import axios from 'axios';
import { portal, callAuth } from '../../api/api';

import Login from '../../api/graphql/auth/login.graphql';
import Register from '../../api/graphql/auth/register.graphql';
import UserEdit from '../../api/graphql/auth/userEdit.graphql';
import UserPassword from '../../api/graphql/auth/userPassword.graphql';
import TokenUser from '../../api/graphql/auth/tokenUser.graphql';
import ConfirmRegistration from '../../api/graphql/auth/confirmRegistration.graphql';
import EnrolledCourses from '../../api/graphql/auth/enrolledCourses.graphql';
import EnrolledEvents from '../../api/graphql/auth/enrolledEvents.graphql';
import Payments from '../../api/graphql/auth/payments.graphql';
import PasswordRecovery from '../../api/graphql/auth/passwordRecovery.graphql';
import PasswordRecoveryCheck from '../../api/graphql/auth/passwordRecoveryCheck.graphql';
import PasswordRecoveryConfirm from '../../api/graphql/auth/passwordRecoveryConfirm.graphql';


export default {
    login: ({ commit }, params) => callAuth(Login, {
        email: params.email,
        password: params.password,
        portalId: process.env.VUE_APP_PORTAL_ID,
    }).then((success) => {
        if (success.data.data && success.data.data.login) {
            commit('saveLogin', success.data.data.login);
            return Promise.resolve(success.data.data.login);
        }
        return Promise.reject(success.data.errors[0].message);
    }),

    register: (context, params) => callAuth(Register, {
        email: params.email,
        password: params.password,
        name: params.name,
        surname: params.surname,
        portalId: process.env.VUE_APP_PORTAL_ID,
        newsletter: params.newsletter,
    }).then((success) => {
        if (success.data.data && success.data.data.register) {
            return Promise.resolve(success.data.data.register);
        }
        return Promise.reject(success.data.errors[0].message);
    }),

    userEdit: (context, params) => portal(UserEdit, {
        name: params.name,
        surname: params.surname,
    }).then((success) => {
        if (success.data.data && success.data.data.userEdit) {
            return Promise.resolve(success.data.data.userEdit);
        }
        return Promise.reject(success.data.errors[0].message);
    }),

    userPassword: (context, params) => callAuth(UserPassword, {
        oldPassword: params.oldPassword,
        newPassword: params.newPassword,
    }).then((success) => {
        if (success.data.data && success.data.data.userPassword) {
            return Promise.resolve();
        }
        return Promise.reject(success.data.errors[0].message);
    }),

    tokenUser: ({ commit, dispatch }) => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            commit('saveLogin', {
                token: null,
                account: null,
            });
        } else {
            return callAuth(TokenUser, {})
                .then((success) => {
                    if (success.data.data.tokenUser) {
                        commit('saveLogin', success.data.data.tokenUser);
                        dispatch('enrolledCourses');
                        dispatch('enrolledEvents');
                        dispatch('payments');
                        return Promise.resolve(success.data.data.tokenUser);
                    }
                    commit('saveLogin', {
                        token: null,
                        account: null,
                    });
                    return Promise.reject();
                })
                .catch(() => {
                    commit('saveLogin', {
                        token: null,
                        account: null,
                    });
                    return Promise.reject();
                });
        }
    },
    confirmRegistration: (context, params) => callAuth(ConfirmRegistration, {
        code: params.code,
        portalId: process.env.VUE_APP_PORTAL_ID,
    })
        .then((success) => {
            if (success.data.data && success.data.data.confirmRegistration) {
                return Promise.resolve(success.data.data.confirmRegistration);
            }
            return Promise.reject(success.data.errors[0].message);
        }),

    enrolledCourses: ({ commit }) => portal(EnrolledCourses, {})
        .then((success) => {
            if (success.data.data && success.data.data.enrolledCourses) {
                commit('saveEnrolledCourses', success.data.data.enrolledCourses);
                return Promise.resolve(success.data.data.enrolledCourses);
            }
            return Promise.reject(success.data.errors[0].message);
        }),

    enrolledEvents: ({ commit }) => portal(EnrolledEvents, {})
        .then((success) => {
            if (success.data.data && success.data.data.enrolledEvents) {
                commit('saveEnrolledEvents', success.data.data.enrolledEvents);
                return Promise.resolve(success.data.data.enrolledEvents);
            }
            return Promise.reject(success.data.errors[0].message);
        }),

    payments: ({ commit }) => portal(Payments, {})
        .then((success) => {
            if (success.data.data && success.data.data.payments) {
                commit('savePayments', success.data.data.payments);
                return Promise.resolve(success.data.data.payments);
            }
            return Promise.reject(success.data.errors[0].message);
        }),

    passwordRecovery: (context, params) => callAuth(PasswordRecovery, {
        email: params.email,
        portalId: process.env.VUE_APP_PORTAL_ID,
    })
        .then((success) => {
            if (success.data.data && success.data.data.passwordRecovery) {
                return Promise.resolve(success.data.data.passwordRecovery);
            }
            return Promise.reject(success.data.errors[0].message);
        }),
    passwordRecoveryCheck: (context, params) => callAuth(PasswordRecoveryCheck, {
        code: params.code,
        portalId: process.env.VUE_APP_PORTAL_ID,
    })
        .then((success) => {
            if (success.data.data && success.data.data.passwordRecoveryCheck) {
                return Promise.resolve(success.data.data.passwordRecoveryCheck);
            }
            return Promise.reject(success.data.errors[0].message);
        }),
    passwordRecoveryConfirm: (context, params) => callAuth(PasswordRecoveryConfirm, {
        code: params.code,
        password: params.password,
        portalId: process.env.VUE_APP_PORTAL_ID,
    })
        .then((success) => {
            if (success.data.data && success.data.data.passwordRecoveryConfirm) {
                return Promise.resolve(success.data.data.passwordRecoveryConfirm);
            }
            return Promise.reject(success.data.errors[0].message);
        }),
};

<template>
    <section class="bg--gradient">
        <div class="container pt120 pb150">
            <div class="row flex--center flex--middle">
                <div class="col-md-4 pb30:sm">
                    <h6 class="text--white text-section-subtitle">
                        {{ $t('components._sections.Beta.subtitle') }}
                    </h6>
                    <h3 class="text--white">
                        {{ $t('components._sections.Beta.title.0') }} 
                            <span class="text-normal">{{ $t('components._sections.Beta.title.1') }}</span>
                    </h3>
                </div>
                <div class="col-md-4">
                    <div class="box bg--white pt30 pb30 pl45 pr45">
                        <form-sections-beta />
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>

<script>
import FormSectionsBeta from '../Forms/_sections/Beta.vue';

export default {
    name: 'section-beta',
    components: {
        FormSectionsBeta,
    },
};
</script>

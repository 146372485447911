<template>
    <section class="s-ribbon bg--gradient pt90 pb90 pt60:sm pb60:sm" :class="{ 's-ribbon--skew': skew}">
        <div class="container">
            <u-animate-container>
                <div class="s-ribbon__content">
                    <div class="hide:sm row">
                        <div class="col-md-4">
                            <u-animate
                                    name="slideInLeft"
                                    delay="0s"
                                    duration="1s"
                                    animateClass="animated"
                            >
                                <div class="s-ribbon__item">
                                    <div class="flex flex--center">
                                        <div class="s-ribbon__item-icon" :class="{'text--white': !dark}">
                                            <v-icon-elastic height="64px" width="64px" />
                                        </div>
                                    </div>
                                    <h4 class="s-ribbon__item-title" :class="{'text--white': !dark}">
                                        {{$t('components._sections.Ribbon.carousel.0.title')}}</h4>
                                    <p class="s-ribbon__item-text" :class="{'text--white': !dark}">
                                        {{$t('components._sections.Ribbon.carousel.0.description')}}
                                    </p>
                                </div>
                            </u-animate>
                        </div>

                        <div class="col-md-4">
                            <u-animate
                                    name="slideInUp"
                                    delay="0s"
                                    duration="1s"
                                    animateClass="animated"
                            >
                                <div class="s-ribbon__item">
                                    <div class="flex flex--center">
                                        <div class="s-ribbon__item-icon" :class="{'text--white': !dark}">
                                            <v-icon-results height="64px" width="64px" />
                                        </div>
                                    </div>
                                    <h4 class="s-ribbon__item-title" :class="{'text--white': !dark}">
                                        {{$t('components._sections.Ribbon.carousel.1.title')}}</h4>
                                    <p class="s-ribbon__item-text" :class="{'text--white': !dark}">
                                        {{$t('components._sections.Ribbon.carousel.1.description')}}
                                    </p>
                                </div>
                            </u-animate>
                        </div>

                        <div class="col-md-4">
                            <u-animate
                                    name="slideInRight"
                                    delay="0s"
                                    duration="1s"
                                    animateClass="animated"
                            >
                                <div class="s-ribbon__item">
                                    <div class="flex flex--center">
                                        <div class="s-ribbon__item-icon" :class="{'text--white': !dark}">
                                            <v-icon-knowledge height="64px" width="64px" />
                                        </div>
                                    </div>
                                    <h4 class="s-ribbon__item-title" :class="{'text--white': !dark}">
                                        {{$t('components._sections.Ribbon.carousel.2.title')}}</h4>
                                    <p class="s-ribbon__item-text" :class="{'text--white': !dark}">
                                        {{$t('components._sections.Ribbon.carousel.2.description')}}
                                    </p>
                                </div>
                            </u-animate>
                        </div>
                    </div>
                    <div class="show-block:sm">
                        <u-animate
                                name="slideInUp"
                                delay="0s"
                                duration="1s"
                                animateClass="animated"
                        >
                            <carousel :nav="false" :responsive="{0:{items:1,dots: true},992:{items:3,dots: false}}">
                                <div class="wow slideInUp"
                                     data-wow-delay="ms">
                                    <div class="s-ribbon__item">
                                        <div class="flex flex--center">
                                            <div class="s-ribbon__item-icon" :class="{'text--white': !dark}">
                                                <v-icon-airplay></v-icon-airplay>
                                            </div>
                                        </div>
                                        <h4 class="s-ribbon__item-title" :class="{'text--white': !dark}">
                                            {{$t('components._sections.Ribbon.carousel.0.title')}}</h4>
                                        <p class="s-ribbon__item-text" :class="{'text--white': !dark}">
                                            {{$t('components._sections.Ribbon.carousel.0.description')}}
                                        </p>
                                    </div>
                                </div>

                                <div class="item wow slideInUp"
                                     data-wow-delay="ms">
                                    <div class="s-ribbon__item">
                                        <div class="flex flex--center">
                                            <div class="s-ribbon__item-icon" :class="{'text--white': !dark}">
                                                <v-icon-airplay></v-icon-airplay>
                                            </div>
                                        </div>
                                        <h4 class="s-ribbon__item-title" :class="{'text--white': !dark}">
                                            {{$t('components._sections.Ribbon.carousel.1.title')}}</h4>
                                        <p class="s-ribbon__item-text" :class="{'text--white': !dark}">
                                            {{$t('components._sections.Ribbon.carousel.1.description')}}
                                        </p>
                                    </div>
                                </div>

                                <div class="item  wow slideInUp"
                                     data-wow-delay="ms">
                                    <div class="s-ribbon__item">
                                        <div class="flex flex--center">
                                            <div class="s-ribbon__item-icon" :class="{'text--white': !dark}">
                                                <v-icon-airplay></v-icon-airplay>
                                            </div>
                                        </div>
                                        <h4 class="s-ribbon__item-title" :class="{'text--white': !dark}">
                                            {{$t('components._sections.Ribbon.carousel.2.title')}}</h4>
                                        <p class="s-ribbon__item-text" :class="{'text--white': !dark}">
                                            {{$t('components._sections.Ribbon.carousel.2.description')}}
                                        </p>
                                    </div>
                                </div>
                            </carousel>
                        </u-animate>
                    </div>
                </div>
            </u-animate-container>
        </div>
    </section>
</template>

<script>
import carousel from 'vue-owl-carousel';
import { UAnimateContainer, UAnimate } from 'vue-wow';


export default {
    name: 'section-ribbon',

    components: {
        carousel,
        UAnimateContainer,
        UAnimate,
    },

    props: {
        dark: {
            type: Boolean,
            default: false,
        },

        skew: {
            type: Boolean,
            default: true,
        }
    },
};
</script>

<template>
    <section>
        <div class="container pt60 pb60 pt30:sm pb30:sm">
            <div class="row">
                <div class="col-md-6 wow slideInUp pb20:sm">
                    <div class="box pt30 pb30 pl30 pr30">
                        <div class="flex flex--center">
                            <div>
                                <v-icon-airplay></v-icon-airplay>
                            </div>
                        </div>
                        <h5 class="text-center pb10">
                            {{$t('components._sections.Apply.boxes.0.title')}}
                        </h5>
                        <p class="text-center">
                            {{$t('components._sections.Apply.boxes.0.description')}}
                        </p>
                        <div class="flex flex--center pt15">
                            <router-link to="#" class="btn">
                                {{$t('components._sections.Apply.boxes.0.button')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 wow slideInUp pb20:sm">
                    <div class="box pt30 pb30 pl30 pr30">
                        <div class="flex flex--center">
                            <div>
                                <v-icon-24hours width="64" height="64"></v-icon-24hours>
                            </div>
                        </div>
                        <h5 class="text-center pb10">
                            {{$t('components._sections.Apply.boxes.1.title')}}
                        </h5>
                        <p class="text-center">
                            {{$t('components._sections.Apply.boxes.1.description')}}
                        </p>
                        <div class="flex flex--center pt15">
                            <router-link to="#" class="btn">
                                {{$t('components._sections.Apply.boxes.0.button')}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-apply',
};
</script>

<template>
    <div>
        <vue-content-loading v-if="loading.one" :width="100" :height="45">
            <rect x="0" y="0" width="100" height="45"/>
        </vue-content-loading>
        <player v-else :type="$route.params.type" :stub="$route.params.stub"
                :enrolled="enrolled"></player>
        <!-- <player v-else></player> -->
        <section>
            <div class="bg--cool-gray-light pb40 pt40">
                <div class="container">
                    <div class="row flex--middle">
                        <div class="col-md-8">
                            <h4 class="mb10">
                                {{ lecture.name }}
                            </h4>
                            <div class="mb0" v-html="lecture.description">
                                <!-- Tvoje jedro oz. trebuh je center tvojega telesa. Iz jedra izhaja vse (drža, moč, kronične bolečine ...), saj trebušne mišice držijo hrbtenico pokonci. Pomagal ti bom učvrstiti trebušne in hrbtne mišice. -->
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row" v-if="typeLectures.length>1">
                                <div class="col-xs-6 mb15 mt15 flex--one">
                                    <div class="box box--hover box--gray pl15 pr15 pt10 pb10 flex flex--center cursor height100 width100"
                                         @click="prevVideo()"
                                         v-if="stepId>0 && (typeLectures[stepId - 1].free || enrolled)">
                                        <div class="flex flex--left flex--middle width100">
                                            <v-icon-chevronLeft></v-icon-chevronLeft>
                                            <span style="max-width: calc(100% - 30px)">{{ $t('views.Lectures.Lecture.previous') }}</span>
                                        </div>
                                    </div>
                                    <div class="box box--hover box--gray pl15 pr15 pt10 pb10 flex flex--center cursor height100 width100"
                                         @click="enrollPopup()"
                                         v-else-if="stepId>0 && (!typeLectures[stepId - 1].free && !enrolled)">
                                        <div class="flex flex--left flex--middle width100">
                                            <v-icon-chevronLeft></v-icon-chevronLeft>
                                            <span style="max-width: calc(100% - 30px)">{{ $t('views.Lectures.Lecture.previous') }}</span>
                                        </div>
                                    </div>
                                    <div class="box box--disabled pl15 pr15 pt10 pb10 flex flex--center cursor height100 width100"
                                         v-else>
                                        <div class="flex flex--left flex--middle width100">
                                            <v-icon-chevronLeft></v-icon-chevronLeft>
                                            <span style="max-width: calc(100% - 30px)">{{ $t('views.Lectures.Lecture.previous') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-6 mb15 mt15 flex--one">
                                    <div class="box box--hover box--gray pl15 pr15 pt10 pb10 flex flex--center cursor height100 width100"
                                         @click="nextVideo()"
                                         v-if="stepId < (typeLectures.length - 1) && (typeLectures[stepId + 1].free || enrolled)">
                                        <div class="flex flex--right flex--middle width100">
                                            <span style="max-width: calc(100% - 30px)">{{ $t('views.Lectures.Lecture.next') }}</span>
                                            <v-icon-chevronRight></v-icon-chevronRight>
                                        </div>
                                    </div>
                                    <div class="box box--hover box--gray pl15 pr15 pt10 pb10 flex flex--center cursor height100 width100"
                                         @click="complete()"
                                         v-else-if="stepId >= (typeLectures.length - 1) && $route.params.type === 'courses' && (typeLectures.length - progress) <=1">
                                        <div class="flex flex--right flex--middle width100">
                                            <span style="max-width: calc(100% - 30px)">{{ $t('views.Lectures.Lecture.complete') }}</span>
                                            <v-icon-chevronRight></v-icon-chevronRight>
                                        </div>
                                    </div>
                                    <div class="box box--hover box--gray pl15 pr15 pt10 pb10 flex flex--center cursor height100 width100"
                                         @click="enrollPopup()" v-else-if="stepId < (typeLectures.length - 1)">
                                        <div class="flex flex--right flex--middle width100">
                                            <span style="max-width: calc(100% - 30px)">{{ $t('views.Lectures.Lecture.next') }}</span>
                                            <v-icon-chevronRight></v-icon-chevronRight>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container pt60 pb60">
                <div class="row flex--column-reverse:sm">
                    <div class="col-md-12">

                        <!-- REMOVE SHOW-NONE CLASS IF NEED DESCRIPTION -->
                        <div class="box pl15 pr15 pt15 pb15 show-none">
                            <div class="row">
                                <div class="col-xs-2">
                                    <div class="flex flex--center">
                                        <v-icon-fileText height="80px" width="80px"/>
                                    </div>

                                    <!--<vue-content-loading v-if="loading.one" :width="100" :height="56">
                                        <rect x="0" y="0" width="100" height="56"/>
                                    </vue-content-loading>
                                    <div class="quadric bg--image" v-else
                                         :style="'background-image: url('+author && author.image && author.image.uri+')'"></div>
                                    <vue-content-loading v-if="loading.one" :width="100" :height="8">
                                        <rect x="0" y="0" width="100" height="8"/>
                                    </vue-content-loading>
                                    <h6 v-else>{{author.name}} {{author.surname}}</h6>-->
                                </div>
                                <vue-content-loading v-if="loading.one" :width="100" :height="45">
                                    <rect x="0" y="0" width="100" height="45"/>
                                </vue-content-loading>
                                <template v-else>
                                    <div class="col-xs-10" v-html="lecture.description"
                                         v-if="lecture.description"></div>
                                    <div class="col-xs-10" v-else><p>No description</p></div>
                                </template>
                            </div>
                        </div>

                        <div v-if="$route.params.type === 'courses' && $store.state.courses.one.sections">
                            <template v-if="loading.next">
                                <vue-content-loading :width="100" :height="20" class="mt10">
                                    <rect x="0" y="0" width="100" height="20"/>
                                </vue-content-loading>
                                <vue-content-loading :width="100" :height="20" class="mt10">
                                    <rect x="0" y="0" width="100" height="20"/>
                                </vue-content-loading>
                                <vue-content-loading :width="100" :height="20" class="mt10">
                                    <rect x="0" y="0" width="100" height="20"/>
                                </vue-content-loading>
                            </template>
                            <template v-else>
                                <template v-for="(section, si) of typeSectionNextLectures">
                                    <c-accordion :title="section.name" :opened="si===0">
                                        <div class="row mt20">
                                            <template v-for="(lec, li) of section.lectures">
                                                <div class="col-md-4 col-sm-6 mb30">
                                                    <template v-if="$store.state.auth.token">
                                                        <template v-if="!lec.free && !enrolled">

                                                            <div :key="`${si}${li}`" @click="enrollPopup()">
                                                                <lecture-card :lecture="lec" :grid="true" type="none" :tstub="'none'" ></lecture-card>
                                                            </div>

                                                        </template>
                                                        <template v-else>
                                                            <lecture-card :key="`${si}${li}`" :lecture="lec" :grid="true" :type=" $route.params.type" :stub="lec.stub" :tstub="$route.params.tstub" :is-active="lecture.name == lec.name"></lecture-card>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <!-- <router-link :key="`${si}${li}`"
                                                                     :to="{ name: 'login', params: {lang: $i18n.locale}, query: {returnUrl: currentUrl} }"
                                                                     class="border--grey-bottom pt10 block">
                                                            <p>{{lec.name}} lecture name 3</p>
                                                            <p class="small">{{ (lec.video && lec.video.duration) |
                                                                duration}}</p>
                                                        </router-link> -->


                                                        <lecture-card :key="`${si}${li}`" :lecture="lec" :grid="true" type="none" :tstub="'login'"></lecture-card>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </c-accordion>
                                </template>
                            </template>
                        </div>
                        <div class="box pl15 pr15 pb5" v-else>
                            <template v-if="loading.next">
                                <vue-content-loading :width="100" :height="20" class="mt10">
                                    <rect x="0" y="0" width="100" height="20"/>
                                </vue-content-loading>
                                <vue-content-loading :width="100" :height="20" class="mt10">
                                    <rect x="0" y="0" width="100" height="20"/>
                                </vue-content-loading>
                                <vue-content-loading :width="100" :height="20" class="mt10">
                                    <rect x="0" y="0" width="100" height="20"/>
                                </vue-content-loading>
                            </template>
                            <template v-else>
                                <template v-for="(lec, li) of typeNextLectures">
                                    <template v-if="$store.state.auth.token">
                                        <router-link :key="li"
                                                     :to="{ name: 'lecture', params: { lang: $i18n.locale, stub: lec.stub, type: $route.params.type, tstub: $route.params.tstub } }"
                                                     class="border--grey-bottom pt10 block">
                                            <p>{{lec.name}}</p>
                                            <p class="small">{{ (lec.video && lec.video.duration) | duration}}</p>
                                        </router-link>
                                    </template>
                                    <template v-else>
                                        <router-link :key="li"
                                                     :to="{ name: 'login', params: {lang: $i18n.locale}, query: {returnUrl: currentUrl} }"
                                                     class="border--grey-bottom pt10 block">
                                            <p>{{lec.name}}</p>
                                            <p class="small">{{ (lec.video && lec.video.duration) | duration}}</p>
                                        </router-link>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <v-modal :open="popup" @close="popup=false">
            <template v-if="!enrolled">
                <template v-if="$route.params.type === 'courses'">
                    <h3 class="text-center pb10">
                        {{$store.state.courses.one.priceCourse ? ($store.state.courses.one.priceCourse + "€") :
                        $t('views.Courses.Course.free')}}
                    </h3>
                    <template v-if="$store.state.courses.one.free || !$store.state.courses.one.priceCourse">
                        <button @click="enroll" class="btn btn--full">
                            {{$t('views.Courses.Course.apply')}}
                        </button>
                    </template>
                    <template v-else>
                        <button @click="enroll" class="btn btn--full" v-if="!inCart">
                            {{$t('views.Courses.Course.addToCart')}}
                        </button>
                        <button @click="$router.push({name: 'cart', params: {lang: $i18n.locale}})" class="btn btn--full"
                                v-else>
                            {{$t('views.Courses.Course.inCart')}}
                        </button>
                    </template>
                </template>
                <template v-else-if="$route.params.type === 'events'">
                    <div>
                        <h3 class="text-center pb10">
                            {{$t('views.Events.Event.free')}}
                        </h3>
                        <button @click="enroll" class="btn btn--full">
                            {{$t('views.Courses.Course.apply')}}
                        </button>
                        <p class="text-center errortext">{{enrollError}}</p>
                        <p>{{enrollSuccess}}</p>
                    </div>
                </template>
            </template>
            <template v-else>
                <h3 class="text-center pb10">
                    {{$t('views.Courses.Course.applied')}}
                </h3>
            </template>
        </v-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VueContentLoading from 'vue-content-loading';
import Player from '../../components/VideoPlayer/Player.vue';
import CAccordion from '../../components/Shared/Accordion';
import LectureCard from '../../components/Lectures/LectureCard.vue';

export default {
    components: {
        CAccordion,
        Player,
        VueContentLoading,
        LectureCard,
    },

    data() {
        return {
            lectures: [],
            loading: {
                one: true,
                next: true,
            },
            stepId: -1,
            enrollError: '',
            enrollSuccess: '',
            popup: false,
        };
    },

    watch: {
        '$route.params.stub': {
            handler(stub) {
                this.loading.one = true;
                this.$store.dispatch('lectures/fetchOne', stub)
                    .then((val) => {
                        document.title = `${this.portalName} - ${val.name}`;
                        this.loading.one = false;
                    });
                this.$store.state.lectures.player.play = false;
                this.$store.state.lectures.player.time = 0;
            },
        },

        '$i18n.locale': {
            handler() {
                this.loading = {
                    one: true,
                    next: true,
                };
                this.$store.dispatch('lectures/fetchOne', this.$route.params.stub)
                    .then((val) => {
                        document.title = `${this.portalName} - ${val.name}`;
                        this.loading.one = false;
                    });
                switch (this.$route.params.type) {
                case 'courses':
                    this.$store.dispatch('courses/fetchOne', this.$route.params.tstub)
                        .then(() => {
                            this.loading.next = false;
                        });
                    break;
                case 'events':
                    this.$store.dispatch('events/fetchOne', this.$route.params.tstub)
                        .then(() => {
                            this.loading.next = false;
                        });
                    break;
                default:

                    break;
                }
            },
        },
        $route() {
            this.findId();
        },

        typeLectures() {
            this.findId();
        },
        portalName: {
            handler(portalName) {
                if (this.lecture && this.lecture.name) {
                    document.title = `${portalName} - ${this.lecture.name}`;
                } else {
                    document.title = `${portalName}`;
                }
            },
        },
        lecture: {
            handler(lecture) {
                if (!lecture.free && (!this.$store.state.auth.token || !localStorage.getItem('auth_token'))) {
                    this.$router.push({ name: 'login', params: { lang: this.$i18n.locale }, query: { returnUrl: window.location.pathname } });
                } else {
                    if (this.$route.params.type === 'courses' && this.account !== null) {
                        this.checkEnrollment(lecture, this.$store.state.courses.one, this.enrolledCourses);
                    }
                    if (this.$route.params.type === 'events' && this.account !== null) {
                        this.checkEnrollment(lecture, this.$store.state.events.one, this.enrolledEvents);
                    }
                }
            },
        },
        enrolledCourses: {
            handler(enrolledCourses) {
                if (this.$route.params.type === 'courses') {
                    this.checkEnrollment(this.lecture, this.$store.state.courses.one, enrolledCourses);
                }
            },
        },
        enrolledEvents: {
            handler(enrolledEvents) {
                if (this.$route.params.type === 'events') {
                    this.checkEnrollment(this.lecture, this.$store.state.events.one, enrolledEvents);
                }
            },
        },
    },

    computed: {
        ...mapState({
            lecture: state => state.lectures.one,
            enrolledCourse(state) {
                return state.auth.enrolledCourses && state.auth.enrolledCourses.find(el => el.course.stub === this.$route.params.tstub);
            },
            enrolledEvent(state) {
                return state.auth.enrolledEvents && state.auth.enrolledEvents.find(el => el.event.stub === this.$route.params.tstub);
            },
            enrolledCourses: state => state.auth.enrolledCourses,
            enrolledEvents: state => state.auth.enrolledEvents,
            account: state => state.auth.account,
            portalName: state => state.other.portalName,
            inCart(state) {
                if (this.$route.params.type === 'courses') {
                    return !!state.shop.cart.find(el => el.stub === this.$route.params.tstub);
                }
                return false;
            },
        }),

        progress() {
            return this.enrolledCourse && this.enrolledCourse.course && this.enrolledCourse.course.progress && this.enrolledCourse.course.progress.length;
        },

        enrolled() {
            switch (this.$route.params.type) {
            case 'courses':
                return !!this.enrolledCourse;
            case 'events':
                return !!this.enrolledEvent;
            default:
                return false;
            }
        },

        currentUrl() {
            return window.location.pathname;
        },

        typeNextLectures() {
            switch (this.$route.params.type) {
            case 'courses':
                return this.nextLecutres(this.$store.state.courses.one).filter(lec => lec.free || this.enrolled).slice(0, 5);
            case 'events':
                return this.nextLecutres(this.$store.state.events.one).filter(lec => lec.free || this.enrolled).slice(0, 5);
            default:
                return [];
            }
        },

        typeSectionNextLectures() {
            // return this.sectionNextLecutres(this.$store.state.courses.one.sections);
            return this.$store.state.courses.one.sections;
        },
        typeLectures() {
            switch (this.$route.params.type) {
            case 'courses':
                if (this.$store.state.courses.one.sections && this.$store.state.courses.one.sections.length > 0) {
                    return this.$store.state.courses.one.sections.map(section => section.lectures).flat();
                }
                return this.$store.state.courses.one.lectures;
            case 'events':
                return this.$store.state.events.one.lectures;
            default:
                return { lectures: [] };
            }
        },
        typeElement() {
            switch (this.$route.params.type) {
            case 'courses':
                return this.$store.state.courses.one;
            case 'events':
                return this.$store.state.events.one;
            default:
                return { lectures: [] };
            }
        },

        author() {
            if (this.lecture.authors) {
                if (this.lecture.authors.length > 0) {
                    return this.lecture.authors[0];
                }
            }
            return {
                image: { uri: null },
            };
        },
    },

    methods: {
        nextLecutres(data) {
            const self = this;
            let current = false;
            return data.lectures.filter((lec) => {
                const prev = current;
                if (lec.stub === self.$route.params.stub) {
                    current = true;
                }
                return prev;
            });
        },
        // sectionNextLecutres(data) {
        //     const self = this;
        //     let current = false;
        //     const nextLectures = [...data];
        //     return data && nextLectures.map((section) => {
        //         const s = { ...section };
        //         s.lectures = s.lectures.filter((lec) => {
        //             const prev = current;
        //             if (lec.stub === self.$route.params.stub) {
        //                 current = true;
        //             }
        //             return prev;
        //         });
        //         return s;
        //     }).filter(section => section.lectures.length > 0);
        // },
        complete() {
            this.$matomo && this.$matomo.trackEvent('lecture', 'complete');
            if (this.$route.params.type === 'courses') {
                this.$store.dispatch('courses/courseProgress', {
                    courseId: this.enrolledCourse.course.id,
                    lectureId: this.lecture.id,
                })
                    .then(() => {
                        this.$store.dispatch('auth/enrolledCourses');
                        this.$router.push({
                            name: 'coursefinish',
                            params: {
                                stub: this.$route.params.tstub,
                                lang: this.$i18n.locale,
                            },
                        });
                    });
            }
        },
        nextVideo() {
            this.$matomo && this.$matomo.trackEvent('lecture', 'next');
            if (this.$route.params.type === 'courses') {
                if (this.enrolledCourse) {
                    this.$store.dispatch('courses/courseProgress', {
                        courseId: this.enrolledCourse.course.id,
                        lectureId: this.lecture.id,
                    })
                        .then(() => {
                            this.$store.dispatch('auth/enrolledCourses');
                        });
                }
            }
            this.findId();
            if (this.stepId < (this.typeLectures.length - 1)) {
                if (this.$store.state.auth.token) {
                    if (this.typeLectures.length - 1 > this.stepId && (this.typeLectures[this.stepId + 1].free || this.enrolled)) {
                        this.$router.push({
                            name: 'lecture',
                            params: {
                                id: this.typeLectures[this.stepId + 1].id,
                                stub: this.typeLectures[this.stepId + 1].stub,
                                type: this.$route.params.type,
                                tstup: this.$route.params.tstub,
                                lang: this.$i18n.locale,
                            },
                        });
                    }
                } else {
                    this.$router.push({ name: 'login', params: { lang: this.$i18n.locale }, query: { returnUrl: window.location.pathname } });
                }
            }
        },

        prevVideo() {
            this.$matomo && this.$matomo.trackEvent('lecture', 'previous');
            this.findId();
            if (this.stepId > 0) {
                if (this.$store.state.auth.token || this.typeLectures[this.stepId - 1].free) {
                    if (this.stepId > 0 && (this.typeLectures[this.stepId - 1].free || this.enrolled)) {
                        this.$router.push({
                            name: 'lecture',
                            params: {
                                id: this.typeLectures[this.stepId - 1].id,
                                stub: this.typeLectures[this.stepId - 1].stub,
                                type: this.$route.params.type,
                                tstup: this.$route.params.tstub,
                                lang: this.$i18n.locale,
                            },
                        });
                    }
                } else {
                    this.$router.push({ name: 'login', params: { lang: this.$i18n.locale }, query: { returnUrl: window.location.pathname } });
                }
            }
        },
        findId() {
            for (let i = 0; i < this.typeLectures.length; i += 1) {
                if (this.typeLectures[i].stub === this.$route.params.stub) {
                    this.stepId = i;
                    break;
                }
            }
        },
        enroll() {
            this.enrollSuccess = '';
            this.enrollError = '';
            switch (this.$route.params.type) {
            case 'courses':
                if (this.$store.state.courses.one.free || !this.$store.state.courses.one.priceCourse) {
                    this.$store.dispatch('courses/courseEnroll', this.$store.state.courses.one.id)
                        .then(() => {
                            this.$store.dispatch('auth/tokenUser');
                            this.enrollSuccess = this.$t('views.Courses.Course.enroll_success');
                        })
                        .catch((err) => {
                            console.log(err);
                            this.enrollError = err;
                        });
                } else {
                    this.$store.dispatch('shop/addToCart', this.$store.state.courses.one);
                    this.$analytics.fbq.event('AddToCart');
                }
                break;
            case 'events':
                this.$store.dispatch('events/eventEnroll', this.$store.state.events.one.id)
                    .then(() => {
                        this.$store.dispatch('auth/tokenUser');
                        this.enrollSuccess = this.$t('views.Events.Event.enroll_success');
                    })
                    .catch((err) => {
                        console.log(err);
                        this.enrollError = err;
                    });
                break;
            default:
                break;
            }
        },
        enrollPopup() {
            if (this.$store.state.auth.token) {
                this.popup = true;
            } else {
                window.location = `${process.env.VUE_APP_LOGIN_REDIRECT}?origin=academy&redirect_url=${window.location.href}`;
                // this.$store.dispatch('other/popup', true);
            }
        },
        checkEnrollment(lecture, data, enrolled) {
            if (lecture.id && data.id && enrolled) {
                let isEnrolled;
                switch (this.$route.params.type) {
                case 'courses':
                    isEnrolled = !!enrolled.find(el => el.course.stub === this.$route.params.tstub);
                    break;
                case 'events':
                    isEnrolled = !!enrolled.find(el => el.event.stub === this.$route.params.tstub);
                    break;
                default:
                    isEnrolled = false;
                    break;
                }
                if (!lecture.free) {
                    if (!isEnrolled) {
                        switch (this.$route.params.type) {
                        case 'courses':
                            this.$router.push({ name: 'course', params: { stub: this.$route.params.tstub, lang: this.$i18n.locale } });
                            break;
                        case 'events':
                            this.$router.push({ name: 'event', params: { stub: this.$route.params.tstub, lang: this.$i18n.locale } });
                            break;
                        default:
                            break;
                        }
                    }
                }
            }
        },
    },

    beforeMount() {
        this.$matomo && this.$matomo.trackPageView();
        document.title = `${this.portalName}`;
        this.$store.dispatch('lectures/fetchOne', this.$route.params.stub)
            .then((val) => {
                document.title = `${this.portalName} - ${val.name}`;
                this.loading.one = false;
            });
        switch (this.$route.params.type) {
        case 'courses':
            this.$store.dispatch('courses/fetchOne', this.$route.params.tstub)
                .then(() => {
                    this.loading.next = false;
                    this.checkEnrollment(this.lecture, this.$store.state.courses.one, this.enrolledCourses);
                });
            break;
        case 'events':
            this.$store.dispatch('events/fetchOne', this.$route.params.tstub)
                .then(() => {
                    this.loading.next = false;
                    this.checkEnrollment(this.lecture, this.$store.state.events.one, this.enrolledEvents);
                });
            break;
        default:
            break;
        }
    },

    mounted() {
        this.findId();
    },
};
</script>

<template>
    <div>
        <section class="bg--image ph"
                 :style="{'background-image': `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${require('../../assets/images/bg_cover_small.jpg')})`}">
            <div class="container pt90 pt30:sm pb60 pb30:sm">
                <div class="row flex--center flex--middle pb20">
                    <div class="col-md-8">
                        <div>
                            <div class="flex flex--center">
                                <div class="loading-indicator"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    beforeCreate() {
        console.log('Here :)');

        const token = this.$route.query.token;
        const redirectUrl = this.$route.query.redirect_url;

        if (!token) {
            return;
        }

        localStorage.setItem('auth_token', token);

        this.$store.dispatch('auth/tokenUser').then(() => {
            if (redirectUrl) {
                window.location.href = redirectUrl;
                return;
            }

            this.$router.push({ name: 'index' });
        });
    },
};
</script>

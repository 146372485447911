<template>
    <div class="lecture-card__content">
        <div class="flex" v-if="!grid">
            <div class="col-md-4 flex--one pr0 pl0">
                <div class="relative width100">
                    <div class="quadric bg--image height100 width100"
                         :style="`background-image: url(${lecture.image ? lecture.image.uri : ''})`">
                         <div class="lecture-card__disabled-overlay" v-if="disabled">
                            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
                            <div class="h5">{{$t('components.Lectures.LectureCard.disabled')}}</div>
                        </div>
                    </div>
                    <div v-if="lecture.free"
                         class="absolute absolute--right absolute--top mt20 bg--gray text--white pt5 pb5 pl20 pr20 text-uppercase">
                        {{ $t('components.Lectures.LectureCard.free') }}
                    </div>
                </div>
            </div>
            <div class="col-md-8 flex--one">
                <div class="pl10 pr10 pt10 pb10">
                    <p class="h5 text-uppercase">
                        {{ lecture.type && lecture.type.name }}
                    </p>
                    <h4 class="mb5 mt5">{{ lecture.name }}</h4>
                    <p>
                        {{ lecture.authors && lecture.authors.map((author)=> (author.name + " " + author.surname)).join(", ") }}
                    </p>
                    <p>{{ lecture.abstract && lecture.abstract.substring(0,100) }}</p>
                    <!-- <hr style="height: 1.5px;">
                    <div class="flex flex--middle pt10">
                        <v-icon-clock height="18px" width="18px" class="mr5"></v-icon-clock>
                        <p class="small mb0 pl5">
                            {{ lecture.video && lecture.video.duration | duration }}
                        </p>
                    </div> -->
                </div>
            </div>
        </div>
        <template v-else>
            <div class="width100 relative">
                <div class="lecture-card__image quadric bg--image"
                        :style="`background-image: url(${lecture.image ? lecture.image.uri : ''})`"
                >
                    <div class="lecture-card__disabled-overlay" v-if="disabled">
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
                        <div class="h5">{{$t('components.Lectures.LectureCard.disabled')}}</div>
                    </div>
                </div>
                <div v-if="lecture.free"
                     class="absolute absolute--right absolute--top mt20 bg--gray text--white pt5 pb5 pl20 pr20 text-uppercase">
                    {{$t('components.Lectures.LectureCard.free')}}
                </div>
                <div v-else-if="lecture.live"
                     class="absolute absolute--right absolute--top mt20 bg--gray text--white pt5 pb5 pl20 pr20 text-uppercase">
                    {{$t('components.Lectures.LectureCard.live')}}
                </div>

                <div v-if="isActive"
                     class="absolute absolute--right absolute--left text-center absolute--top absolute--bottom flex flex--middle flex--center bg--gray text--white pt20 pb20 pl20 pr20 text-uppercase">
                     Se trenutno predvaja
                </div>
            </div>
            <div class="pl30 pr30 pt30 pb30">
                <p class="mb20">{{ lecture.type && lecture.type.name }}</p>
                <h6 class="mb20">{{ lecture.name }}</h6>
                <p class="mb20">
                    {{ lecture.authors && lecture.authors.map((author)=> (author.name + " " + author.surname)).join(", ") }}
                </p>
                <p class="mb20">
                    {{ lecture.abstract && lecture.abstract.substring(0,100) + "..." }}
                </p>
            </div>
            <!-- <div class="absolute absolute--bottom absolute--left absolute--right pl10 pr10 pb10">
                <hr style="height: 1.5px;">
                <div class="flex flex--middle pt10">
                    <template v-if="!lecture.live">
                        <v-icon-clock height="18px" width="18px" class="mr5"></v-icon-clock>
                        <p class="small mb0 pl5">
                            {{ lecture.video && lecture.video.duration | duration }}
                        </p>
                    </template>
                    <template v-else>
                        <v-icon-clock height="18px" width="18px" class="mr5"></v-icon-clock>
                        <p class="small mb0 pl5">
                            {{ lecture.liveVideoDate }}
                        </p>
                    </template>
                </div>
            </div> -->
        </template>
    </div>
</template>

<script>
export default {
    name: 'lecture-card-content',

    props: {
        lecture: {
            type: Object,
            required: true,
        },

        grid: {
            type: Boolean,
            required: false,
            default: true,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        isActive: {
            type: Boolean,
            required: false,
            default: false
        }
    },
};
</script>

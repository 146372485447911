export default {
    saveLogin(state, login) {
        state.token = login.token;
        state.account = login.account;
        if (login.enrolledCourses) {
            state.enrolledCourses = login.enrolledCourses;
        }
        if (login.enrolledEvents) {
            state.enrolledEvents = login.enrolledEvents;
        }
        if (login.token) {
            localStorage.setItem('auth_token', login.token);
        } else {
            localStorage.removeItem('auth_token');
        }
    },
    saveEnrolledCourses(state, enrolledCourses) {
        state.enrolledCourses = enrolledCourses;
    },
    saveEnrolledEvents(state, enrolledEvents) {
        state.enrolledEvents = enrolledEvents;
    },
    savePayments(state, payments) {
        state.payments = payments;
    },
    loginCallback(state, cb) {
        state.loginCallback = cb;
    }
};

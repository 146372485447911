<template>
    <div class="width100 height100">
        <div class="box flex relative">
            <div class="col-md-4 flex--one pr0 pl0">
                <div class="relative width100">
                    <div class="bg--image height100 width100 relative"
                         :style="{'background-image': 'url(' + (course.image && course.image.uri) + ')'}">
                    </div>
                </div>
            </div>
            <div class="col-md-8 flex--one">
                <div class="pl15 pr15 pt10 pb60 relative width100">
                    <p class="mb5 small">{{ course.categories && course.categories.map((category) =>
                        category.name).join(", ")}}</p>
                    <h6>{{ course.name }}</h6>
                    <p class="small">
                        {{ course.authors && course.authors.map((author)=> (`${author.name}
                        ${author.surname}`)).join(", ") }}
                    </p>
                    <div class="absolute absolute--bottom absolute--left absolute--right pl20 pr20">
                        <hr style="height: 1.5px">
                        <div class="flex flex--between flex--middle">
                            <div class="flex mb10 flex--middle mt10" v-if="course.duration">
                                <v-icon-clock height="18px" width="18px" class="mr5"></v-icon-clock>
                                {{ course.duration | duration }}
                            </div>
                            <div class="text-bold">
                                        <span v-if="course.paidCourse" class="bg--gradient text--white pl5 pr5 pt5 pb5"
                                              style="border-radius: 5px">{{ course.priceCourse + "€"}}</span>
                                <span v-else style="background: #b3e6bb;border-radius: 5px"
                                      class=" pl5 pr5 pt5 pb5">{{ $t('components.Courses.CourseCard.free') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'payment-course-card',
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
};
</script>

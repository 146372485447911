import axios from 'axios';

function call(data, variables, headers, url) {
    // MUST PROPERLY HANDLE graphql-tag OBJECT
    const body = {
        query: typeof data === 'string' ? data : data.loc.source.body,
        variables,
    };

    return axios({
        method: 'POST',
        url,
        data: body,
        headers,
    });
}

export function base(data, variables) {
    return call(data, variables, {
        'X-Locale': localStorage.getItem('lang') || 'sl_SI',
        'X-Portal': process.env.VUE_APP_PORTAL_ID,
    }, process.env.VUE_APP_GRAPHQL_URL);
}

export function portal(data, variables) {
    const headers = {
        'X-Portal': process.env.VUE_APP_PORTAL_ID,
        'X-Locale': localStorage.getItem('lang') || 'sl_SI',
    };

    if (localStorage.getItem('auth_token')) {
        headers.Authorization = `Bearer ${localStorage.getItem('auth_token')}`;
    }

    return call(data, variables, headers, `${process.env.VUE_APP_GRAPHQL_URL}/${process.env.VUE_APP_PORTAL_ENDPOINT}`);
}

export function callAuth(data, variables) {
    const headers = {
        'X-Portal': process.env.VUE_APP_PORTAL_ID,
        'X-Locale': localStorage.getItem('lang') || 'sl_SI',
    };

    if (localStorage.getItem('auth_token')) {
        headers.Authorization = `Bearer ${localStorage.getItem('auth_token')}`;
    }

    return call(data, variables, headers, process.env.VUE_APP_GRAPHQL_URL);
}
